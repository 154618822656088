.jobsListing {
    color: var(--rnb-colour-midnight);
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 var(--rnb-spacing-medium);
    display: flex;

    @media (--rnb-mq-large) {
        margin: 0 var(--rnb-spacing-huge);
    }
}

.jobsListingCTA {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: var(--rnb-spacing-super) auto var(--rnb-spacing-jumbo);
}

.jobsListingCTACopySmall {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.jobsListingCTACopyLarge {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.jobsListingLoading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: var(--rnb-spacing-super) auto var(--rnb-spacing-jumbo);
}
