.content-container {
    text-align: left;
}

.title-container {
    display: flex;
    align-items: flex-end;
    min-height: var(--rnb-spacing-huge);
    margin-top: var(--rnb-spacing-large);
}

.title-container--with-subtitle {
    flex-direction: column;
    align-items: inherit;
}

.title-container--without-tag {
    margin-top: var(--rnb-spacing-huge);
}

.subtitle-container {
    display: flex;
    align-items: center;
}

.description-container {
    min-height: 2.5rem;
    text-align: left;
}
