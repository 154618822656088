:root .component {
    --iconSize: 2rem;
    --iconSizeMobile: 1.75rem;
    --textPaddingMobile: 0.125rem;
    --closeIconMargin: 0.375rem;

    &.isDisplayStateSuccess {
        --icon-color: var(--rnb-colour-sea);
    }

    &.isDisplayStateWarning {
        --icon-color: var(--rnb-colour-sand);
    }

    &.isDisplayStateInfo {
        --icon-color: var(--rnb-colour-chill);
    }

    &.isDisplayStateInfoWarning {
        --icon-color: var(--rnb-colour-sand);
    }

    &.isDisplayStateError {
        --icon-color: var(--rnb-colour-rose);
    }

    &.isDisplayStateAlertInfo {
        --icon-color: var(--rnb-colour-midnight);
    }
}

.component {
    border: var(--rnb-colour-midnight-tint-16) solid var(--rnb-border-width-thin);
    background-color: var(--rnb-colour-white);
    border-radius: var(--rnb-border-radius-medium);
    box-shadow: var(--rnb-shadow-mountain);
    padding: var(--rnb-spacing-medium);
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: var(--rnb-spacing-medium);
    row-gap: var(--rnb-spacing-small);
    margin: 0 var(--rnb-spacing-large);
    pointer-events: auto;

    &.dismissable {
        grid-template-columns: min-content 1fr auto;
    }

    @media (--rnb-mq-medium) {
        width: 100%;
        max-width: 632px;
        margin: 0 auto;
        padding: var(--rnb-spacing-large);
        grid-template-columns: min-content 1fr max-content;

        & > * {
            align-self: center;
        }

        &.dismissable {
            grid-template-columns: min-content 1fr max-content auto;
        }
    }

    & .text {
        padding-top: var(--textPaddingMobile);

        @media (--rnb-mq-medium) {
            padding-top: initial;
        }
    }

    & .ctaLink {
        grid-column: 2 / -1;

        @media (--rnb-mq-medium) {
            grid-column: initial;
        }
    }

    & button {
        grid-column: 1/ -1;

        @media (--rnb-mq-medium) {
            grid-column: initial;
        }
    }

    & .closeIcon {
        color: var(--rnb-colour-midnight);
        grid-column: 3;
        grid-row: 1;
        margin-top: var(--closeIconMargin);

        & button {
            border: none;
            background: none;
        }

        @media (--rnb-mq-medium) {
            margin-top: initial;
            grid-column: 4;
            grid-row: 1;
        }
    }

    & .icon {
        height: var(--iconSizeMobile);
        color: var(--icon-color);

        & svg {
            width: var(--iconSizeMobile);
            height: var(--iconSizeMobile);

            :global(.icon-state-normal) {
                opacity: 0;
            }

            :global(.icon-state-hover) {
                opacity: 1;
            }
        }

        margin-top: var(--themeIconMargin);

        @media (--rnb-mq-medium) {
            margin-top: initial;
            height: var(--iconSize);

            svg {
                width: var(--iconSize);
                height: var(--iconSize);
            }
        }
    }
}

.atOnly {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}
