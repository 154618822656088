.testimonialsSectionHeading {
    color: var(--rnb-colour-midnight-tint-80);
    text-align: center;
    font-size: var(--rnb-font-size-large);
    font-weight: var(--rnb-font-weight-medium);
    line-height: var(--rnb-line-height-text); 
    }

.testimonials {
    overflow-x: auto;
    justify-content: center;
    align-items: center;
}

.items {
    margin: 0;
    padding: 0 0 var(--rnb-spacing-medium) 0;
    padding-top: var(--rnb-spacing-large);
    list-style: none;
    display: grid;
    grid: auto / auto-flow minmax(100%, 1fr);
    grid-gap: var(--grid-gap);
    align-items: center;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    @media (--rnb-mq-phablet) {
        grid: auto / auto-flow minmax(31.3%, 1fr);
    }

    @media (--rnb-mq-huge) {
        grid: auto / auto-flow minmax(22%, 1fr);
    }
    
    li {
        scroll-snap-align: center;
        height: 100%;
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

.dots {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: var(--rnb-spacing-large);
}

.testimonial {
    display: flex;
    flex-direction: column;
    border-radius: var(--rnb-spacing-small);
    background: var(--core-white-white, #FFF);
    box-shadow: 0px var(--rnb-spacing-tiny) var(--rnb-spacing-small) 0px rgba(0, 0, 0, 0.08), 0px 0px var(--rnb-spacing-small) 0px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(40px);
    height: 100%;
}

.testimonialHeader {
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    background-color: var(--slate-colour-metal-tint-80);
    border-top-right-radius: var(--rnb-spacing-small);
    border-top-left-radius: var(--rnb-spacing-small);
    padding: var(--rnb-spacing-small);
}

.testimonialBody {
    padding: var(--rnb-spacing-small);
    display: flex;  
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.customerName {
    margin-top: var(--rnb-spacing-small);
    font-size: var(--rnb-font-size-mini);
    font-weight: var(--rnb-font-weight-bold); 
}

.testimonialTitle {
    font-size: var(--rnb-font-size-small);
    font-weight: var(--rnb-font-weight-medium);
    color: var(--rnb-colour-white);
}

.starRatingImage {
    width: var(--rnb-spacing-super);
    margin-left: var(--rnb-spacing-tiny);
}

.testimonialText {
    line-height: var(--rnb-line-height-heading);
}

.trustpilotLogoContainer {
    display: flex;
    justify-content: center;
    max-height: var(--rnb-spacing-large);
    margin-top: var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        max-height: var(--rnb-spacing-big);
    }
}

.appLogos {
    display: flex;
    padding: var(--rnb-spacing-medium);
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.appLogo {
    max-height: var(--rnb-spacing-big);
    width: 220px;
}

.trustpilotLogo {
    max-height: var(--rnb-spacing-big);
    width: 300px;
}