.section {
    position: relative;
    width: 100%;
}

.faqSection {
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;
}

.faqContentWrapper {
    --maxContainerSize: 1380px;

    width: 100%;
    z-index: var(--rnb-zindex-levitate);
    max-width: var(--maxContainerSize);
    display: flex;
    padding: 0 var(--rnb-spacing-large);
    flex-direction: column;
    margin-bottom: var(--rnb-spacing-big);
    margin-top: var(--rnb-spacing-big);

    @media (--rnb-mq-phablet) {
        padding: 0 4rem;
    }

    @media (--rnb-mq-medium) {
        margin: 8rem;
    }
}

.header {
    display: flex;
    text-align: center;

    @media (--rnb-mq-medium) {
        text-align: left;
    }
}

.cardWrapper {
    display: none;
    justify-content: space-around;
    flex-direction: column;

    @media (--rnb-mq-medium) {
        display: flex;
        flex-flow: wrap;
    }

    @media (--rnb-mq-large) {
        justify-content: space-between;
        top: 50%;
    }
}

.question {
    @media (--rnb-mq-medium) {
        width: 460px;
    }

    @media (min-width: 1440px) {
        width: 524px;
    }
}

.answer {
    margin-top: var(--rnb-spacing-medium);
}

.faqButton {
    display: flex;
    justify-content: center;

    a * {
        background-color: var(--rnb-colour-chill) !important;
    }
}

.faqsSmall {
    display: block;

    @media (--rnb-mq-medium) {
        display: none;
    }

    & button {
        border: none;
        font-size: var(--rnb-font-size-medium);
        font-weight: var(--rnb-font-weight-medium);
    }
}
