.component {
    color: var(--rnb-colour-black);
    text-decoration: none;
    padding-top: var(--rnb-spacing-big);
    margin-bottom: var(--rnb-spacing-big);

    & + & {
        border-top: 1px solid var(--rnb-colour-black-alpha-16);
    }

    @media (--rnb-mq-medium) {
        padding-top: var(--rnb-spacing-super);
        margin-bottom: var(--rnb-spacing-super);

        &:not(.hasFullArticleInfo) {
            margin-bottom: var(--rnb-spacing-super);
        }
    }

    @media (--rnb-mq-large) {
        &:not(.hasFullArticleInfo) {
            display: grid;
            grid-template: 1fr / 0.5fr 1fr;
        }
    }

    .component {
        padding-top: 0;
    }
}

.title {
    font-weight: var(--rnb-font-weight-medium);
}

.headLink {
    color: var(--rnb-colour-black);
    text-decoration: none;

    &:hover {
        .title {
            text-decoration: underline;
        }

        .description .iconArrow {
            transform: translate(5px, 0);
        }
    }

    .iconArrow {
        transition: all 0.2s ease-in-out;
    }
}

.head {
    margin-bottom: var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        padding-right: var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-medium) {
        margin-bottom: var(--rnb-spacing-big);
    }
}

.description {
    color: var(--rnb-colour-midnight);
    margin-top: var(--rnb-spacing-tiny);

    @media (--rnb-mq-medium) {
        margin-top: var(--rnb-spacing-medium);
    }
}

.iconArrow {
    color: var(--rnb-colour-chill);
    vertical-align: middle;
}

.item {
    padding: var(--rnb-spacing-none) var(--rnb-spacing-tiny);

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-none);
    }
}
