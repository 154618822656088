:root .wrapper {
    --banner-height: 80px;
    --icon-inner-padding: 8px;
    --cs-icon-size: 36px;
    --cs-icon-border-size: 2px;
    --inner-wrapper-max: 80rem;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--banner-height);
    background: var(--rnb-colour-white);

    &.hasBorderTop {
        border-top: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-16);
    }

    &.hasBorderBottom {
        border-bottom: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-16);
    }

    @media (--rnb-mq-phablet) {
        justify-content: center;
    }
}

.innerWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 1280px;

    @media (--rnb-mq-phablet) {
        justify-content: space-between;
        padding: var(--rnb-spacing-none) var(--rnb-spacing-medium);
    }

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-none) var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-huge) {
        max-width: calc((var(--rnb-spacing-big)) + var(--inner-wrapper-max));
        padding: var(--rnb-spacing-none);
    }
}

.leftContent {
    display: flex;
    align-items: center;
}

.iconOuterWrapper {
    border-radius: var(--rnb-border-radius-medium);
    padding: var(--cs-icon-border-size);
    height: var(--cs-icon-size);
    width: var(--cs-icon-size);
    background: linear-gradient(
        -180deg,
        var(--rnb-colour-sand-tint-64),
        var(--rnb-colour-dandelion-tint-64),
        var(--rnb-colour-moss-tint-64),
        var(--rnb-colour-sea-tint-64),
        var(--rnb-colour-chill-tint-32)
    );
}

.iconInnerWrapper {
    border-radius: calc(var(--rnb-border-radius-medium) - var(--cs-icon-border-size));
    padding: var(--icon-inner-padding);
    background: var(--rnb-colour-white);
    width: 100%;
    height: 100%;
    text-align: center;
}

.rightContent {
    flex-grow: 1;
    text-align: right;
    cursor: pointer;

    @media (--rnb-mq-phablet) {
        flex-grow: 0;
    }
}

.storeCtaImage {
    height: var(--cs-icon-size);
}
