.none {
    margin-bottom: 0;
}

.tiny {
    margin-bottom: var(--rnb-spacing-tiny);
}

.medium {
    margin-bottom: var(--rnb-spacing-medium);
}

.big {
    margin-bottom: var(--rnb-spacing-big);
}

.huge {
    margin-bottom: var(--rnb-spacing-huge);
}

.super {
    margin-bottom: var(--rnb-spacing-super);
}
