:root {
    --background-color__3: white;
    --color__6: white;
    --maxContainerSize__15: 1280px;
    --maxContentSize: 675px;
    --maxContentSizeM: 550px;
}

.section {
    background-color: var(--background-color__3);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--rnb-spacing-large) 0;

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-jumbo) 0;
    }
}

.sectionContent {
    max-width: var(--maxContainerSize__15);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    margin: var(--rnb-spacing-medium);
}

.textContainer {
    padding: var(--rnb-spacing-large);

    @media (--rnb-mq-medium) {
        max-width: var(--maxContentSizeM);
    }

    @media (--rnb-mq-large) {
        max-width: var(--maxContentSize);
        width: 100%;
    }
}

.whyClearScoreTextContainer {
    text-align: center;
}

.emailForm {
    margin: 0 var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-super);
}
