:root {
    --icon-section-height: 152px;
}

.header {
    color: var(--rnb-colour-midnight);
    text-align: center;
}

.subheader {
    color: var(--rnb-colour-midnight-tint-64);
    text-align: center;
}

.items {
    overflow-x: hidden;
}

.item {
    background-color: var(--rnb-colour-midnight-tint-8);
    padding: var(--rnb-spacing-big);
    text-align: center;
    color: var(--rnb-colour-midnight);
    margin-right: var(--rnb-spacing-medium);
    height: 100%;

    @media (--rnb-mq-phablet) {
        margin-bottom: var(--rnb-spacing-medium);
        margin-right: var(--rnb-spacing-none);
        height: 95%;
    }
}

.itemBody {
    color: var(--rnb-colour-midnight-tint-64);
    line-height: var(--rnb-line-height-text);
}

.icon {
    height: var(--icon-section-height);
}
