:root {
    background-color: var(--rnb-colour-white);
}

.cards {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    color: var(--rnb-colour-midnight);
}

.sectionContainer {
    position: relative;
    min-height: 100vh;
}

.mobileBackground {
    display: block;

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.desktopBackground {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

.faqDisplay {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.tabletBackground {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
