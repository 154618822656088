.navigationItem {
    color: var(--rnb-colour-white);
    font-size: var(--rnb-font-size-mini);
    line-height: var(--rnb-spacing-large);
    font-weight: var(--rnb-font-weight-bold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    @media (--rnb-mq-lt-medium) {
        flex-shrink: 0;
    }

    @media (--rnb-mq-lt-medium) {
        font-size: var(--rnb-font-size-medium);
        line-height: var(--rnb-font-size-massive);
    }
}
