:root .contentCarousel {
    --grid-gap: var(--rnb-spacing-medium);
    --container-gutter: var(--rnb-spacing-big);
    --grid-gutters: calc(var(--container-gutter) - var(--grid-gap));
}

.items {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template: 1fr / auto;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }

    @supports (grid-auto-flow: initial) {
        grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        grid-auto-flow: column;
        grid-template: 1fr / var(--grid-gutters);
        margin: 0 calc(var(--container-gutter) * -1) 0;

        @media (--rnb-mq-medium) {
            grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        &::before,
        &::after {
            content: '';
            width: var(--grid-gutters);
        }
    }

    & .item {
        position: relative;
        scroll-snap-align: center;
    }
}

.dots {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var(--rnb-spacing-large);
}

@supports (grid-auto-flow: initial) {
    .maxCarouselItems-4 {
        --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
    }

    .maxCarouselItems-3 {
        --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
    }

    .maxCarouselItems-2 {
        --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
    }

    .maxCarouselItems-1 {
        --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
    }

    @media (--rnb-mq-small) {
        .maxCarouselItems-small-4 {
            --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-small-3 {
            --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-small-2 {
            --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-small-1 {
            --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        }
    }

    @media (--rnb-mq-phablet) {
        .maxCarouselItems-phablet-4 {
            --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-phablet-3 {
            --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-phablet-2 {
            --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-phablet-1 {
            --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        }
    }

    @media (--rnb-mq-medium) {
        .maxCarouselItems-medium-4 {
            --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-medium-3 {
            --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-medium-2 {
            --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-medium-1 {
            --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        }
    }

    @media (--rnb-mq-large) {
        .maxCarouselItems-large-4 {
            --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-large-3 {
            --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-large-2 {
            --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-large-1 {
            --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        }
    }

    @media (--rnb-mq-huge) {
        .maxCarouselItems-huge-4 {
            --grid-auto-columns: calc(24% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-huge-3 {
            --grid-auto-columns: calc(31.5% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-huge-2 {
            --grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        .maxCarouselItems-huge-1 {
            --grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        }
    }

    .items {
        grid-auto-columns: var(--grid-auto-columns);
    }

    .fitContent .items {
        grid-auto-columns: max-content;
    }
}

.isInline,
.isInlineSmall,
.isInlinePhablet,
.isInlineMedium,
.isInlineLarge,
.isInlineHuge {
    & .items {
        flex-flow: row wrap;
    }

    &.maxInlineItems-4 .item {
        flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

        &:not(:nth-of-type(4n)) {
            margin-right: var(--grid-gap);
        }

        &:nth-of-type(4n) {
            margin-right: var(--rnb-spacing-none);
        }
    }

    &.maxInlineItems-3 .item {
        flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

        &:not(:nth-of-type(3n)) {
            margin-right: var(--grid-gap);
        }

        &:nth-of-type(3n) {
            margin-right: var(--rnb-spacing-none);
        }
    }

    &.maxInlineItems-2 .item {
        flex: 0 1 calc(50% - var(--grid-gap) / 2);

        &:not(:nth-of-type(even)) {
            margin-right: var(--grid-gap);
        }

        &:nth-of-type(even) {
            margin-right: var(--rnb-spacing-none);
        }
    }

    &.maxInlineItems-1 .item {
        flex: 0 1 100%;
    }

    @media (--rnb-mq-small) {
        &.maxInlineItems-small-4 .item {
            flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

            &:not(:nth-of-type(4n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(4n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-small-3 .item {
            flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

            &:not(:nth-of-type(3n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(3n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-small-2 .item {
            flex: 0 1 calc(50% - var(--grid-gap) / 2);

            &:not(:nth-of-type(even)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(even) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-small-1 .item {
            flex: 0 1 100%;
        }
    }

    @media (--rnb-mq-phablet) {
        &.maxInlineItems-phablet-4 .item {
            flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

            &:not(:nth-of-type(4n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(4n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-phablet-3 .item {
            flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

            &:not(:nth-of-type(3n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(3n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-phablet-2 .item {
            flex: 0 1 calc(50% - var(--grid-gap) / 2);

            &:not(:nth-of-type(even)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(even) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-phablet-1 .item {
            flex: 0 1 100%;
        }
    }

    @media (--rnb-mq-medium) {
        &.maxInlineItems-medium-4 .item {
            flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

            &:not(:nth-of-type(4n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(4n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-medium-3 .item {
            flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

            &:not(:nth-of-type(3n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(3n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-medium-2 .item {
            flex: 0 1 calc(50% - var(--grid-gap) / 2);

            &:not(:nth-of-type(even)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(even) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-medium-1 .item {
            flex: 0 1 100%;
        }
    }

    @media (--rnb-mq-large) {
        &.maxInlineItems-large-4 .item {
            flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

            &:not(:nth-of-type(4n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(4n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-large-3 .item {
            flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

            &:not(:nth-of-type(3n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(3n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-large-2 .item {
            flex: 0 1 calc(50% - var(--grid-gap) / 2);

            &:not(:nth-of-type(even)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(even) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-large-1 .item {
            flex: 0 1 100%;
        }
    }

    @media (--rnb-mq-huge) {
        &.maxInlineItems-huge-4 .item {
            flex: 0 1 calc(25% - var(--grid-gap) / 1.25);

            &:not(:nth-of-type(4n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(4n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-huge-3 .item {
            flex: 0 1 calc(33.33% - var(--grid-gap) / 1.5);

            &:not(:nth-of-type(3n)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(3n) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-huge-2 .item {
            flex: 0 1 calc(50% - var(--grid-gap) / 2);

            &:not(:nth-of-type(even)) {
                margin-right: var(--grid-gap);
            }

            &:nth-of-type(even) {
                margin-right: var(--rnb-spacing-none);
            }
        }

        &.maxInlineItems-huge-1 .item {
            flex: 0 1 100%;
        }
    }
}

.isInline {
    & .items {
        display: flex;
        margin: 0;
        overflow-x: visible;
        grid-gap: 0;
        grid-template: 1fr / auto;

        &::before,
        &::after {
            display: none;
        }
    }

    & .item {
        flex-basis: 0;
        margin-right: var(--grid-gap);
    }

    & .flexGrow {
        flex-grow: 1;
    }

    & .dots {
        display: none;
    }
}

@media (--rnb-mq-small) {
    .isInlineSmall {
        & .items {
            display: flex;
            margin: 0;
            overflow-x: visible;
            grid-gap: 0;
            grid-template: 1fr / auto;

            &::before,
            &::after {
                display: none;
            }
        }

        & .item {
            flex-basis: 0;
            margin-right: var(--grid-gap);
        }

        & .flexGrow {
            flex-grow: 1;
        }

        & .dots {
            display: none;
        }
    }
}

@media (--rnb-mq-phablet) {
    .isInlinePhablet {
        & .items {
            display: flex;
            margin: 0;
            overflow-x: visible;
            grid-gap: 0;
            grid-template: 1fr / auto;

            &::before,
            &::after {
                display: none;
            }
        }

        & .item {
            flex-basis: 0;
            margin-right: var(--grid-gap);
        }

        & .flexGrow {
            flex-grow: 1;
        }

        & .dots {
            display: none;
        }
    }
}

@media (--rnb-mq-medium) {
    .isInlineMedium {
        & .items {
            display: flex;
            margin: 0;
            overflow-x: visible;
            grid-gap: 0;
            grid-template: 1fr / auto;

            &::before,
            &::after {
                display: none;
            }
        }

        & .item {
            flex-basis: 0;
            margin-right: var(--grid-gap);
        }

        & .flexGrow {
            flex-grow: 1;
        }

        & .dots {
            display: none;
        }
    }
}

@media (--rnb-mq-large) {
    .isInlineLarge {
        & .items {
            display: flex;
            margin: 0;
            overflow-x: visible;
            grid-gap: 0;
            grid-template: 1fr / auto;

            &::before,
            &::after {
                display: none;
            }
        }

        & .item {
            flex-basis: 0;
            margin-right: var(--grid-gap);
        }

        & .flexGrow {
            flex-grow: 1;
        }

        & .dots {
            display: none;
        }
    }
}

@media (--rnb-mq-huge) {
    .isInlineHuge {
        & .items {
            display: flex;
            margin: 0;
            overflow-x: visible;
            grid-gap: 0;
            grid-template: 1fr / auto;

            &::before,
            &::after {
                display: none;
            }
        }

        & .item {
            flex-basis: 0;
            margin-right: var(--grid-gap);
        }

        & .flexGrow {
            flex-grow: 1;
        }

        & .dots {
            display: none;
        }
    }
}
