:root {
    --darkerBlue: rgb(22, 32, 43);
    --blue: rgb(35, 49, 67);
    --extraBigPadding: 60%;
    --logoWidth: 200px;
    --logoWidthL: 245px;
    --zIndexAboveNav: var(--rnb-zindex-levitate);
}

.footer {
    margin-top: 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--rnb-colour-white);
    z-index: var(--zIndexAboveNav);

    p {
        color: var(--rnb-colour-white-alpha-48);
    }
}

.background {
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--blue);
    height: auto;
    padding: 0 var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        padding: 0 0 var(--rnb-spacing-big) 0;
    }
}

.footerTop {
    display: flex;
    flex-wrap: wrap;
    gap: var(--rnb-spacing-medium) var(--rnb-spacing-big);
    padding: var(--rnb-spacing-big) var(--rnb-spacing-micro);

    @media (--rnb-mq-small) {
        padding: var(--rnb-spacing-big) var(--rnb-spacing-small);
    }

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-big) var(--rnb-spacing-big);
    }
}

.footerColumn {
    display: flex;
    flex: 1;

    @media (--rnb-mq-lt-medium) {
        flex-basis: 10%;
    }

    &:nth-child(1) {
        flex-basis: 100%;

        @media (--rnb-mq-large) {
            flex-basis: 20%;
        }
    }

    &:nth-child(2) {
        flex-basis: 100%;

        @media (--rnb-mq-medium) {
            flex-basis: 10%;
        }
    }
}

.footerBottom {
    background-color: var(--darkerBlue);
    padding: var(--rnb-spacing-large) var(--rnb-spacing-medium);
    flex: initial;
}

.footerAppLogos {
    display: flex;
    align-items: center;
    margin-left: var(--rnb-spacing-micro);
    margin-bottom: var(--rnb-spacing-big);

    @media (--rnb-mq-small) {
        margin-left: var(--rnb-spacing-small);
    }

    @media (--rnb-mq-medium) {
        margin-left: var(--rnb-spacing-huge);
    }
}

@media (--rnb-mq-medium) {
    .footer {
        &.minimal {
            flex-direction: row;
        }
    }

    .footerTop {
        grid-template-rows: auto;
    }

    .background {
        height: 70%;

        &.minimal {
            flex: 0.55;
        }
    }

    .footerBottom {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.minimal {
            flex: 0.45;
            text-align: right;
        }
    }

    .footerTop,
    .footerBottom {
        padding: var(--rnb-spacing-huge);
    }
}
