.header {
    text-align: center;
    color: var(--rnb-colour-midnight);
}

.secondBoxBullets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (--rnb-mq-medium) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (--rnb-mq-large) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.secondBoxBullet {
    display: flex;
    align-items: center;
    margin-top: var(--rnb-spacing-big);
    margin-right: var(--rnb-spacing-medium);
}
