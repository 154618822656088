:root .container {
    --maxContainerSize: 1380px;
    --apr-example-padding-left: 20px;
}

.sectionWrapper {
    background-color: var(--rnb-colour-background-light);
}

.wrapper {
    max-width: var(--maxContainerSize);
    margin: 0 auto;
    padding-top: var(--rnb-spacing-huge);
    padding-bottom: var(--rnb-spacing-huge);
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 var(--rnb-spacing-large);
    background-color: var(--rnb-colour-background-light);
    line-height: var(--rnb-line-height-text);

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-super);
        flex-wrap: unset;
    }
}

.headingContent {
    padding-right: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        width: 50%;
    }
}

.aprExample {
    padding-top: var(--rnb-spacing-tiny);
    width: 100%;

    @media (--rnb-mq-large) {
        width: 50%;
        padding-left: var(--apr-example-padding-left);
    }
}

.cardContainer {
    padding: var(--rnb-spacing-medium);
    box-shadow: var(--rnb-shadow-earth);
    border-radius: var(--rnb-border-radius-medium);
    background-color: var(--rnb-colour-white);
}

.cardTextAmount {
    display: flex;
    justify-content: space-between;
}

.cardTextContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardLeftContent {
    margin: 0;
}

.cardRightContent {
    margin: 0;
}
