:root .section {
    --maxContentWidth: 524px;
    --maxContainerSize: 1380px;
    --mainBackgroundColour: white;
}

.title {
    display: flex;
    align-items: center;
    margin-left: -20px;

    :nth-child(4) {
        margin-left: var(--rnb-spacing-medium);
    }
}

.iconL {
    display: none;

    @media (--rnb-mq-large) {
        display: unset;
    }
}

.iconS {
    @media (--rnb-mq-large) {
        display: none;
    }
}

.subHeading {
    font-size: var(--rnb-font-size-medium);
    font-weight: var(--rnb-font-weight-regular);

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-large);
        line-height: var(--rnb-line-height-heading);
    }
}

.section {
    display: flex;
}

.tripleSection {
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;
}

.center {
    width: 100%;
    max-width: var(--maxContainerSize);
    display: flex;
    height: auto;
    flex-flow: wrap;
    justify-content: center;
    margin-top: var(--rnb-spacing-big);
    padding: 0 var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        margin: var(--rnb-spacing-super);
        flex-flow: unset;
        justify-content: space-between;
    }
}

.sectionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        align-items: flex-start;
        padding: 0 var(--rnb-spacing-big);
        margin-top: var(--rnb-spacing-big);
    }
}

.mainContent {
    max-width: var(--maxContentWidth);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--rnb-mq-large) {
        padding: 0 0 0 var(--rnb-spacing-super);
        align-items: flex-start;
        max-width: 424px;
    }

    @media (--rnb-mq-large) {
        max-width: var(--maxContentWidth);
    }
}

.infoImageL {
    width: 100%;
    height: auto;
    max-width: 400px;
    display: none;

    @media (--rnb-mq-large) {
        display: unset;
        position: relative;
        margin-right: var(--rnb-spacing-jumbo);
    }
}

.infoImageS {
    width: 100%;
    height: auto;
    max-width: 320px;
    margin-top: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        display: none;
    }
}

.blurb {
    display: flex;
    flex-direction: column;

    @media (--rnb-mq-medium) {
        flex-direction: row;
    }

    > * {
        margin-right: var(--rnb-spacing-small);
    }
}

.check {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    margin-bottom: var(--rnb-spacing-tiny);

    > * {
        margin-right: var(--rnb-spacing-tiny);
    }

    @media (--rnb-mq-large) {
        margin-bottom: var(--rnb-spacing-none);
    }
}
