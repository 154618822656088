:root {
    --background-color__4: var(--rnb-colour-white);
    --maxContentSize__2: 535px;
}

.phoneContainer {
    grid-area: media;
    justify-self: center;
    max-width: var(--maxContentSize__2);
    width: 100%;
}

.textContainer {
    grid-area: copy;
    justify-self: center;
    max-width: var(--maxContentSize__2);

    @media (--rnb-mq-large) {
        max-width: 632px;
        width: 100%;
    }
}

.section {
    background-color: var(--background-color__4);
    padding: var(--rnb-spacing-large) 0;

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-jumbo) 0;
    }
}

.sectionContent {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: 'title' 'media' 'copy';

    @media (--rnb-mq-large) {
        grid-template-columns: 50% 50%;
        grid-template-areas:
            'media title'
            'media copy';
    }
}

.textHeader {
    margin-bottom: var(--rnb-spacing-huge);
}

.textBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.textItem {
    margin-bottom: var(--rnb-spacing-large);
}

.title {
    grid-area: title;
    text-align: center;
    align-self: center;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.getProtect {
    margin: var(--rnb-spacing-large) 0;
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
