:root {
    --colGap: var(--rnb-spacing-medium);
    --smallGuttter: calc(var(--rnb-spacing-large) - var(--colGap));
    --mediumGuttter: calc(var(--rnb-spacing-big) - var(--colGap));
    --largeGuttter: calc(var(--rnb-spacing-super) - var(--colGap));
}

.contentGrid {
    grid-template-columns: repeat(4, 1fr);

    @media (--rnb-mq-small) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (--rnb-mq-medium) {
        grid-template-columns: repeat(8, 1fr);
    }

    @media (--rnb-mq-large) {
        grid-template-columns: repeat(12, 1fr);
    }

    @media (--rnb-mq-huge) {
        grid-template-columns: repeat(12, 5.75rem);
    }

    > [data-layout~='full-bleed'],
    > [data-layout~='content'] {
        grid-column-start: 1;
        grid-column-end: -1;
        position: relative;
        z-index: 1;
    }
}

.grid {
    grid-template-columns: var(--rnb-spacing-medium) repeat(4, 1fr) var(--rnb-spacing-medium);

    @media (--rnb-mq-small) {
        grid-template-columns: var(--smallGuttter) repeat(4, 1fr) var(--smallGuttter);
    }

    @media (--rnb-mq-medium) {
        grid-template-columns: var(--mediumGuttter) repeat(8, 1fr) var(--mediumGuttter);
    }

    @media (--rnb-mq-large) {
        grid-template-columns: var(--largeGuttter) repeat(12, 1fr) var(--largeGuttter);
    }

    @media (--rnb-mq-huge) {
        grid-template-columns: 1fr repeat(12, 5.75rem) 1fr;
    }

    > [data-layout~='content'] {
        grid-column-start: 2;
        grid-column-end: -2;
        position: relative;
        z-index: 1;
    }

    > [data-layout~='full-bleed'] {
        grid-column-start: 1;
        grid-column-end: -1;
        position: relative;
        z-index: 1;
    }
}

.grid,
.contentGrid {
    display: grid;
    grid-column-gap: var(--rnb-spacing-medium);

    /* CUSTOM GRID CLASSES */
    > [data-layout~='cols-2'] {
        grid-column-end: span 2;
    }

    > [data-layout~='cols-4'] {
        grid-column-end: span 4;
    }

    @media (--rnb-mq-medium) {
        > [data-layout~='cols-4@medium'] {
            grid-column-end: span 4;
        }

        > [data-layout~='cols-6@medium'] {
            grid-column-end: span 6;
        }
    }

    @media (--rnb-mq-large) {
        > [data-layout~='cols-3@large'] {
            grid-column-end: span 3;
        }

        > [data-layout~='cols-5@large'] {
            grid-column-end: span 5;
        }

        > [data-layout~='cols-6@large'] {
            grid-column-end: span 6;
        }

        > [data-layout~='cols-8@large'] {
            grid-column-end: span 8;
        }

        > [data-layout~='start-4@large'] {
            grid-column-start: 4;
        }
    }
}
