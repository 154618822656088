:root {
    --maxContentWidth: 524px;
    --maxContainerSize__3: 1380px;
}

.section {
    display: flex;
}

.howToEnter {
    width: 100%;
    display: block;
    justify-content: center;
    position: relative;
    z-index: var(--rnb-zindex-default);
    text-align: center;
    padding: 0 var(--rnb-spacing-large);
    margin-bottom: var(--rnb-spacing-super);
    margin-top: var(--rnb-spacing-super);

    @media (--rnb-mq-medium) {
        margin: 10rem;
        padding: 0;
        text-align: left;
    }
}

.stepWrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
    padding: 0 4rem;
    align-items: center;

    @media (--rnb-mq-medium) {
        padding: 0 2rem;
        flex-direction: row;
        align-items: unset;
    }

    @media (--rnb-mq-large) {
        padding: 0 4rem;
        flex-direction: row;
        align-items: unset;
    }
}

.howToHeader {
    display: flex;
    justify-content: center;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appIcons {
    @media (--rnb-mq-large) {
        display: flex;
    }
}

.icon {
    width: 120px;
    height: 40px;
}

.appInfo {
    max-width: 524px;
    text-align: center;
}
