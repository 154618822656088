:root .component {
    --iconHeight: 24px;
}

.component {
    border-radius: 50%;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.progressRing {
    bottom: 100%;
}


.donutContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;

    .heading {
        font-size: var(--rnb-font-size-small);
        font-weight: var(--rnb-font-weight-bold);
        color: white;
        margin-bottom: var(--rnb-spacing-large);
    }

    .subHeading {
        font-size: var(--rnb-font-size-mini);
        color: white;
    }
}

.scoreData {
    display: flex;
    align-items: center;
    margin-bottom: var(--rnb-spacing-large);
    opacity: 0;
    transition: opacity 1.5s ease-in-out;

    &.fadeIn {
        opacity: 1;
    }
}

.score {
    font-size: 78px;
    font-weight: var(--rnb-font-weight-bold);
    color: white;
}

.denominatorContainer {
    margin-left: var(--rnb-spacing-small);
}

.denominator {
    font-size: var(--rnb-font-size-mini);
    color: white;
}

.increaseContainer{
    display: flex;

    .increase {
        font-weight: var(--rnb-font-weight-bold);
        color: var(--rnb-colour-sea);
        margin-bottom: var(--rnb-spacing-small);
    }

    svg {
        fill: var(--rnb-colour-sea);
    }
}
