:root .section {
    --color: var(--rnb-colour-white);
    --maxContainerSize: 1380px;
    --tintedBackgroundColor: rgb(247, 247, 248);
}

.section {
    margin: 0 auto;
    display: flex;
    position: relative;
    padding-top: var(--rnb-spacing-huge);

    @media (--rnb-mq-medium) {
        height: 100vh;
        max-height: 900px;
        max-height: 1100px;
    }
}

.donutDesktopContainer {
    display: none;

    @media (--rnb-mq-medium) {
        display: flex;
        justify-content: center;
    }
}

.heroIntro {
    @media (--rnb-mq-medium) {
        height: 616px;
        max-width: 616px;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
    }
}

.donutDesktopImage {
    height: 350px;
    width: 350px;
}

.title {
    margin-top: var(--rnb-spacing-huge);
    margin-bottom: var(--rnb-spacing-medium);
    font-weight: var(--rnb-font-weight-medium);
    font-size: var(--rnb-font-size-large);
    line-height: var(--rnb-line-height-heading);

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-huge);
        margin-bottom: var(--rnb-spacing-large);
        max-width: 510px;
    }
}

.subHeading {
    font-size: var(--rnb-font-size-mini);
    line-height: var(--rnb-line-height-text);
    font-weight: var(--rnb-font-weight-regular);
    margin-bottom: var(--rnb-spacing-large);
    padding-right: 120px;

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-small);
        max-width: 500px;
    }
}

.heroContent {
    color: var(--color);
    z-index: var(--rnb-zindex-levitate);
    padding: var(--rnb-spacing-large) var(--rnb-spacing-large) var(--rnb-spacing-huge);
    flex: 1;
    overflow: hidden;

    @media (--rnb-mq-medium) {
        flex-direction: row;
        display: flex;
        align-items: center;
    }
}

.donutMobileContainer {
    position: absolute;
    height: 168px;
    width: 168px;
    right: -18px;
    top: -130px;

    &.shiftUp {
        top: -140px;
    }

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.featureContainer {
    @media (--rnb-mq-medium) {
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        padding: var(--rnb-spacing-medium) var(--rnb-spacing-big);
        max-width: var(--maxContainerSize);
        margin: 0 auto;
    }
}

.featureLists {
    background-color: var(--tintedBackgroundColor);
    padding: var(--rnb-spacing-big) 60px var(--rnb-spacing-big) var(--rnb-spacing-medium);
    position: relative;
    width: 100%;
}

.featureItem {
    display: flex;
    flex-direction: row;
    padding-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        flex-direction: column;
        align-items: center;
        padding-top: var(--rnb-spacing-medium);
        max-width: 250px;
    }

    &:last-of-type {
        padding-bottom: 0;
    }
}

.featureIcon {
    @media (--rnb-mq-medium) {
        flex-direction: column;
    }
}

.featureImage {
    height: 52px;
    width: 52px;
}

.featureTitle {
    margin-left: 30px;
    padding-top: 11px;

    @media (--rnb-mq-medium) {
        text-align: center;
    }
}

.sectionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.createProspectForm {
    max-width: 12rem;

    @media (--rnb-mq-medium) {
        max-width: 32.5rem;
    }
    
}

