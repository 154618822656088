.step {
    --flex-direction: row;
    --justify-content: flex-start;
    --align-items: flex-start;

    @media (--rnb-mq-medium) {
        --flex-direction: column;
        --justify-content: center;
        --align-items: center;
    }

    display: flex;
    flex-direction: var(--flex-direction);
    align-items: var(--align-items);
    justify-content: var(--justify-content);
}

.text {
    flex: 1;
}

.imageWrapper {
    --margin-right: var(--rnb-spacing-small);

    @media (--rnb-mq-medium) {
        --margin-right: var(--rnb-spacing-none);
    }

    margin-right: var(--margin-right);
}

.svgImage svg {
    --image-size: 2rem;

    @media (--rnb-mq-medium) {
        --image-size: 4rem;
    }

    fill: var(--rnb-colour-chill);
    width: var(--image-size);
    height: var(--image-size);
}

.image {
    --image-size: 4.5rem;

    @media (--rnb-mq-medium) {
        --image-size: 7.75rem;
    }

    border-radius: 50%;
    width: var(--image-size);
    height: var(--image-size);
    z-index: var(--rnb-zindex-default);
}
