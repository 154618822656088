.component {
    color: var(--rnb-theme-colour-primary, inherit);
    font-family: var(--rnb-font-family-cs-clarity);

    &.isTypeMicro {
        font-size: var(--rnb-font-size-micro);
        line-height: var(--rnb-line-height-text);
    }

    &.isTypeTiny {
        font-size: var(--rnb-font-size-tiny);
        line-height: var(--rnb-line-height-text);
    }

    &.isTypeMini {
        font-size: var(--rnb-font-size-mini);
        line-height: var(--rnb-line-height-text);
    }

    &.isTypeSmall {
        font-size: var(--rnb-font-size-small);
        line-height: var(--rnb-line-height-text);
    }

    &.isTypeMedium {
        font-size: var(--rnb-font-size-medium);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeLarge {
        font-size: var(--rnb-font-size-large);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeBig {
        font-size: var(--rnb-font-size-big);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeHuge {
        font-size: var(--rnb-font-size-huge);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeMassive {
        font-size: var(--rnb-font-size-massive);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeJumbo {
        font-size: var(--rnb-font-size-jumbo);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeGargantuan {
        font-size: var(--rnb-font-size-gargantuan);
        line-height: var(--rnb-line-height-heading);
    }

    &.isTypeRidiculous {
        font-size: var(--rnb-font-size-ridiculous);
        line-height: var(--rnb-line-height-heading);
    }

    &.isWeightThin {
        font-weight: var(--rnb-font-weight-thin);
    }

    &.isWeightLight {
        font-weight: var(--rnb-font-weight-light);
    }

    &.isWeightBook {
        font-weight: var(--rnb-font-weight-book);
    }

    &.isWeightRegular {
        font-weight: var(--rnb-font-weight-regular);
    }

    &.isWeightMedium {
        font-weight: var(--rnb-font-weight-medium);
    }

    &.isWeightBold {
        font-weight: var(--rnb-font-weight-bold);
    }

    &.isWeightBlack {
        font-weight: var(--rnb-font-weight-black);
    }

    &.isSpacingTight {
        letter-spacing: 0.0063rem;
    }

    &.isSpacingWide {
        letter-spacing: 0.025rem;
    }

    &.isSpacingExploded {
        letter-spacing: 0.05rem;
    }

    &.isUppercase {
        text-transform: uppercase;
    }

    &.isItalic {
        font-style: italic;
    }
}
