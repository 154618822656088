.container {
    --website-container-white: var(--rnb-colour-white);
    --website-container-grey: var(--rnb-colour-midnight-tint-4);
    --website-container-slate-tint-20: var(--slate-colour-slate-tint-20);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: var(--rnb-spacing-small) var(--rnb-spacing-huge);

    @media (--rnb-mq-medium) {
        width: 100%;
        flex-direction: row;
        flex-wrap: unset;
    }

    &.isTextCenterAligned {
        align-items: center;
    }

    &.containerized {
        padding: 24px 24px 0 24px;
        border-radius: 24px;

        @media (--rnb-mq-medium) {
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-auto-rows: minmax(100px, auto); 
        }
    }
}
.jumboText {
    /* This is a workaround otherwise I would need to create a whole new text and iamge component without rich text. That's also why these have to have important tags */
    h2 {
        font-size: var(--rnb-font-size-large) !important;   
        font-weight: var(--rnb-font-weight-medium) !important;

        @media (--rnb-mq-large) {
            font-size: var(--rnb-font-size-huge) !important;
            font-weight: var(--rnb-font-weight-medium) !important;
        }
    }

    div[data-qa="text"]:not(a *), p {
        font-size: var(--rnb-font-size-small) !important;;
        line-height: var(--rnb-line-height-text)!important;

        @media (--rnb-mq-large) {
            font-size: 22px !important;
            font-weight: var(--rnb-font-weight-regular) !important;
            line-height: var(--rnb-line-height-text) !important;
        }

    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity ease-in-out .5s;

    &.containerized {
        flex: 1;
        object-fit: contain;
        img {
            object-fit: contain !important;
        }
    }
}

.headingText {
    padding-top: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        padding-top: unset;
    }
}

.bodyText {
    white-space: pre-wrap;
    padding-top: var(--rnb-spacing-micro);
}

.isImageFirst {
    flex-direction: column-reverse;

    @media (--rnb-mq-medium) {
        flex-direction: row-reverse;
    }
}

.cta, .mobileOnlyCta {
    &.light{
        a > div {
            color: white;
        }
    }
}


.mobileOnlyCta {
    display: block;
    width: 100%;
    margin: var(--rnb-spacing-small) 0;
    @media (--rnb-mq-medium) {
        display: none;
    }
}

.forceImageLastOnMobile:not(.isImageFirst) {
    flex-direction: column;

    @media (--rnb-mq-medium) {
        flex-direction: row;
    }
}

.forceImageLastOnMobile.isImageFirst {
    flex-direction: column;

    @media (--rnb-mq-medium) {
        flex-direction: row-reverse;
    }
}

.forceImageLastOnMobile {
    .cta {
        display: none;
        @media (--rnb-mq-medium) {
           display: block;
        }
    }
}

.cardContainer {
    margin-top: var(--rnb-spacing-large);
}

.cardContainer:not(:last-child) {
    margin-bottom: var(--rnb-spacing-large);
}

.cardContainer + .cardContainer {
    margin-top: var(--rnb-spacing-big);
}

.isTextCenterAligned {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.containerizedText {
    flex: 1;
}

.cta {
    margin-top: var(--rnb-spacing-big);

    &.containerized {
        margin-bottom: var(--rnb-spacing-large);
    }
}
