:root {
    --hero-content-z-index: 10;
    --background-color: var(--rnb-colour-midnight-tint-4);
    --color__2: var(--rnb-colour-white);
    --maxWidthTitle: 475px;
}

.section {
    background-color: var(--background-color);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0% 0%, 0% 100%, 100% 97%, 100% 0%);
}

.yourDetails {
    padding: var(--rnb-spacing-jumbo) 0;
}

.sectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    margin: var(--rnb-spacing-medium);
    max-width: var(--maxWidthTitle);
}

.yourDetailsContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detailList {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 'media';

    @media (--rnb-mq-medium) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'media media';
    }

    @media (--rnb-mq-large) {
        grid-template-columns: 35% 30% 35%;
        grid-template-areas:
            '. media .'
            '. media .';
    }
}

.signContainer {
    grid-area: media;
    justify-self: center;
    width: 60%;
    align-self: center;

    @media (--rnb-mq-large) {
        width: 100%;
    }
}

.textContainer {
    margin: var(--rnb-spacing-huge) var(--rnb-spacing-small);
}

.textHeader {
    margin-bottom: var(--rnb-spacing-tiny);
    text-align: center;

    @media (--rnb-mq-phablet) {
        text-align: left;
    }
}

.textBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    @media (--rnb-mq-phablet) {
        text-align: left;
    }
}

.getProtect {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.icon {
    margin-bottom: var(--rnb-spacing-medium);
    width: 32px;
    height: 32px;
    margin-left: calc(50% - 14px);

    @media (--rnb-mq-large) {
        width: 38px;
        height: 38px;
        margin-left: calc(50% - 20px);
    }
}

.circle {
    position: absolute;
    background-color: var(--color__2);
    width: 70px;
    height: 70px;
    border-radius: 35px;
    z-index: -1;
    transform: translate(0, -75px);
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

.iconContainer {
    position: relative;
}
