.component {
    --cols: 1;
    --gap: var(--rnb-spacing-medium);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap);

    @media (--rnb-mq-medium) {
        --cols: 2;
    }

    @media (--rnb-mq-large) {
        --cols: 3;
    }
}

.component > * {
    height: auto;
    flex-grow: 0;
    /* calculate the width of each card, factoring in the amount of columns and the gaps */
    flex-basis: calc(100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1));

    /* if there's only one card, make it full width */
    &:only-child {
        flex-grow: 1;
    }

    /* if there's only two cards, make them half width */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ * {
        @media (--rnb-mq-medium) {
            --cols: 2;
        }
    }

    /* if the number of cards is divisible by 4, make them half width */
    &:first-child:nth-last-child(4n),
    &:first-child:nth-last-child(4n) ~ * {
        @media (--rnb-mq-medium) {
            --cols: 2;
        }
    }
}
