.component {
    display: grid;
    grid-template-columns: var(--rnb-spacing-big) auto;
    gap: var(--rnb-spacing-medium) var(--rnb-spacing-large);
    align-items: center;

    svg {
        --size: 2rem;

        fill: currentColor;
        height: var(--size);
        width: var(--size);
    }
}
