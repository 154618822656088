/* stylelint-disable sh-waqar/declaration-use-variable */
:root {
    --nav-height-small: 40px;
    --nav-height-large: 92px;
    --center-link-width: 152px;
}

.mobileOnly {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.desktopOnly {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.pullDownContent {
    margin-bottom: var(--nav-height-small);

    @media (--rnb-mq-large) {
        margin-bottom: var(--nav-height-large);
    }
}

.wrapper {
    width: 100%;
    z-index: var(--rnb-zindex-levitate);
    height: var(--nav-height-small);

    @media (--rnb-mq-large) {
        height: var(--nav-height-large);
    }

    &.wrapperThemeTransparent {
        position: absolute;
        top: 0;
        background: linear-gradient(-180deg, rgba(var(--rnb-colour-black-rgb), 0.9), transparent);
        color: var(--rnb-colour-white);
    }

    &.wrapperThemeLight {
        position: absolute;
        top: 0;
        background: var(--rnb-colour-white);
        color: var(--rnb-colour-midnight);
    }
}

.centerContent {
    border-bottom: var(--rnb-border-width-default) solid var(--rnb-colour-white-alpha-32);
    padding: var(--rnb-spacing-large) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:last-child {
        @media (--rnb-mq-large) {
            margin-left: var(--rnb-spacing-super);
        }

        @media (--rnb-mq-huge) {
            margin-left: var(--rnb-spacing-jumbo);
        }
    }
}

.desktopLogoLink {
    color: inherit;

    &:visited {
        color: inherit;
    }

    &:link {
        color: inherit;
    }
}

.links {
    & > a {
        color: inherit;
        font-weight: var(--rnb-font-weight-bold);
        text-decoration: none;
        opacity: 0.7;

        &:visited {
            color: inherit;
        }

        &:link {
            color: inherit;
        }

        &:not(:last-child) {
            margin-right: var(--rnb-spacing-big);
        }

        &.activeLinkTransparent {
            opacity: 1;
        }

        &.activeLinkLight {
            opacity: 1;
            color: var(--rnb-colour-chill);
        }
    }
}

.mobileLink {
    color: inherit;
    text-decoration: none;
    font-size: var(--rnb-font-size-large);

    &:visited {
        color: inherit;
    }

    &:link {
        color: inherit;
    }
}

.mobileCenterLink {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    height: var(--nav-height-small);

    & > svg {
        width: var(--center-link-width);
    }
}

.mobileNavOpen {
    display: flex;
    flex-direction: column;
    padding: var(--rnb-spacing-huge) var(--rnb-spacing-medium) var(--rnb-spacing-huge) var(--rnb-spacing-huge);
    color: var(--rnb-colour-white);

    & > a {
        margin-bottom: var(--rnb-spacing-large);
    }
}
