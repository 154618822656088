.icon {
    color: var(--rnb-theme-colour-primary, inherit);
    align-self: flex-start;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: var(--rnb-spacing-small);
}

.flatCard {
    padding: 0 var(--rnb-spacing-large);
}

.backgroundWrapper {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.backgroundChill {
    background-color: var(--rnb-colour-chill-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-chill-tint-32);
}

.backgroundMidnight {
    background-color: var(--rnb-colour-midnight-tint-4);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-32);
}

.backgroundDandelion {
    background-color: var(--rnb-colour-dandelion-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-dandelion-tint-64);
}

.backgroundRose {
    background-color: var(--rnb-colour-rose-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-rose-tint-32);
}

.backgroundMoss {
    background-color: var(--rnb-colour-moss-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-moss-tint-64);
}

.backgroundLavender {
    background-color: var(--rnb-colour-lavender-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-lavender-tint-32);
}

.backgroundReef {
    background-color: var(--rnb-colour-reef-tint-8);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-reef-tint-64);
}

.backgroundWhite {
    background-color: var(--rnb-colour-white);
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-16);
}

.backgroundCoral-tint-10 {
    background-color: var(--slate-colour-coral-tint-10);
    border: var(--rnb-border-width-thin) solid var(--slate-colour-coral-tint-40);
}

.backgroundSlate-tint-5 {
    background-color: var(--slate-colour-slate-tint-5);
    border: var(--rnb-border-width-thin) solid var(--slate-colour-slate-tint-40);
}

.highlightChill {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-chill);
}

.highlightMidnight {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-midnight);
}

.highlightDandelion {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-dandelion);
}

.highlightRose {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-rose);
}

.highlightMoss {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-moss);
}

.highlightReef {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-reef);
}

.highlightLavender {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-lavender);
}

.highlightWhite {
    border-left: var(--rnb-border-width-thick) solid var(--rnb-colour-white);

    .icon {
        color: var(--rnb-theme-colour-primary, inherit);
    }
}
