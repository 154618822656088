.wrapper {
    text-align: center;
    color: var(--rnb-colour-midnight);
    padding: 0 var(--rnb-spacing-large);
}

.preheader {
    line-height: var(--rnb-line-height-heading);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--rnb-font-size-mini);
    text-transform: uppercase;
}

.header {
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--rnb-font-size-massive);

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-jumbo);
    }
}
