:root .howToGetALoanWrapper {
    --maxContainerSize: 1380px;
    --get-a-loan-padding-left: 20px;
}

.howToGetALoanWrapper * {
    background-color: var(--backgroundColor);
    max-width: var(--maxContainerSize);
    margin: 0 auto;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 0 var(--rnb-spacing-large);
    margin-bottom: var(--rnb-spacing-big);
    margin-top: var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-super);
        flex-wrap: unset;
    }
}

.imageContainer {
    width: 100%;
    padding-top: var(--rnb-spacing-medium);
    padding-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        display: unset;
        width: 50%;
    }
}

.imageStyle {
    border-radius: 25px;
}

.informationDiv {
    width: 100%;
    padding-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-super);
        width: 100%;
    }

    @media (--rnb-mq-large) {
        padding-left: var(--get-a-loan-padding-left);
        width: 50%;
    }
}

.cardContainer {
    padding: var(--rnb-spacing-medium);
    box-shadow: var(--rnb-shadow-earth);
    border-radius: var(--rnb-border-radius-medium);
}

.cardTextAmount {
    display: flex;
    justify-content: space-between;
}

.cardTextContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardLeftContent {
    margin: 0;
}

.cardRightContent {
    margin: 0;
}
