:root .ratings {
    --negative-star-margin: -2px;
    --icon-size-small: 1rem;
    --icon-size-medium: 1.25rem;
    --icon-size-large: 2rem;
}

.ratings {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--rnb-colour-sand);

    /* Pulling the whole container so that we can align left */
    margin-left: var(--negative-star-margin);

    &.isUnrated {
        color: var(--rnb-colour-midnight-tint-48);
    }

    & > .star ~ .star {
        margin-left: var(--negative-star-margin);
    }

    & .star:last-child {
        padding-right: var(--rnb-spacing-none);
    }
}

.totalRatings {
    margin-left: var(--rnb-spacing-micro);
    color: var(--rnb-colour-midnight-tint-48);
}

.rating {
    margin-left: var(--rnb-spacing-micro);
    color: var(--rnb-colour-midnight);
}

.isStarSmall {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
}

.isStarMedium {
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
}

.isStarLarge {
    width: var(--icon-size-large);
    height: var(--icon-size-large);
}

.visuallyHidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}
