:root .container {
    --opacity: 0.64;
    --fallback-opacity: 0.9;
    --blur-radius: 16px;
}

.container {
    position: relative;

    &.isFullHeight {
        min-height: 100%;
        display: flex;
        flex-grow: 1;
    }
}

/* Theme */
.isThemeTransparent {
    background-color: var(--rnb-colour-transparent);
}

.isThemeWhite {
    background-color: var(--rnb-colour-white);
    color: var(--rnb-colour-midnight);
}

.isThemeLight {
    background-color: var(--rnb-colour-midnight-tint-4);
    color: var(--rnb-colour-midnight);
}

.isThemeDark {
    background-color: var(--rnb-colour-midnight);
    color: var(--rnb-colour-white);
}

.isTranslucent {
    opacity: var(--fallback-opacity);

    @supports (backdrop-filter: blur(10px)) or (--webkit-backdrop-filter: blur(10px)) {
        opacity: var(--opacity);
        backdrop-filter: blur(var(--blur-radius));
    }
}

.contentContainer {
    z-index: var(--rnb-zindex-default);
    width: 100%;
    padding: var(--rnb-spacing-none) var(--rnb-spacing-medium);
    margin: 0 auto;

    @media (--rnb-mq-small) {
        padding: var(--rnb-spacing-none) var(--rnb-spacing-large);
    }

    @media (--rnb-mq-phablet) {
        padding: var(--rnb-spacing-none) var(--rnb-spacing-big);
    }

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-none) var(--rnb-spacing-super);
    }

    @media (--rnb-mq-huge) {
        padding: var(--rnb-spacing-none);
    }

    /* Size */
    &.isSizeTiny {
        max-width: 375px;
    }

    &.isSizeSmall {
        max-width: 632px;
    }

    &.isSizeMedium {
        max-width: 768px;
    }

    &.isSizeMediumLarge {
        max-width: 848px;
    }

    &.isSizeLarge {
        max-width: 1024px;
    }

    &.isSizeHuge {
        max-width: 1280px;
    }

    &.isSizeMax {
        max-width: 100%;
    }

    /* Top */
    &.isTopSpacingNone {
        padding-top: var(--rnb-spacing-none);
    }

    &.isTopSpacingMicro {
        padding-top: var(--rnb-spacing-micro);
    }

    &.isTopSpacingTiny {
        padding-top: var(--rnb-spacing-tiny);
    }

    &.isTopSpacingSmall {
        padding-top: var(--rnb-spacing-small);
    }

    &.isTopSpacingMedium {
        padding-top: var(--rnb-spacing-medium);
    }

    &.isTopSpacingLarge {
        padding-top: var(--rnb-spacing-large);
    }

    &.isTopSpacingBig {
        padding-top: var(--rnb-spacing-big);
    }

    &.isTopSpacingHuge {
        padding-top: var(--rnb-spacing-huge);
    }

    &.isTopSpacingSuper {
        padding-top: var(--rnb-spacing-super);
    }

    &.isTopSpacingJumbo {
        padding-top: var(--rnb-spacing-jumbo);
    }

    /* Bottom */
    &.isBottomSpacingNone {
        padding-bottom: var(--rnb-spacing-none);
    }

    &.isBottomSpacingMicro {
        padding-bottom: var(--rnb-spacing-micro);
    }

    &.isBottomSpacingTiny {
        padding-bottom: var(--rnb-spacing-tiny);
    }

    &.isBottomSpacingSmall {
        padding-bottom: var(--rnb-spacing-small);
    }

    &.isBottomSpacingMedium {
        padding-bottom: var(--rnb-spacing-medium);
    }

    &.isBottomSpacingLarge {
        padding-bottom: var(--rnb-spacing-large);
    }

    &.isBottomSpacingBig {
        padding-bottom: var(--rnb-spacing-big);
    }

    &.isBottomSpacingHuge {
        padding-bottom: var(--rnb-spacing-huge);
    }

    &.isBottomSpacingSuper {
        padding-bottom: var(--rnb-spacing-super);
    }

    &.isBottomSpacingJumbo {
        padding-bottom: var(--rnb-spacing-jumbo);
    }

    &.excludeGutter {
        padding-left: var(--rnb-spacing-none);
        padding-right: var(--rnb-spacing-none);
    }
}
