:root .ring {
    --rnb-colour-midnight-tint-4: rgba(255, 255, 255, 0.3);
    --circleRadius: 50%;
    --scoreValueSize: 3.25rem;
    --greyOpaqueBackground: rgba(0, 0, 0, 0.2);
    --donutTopContentOffset: 27.5%;
    --donutBottomContentOffset: 72.5%;
    --darkenBackground: rgba(0, 0, 0, 0.5);
    --animateDuration: 0.7s;
    --animateOpacityStart: 0.2;
    --animateOpacityMiddle: 0.5;
    --animateOpacityEnd: 1;
    --ringSize: 200px;
}

.score {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: var(--rnb-colour-white);
    font-size: var(--scoreValueSize);
    font-weight: var(--rnb-font-weight-thin);
}

.donutText {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--rnb-colour-white);
}

.donutLabel {
    top: var(--donutTopContentOffset);
    width: 100%;
}

.donutMaxScore {
    top: var(--donutBottomContentOffset);
}

.ring {
    margin: 0 auto;
    position: relative;
    border: var(--rnb-border-width-thin) var(--rnb-colour-midnight-tint-4) solid;
    border-radius: var(--circleRadius);
    background: var(--greyOpaqueBackground);
    height: var(--ringSize);
    width: var(--ringSize);

    @media (--rnb-mq-large) {
        width: 100%;
        height: 100%;
    }
}

.fadeOpacity {
    animation: fadeOpacity var(--animateDuration);
}

@keyframes fadeOpacity {
    0% {
        opacity: var(--animateOpacityStart);
    }

    50% {
        opacity: var(--animateOpacityMiddle);
    }

    100% {
        opacity: var(--animateOpacityEnd);
    }
}
