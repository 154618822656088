.clearsaverPanelSection {
    --maxContainerSize: 1200px;
    --top-padding: 68px;
    --list-column-gap: 16px;
    --list-row-gap: 23px;
    --parters-section-padding-top: 40px;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--top-padding);
    color: var(--rnb-colour-white);
    overflow: hidden;
}

.clearsaverPanel {
    --top-padding: 0;
    --bottom-padding: var(--rnb-spacing-large);
    --side-padding: var(--rnb-spacing-large);

    @media (--rnb-mq-small) {
        --bottom-padding: 98px;
    }

    @media (--rnb-mq-large) {
        --top-padding: var(--rnb-spacing-super);
        --bottom-padding: 90px;
        --side-padding: 40px;
    }

    position: relative;
    width: 100%;
    max-width: var(--maxContainerSize);
    padding: var(--top-padding) var(--side-padding) var(--bottom-padding);
}

.titleContainer {
    display: flex;
    align-items: center;
}

.balloonTextSvg {
    width: 100%;

    @media (--rnb-mq-large) {
        width: 100%;
    }
}

.clearsaverCta {
    margin-top: var(--rnb-spacing-large);
}

.partersSection {
    padding-top: var(--parters-section-padding-top);
}

.partners {
    --margin-top: 28px;
    --margin-bottom: 26px;

    display: flex;
    margin: var(--margin-top) 0 var(--margin-bottom);
}

.partnerLogo svg {
    --margin-right: var(--rnb-spacing-small);
    --logo-height: 20px;
    --logo-width: 80px;

    @media (--rnb-mq-small) {
        --logo-height: 23px;
    }

    @media (--rnb-mq-medium) {
        --margin-right: var(--rnb-spacing-big);
        --logo-height: 60px;
        --logo-width: 150px;
    }

    height: var(--logo-height);
    width: var(--logo-width);
    margin-right: var(--margin-right);
}

.list {
    display: grid;
    gap: var(--list-row-gap) var(--list-column-gap);
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-bottom: var(--rnb-spacing-large);
    padding-right: 15%;
}

.listItem {
    padding-right: 45%;
}

.clearsaverBackground {
    width: 100%;
    height: 100%;
}

.backgroundDesktop {
    position: absolute;
    top: 0;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
    background-color: var(--rnb-colour-chill);
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}

.backgroundMobile {
    position: absolute;
    top: 0;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
    background-color: var(--rnb-colour-chill);
    display: inline;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.descriptionContainer {
    padding-right: 40%;
    padding-top: var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        padding-right: 20%;
    }
}

.visuallyHidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.disclaimer {
    padding-right: 10%;
}

.buttonSignUpMobile {
    @media (--rnb-mq-medium) {
        display: none;
    }
}

.buttonSignUpDesktop {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}
