:root {
    --wrapper-margin-large: 0 6%;
    --wrapper-margin-huge: 0 8%;
    --wrapper-height-large: 800px;
    --wrapper-height-huge: 1000px;
    --header-font-size-jumbo-medium: 3rem;
    --header-font-size-jumbo-large: 5rem;
    --box-height-large: 610px;
    --box-height-huge: 813px;
    --box-width-small: 100%;
    --box-width-medium: auto;
    --box-width-large: 58.33%;
    --box-background-color-gray: rgb(247, 242, 239);
    --box-header-color-sand: rgb(173, 159, 140);
    --image-width-small__3: 100%;
    --image-width-medium__2: auto;
    --image-width-large__3: 500px;
    --image-width-huge: 680px;
    --right-component-top-offset: var(--rnb-spacing-super);
}

.wrapper {
    position: relative;

    &.isFlipped {
        display: none;

        &.isFlippedMobile {
            display: block;

            @media (--rnb-mq-large) {
                display: none;
            }
        }
    }

    &:not(.isFlipped) {
        display: block;

        &.isFlippedMobile {
            display: none;

            @media (--rnb-mq-large) {
                display: block;
            }
        }
    }

    @media (--rnb-mq-large) {
        margin: var(--wrapper-margin-large);

        /* Prevent absolutely positioned image increasing div size */
        height: var(--wrapper-height-large);
    }

    @media (--rnb-mq-huge) {
        margin: var(--wrapper-margin-huge);

        /* Prevent absolutely positioned image increasing div size */
        height: var(--wrapper-height-huge);
    }
}

.shadedBox {
    color: var(--rnb-colour-midnight-tint-64);
    width: var(--box-width-small);
    padding-top: var(--rnb-spacing-super);
    padding-right: var(--rnb-spacing-large);
    padding-left: var(--rnb-spacing-large);
    padding-bottom: var(--rnb-spacing-jumbo);

    &.isThemeSand {
        background-color: var(--rnb-colour-sand-tint-16);
    }

    &.isThemeChill {
        background-color: var(--rnb-colour-chill-tint-8);
    }

    &.isThemeSea {
        background-color: var(--rnb-colour-sea-tint-8);
    }

    &.isThemeGray {
        background-color: var(--box-background-color-gray);
    }

    @media (--rnb-mq-medium) {
        width: var(--box-width-medium);
        margin: var(--rnb-spacing-big);
        padding-top: calc(var(--rnb-spacing-super) + var(--rnb-spacing-large));
        padding-right: calc(var(--rnb-spacing-jumbo) + var(--rnb-spacing-huge));
        padding-left: calc(var(--rnb-spacing-super) + var(--rnb-spacing-large));
        padding-bottom: calc(var(--rnb-spacing-super) + var(--rnb-spacing-large));
    }

    @media (--rnb-mq-large) {
        position: absolute;
        margin: 0;
        width: var(--box-width-large);
        height: var(--box-height-large);

        /* Default (box on left, image on right) */
        padding-top: var(--rnb-spacing-super);
        padding-right: var(--rnb-spacing-jumbo);
        padding-left: var(--rnb-spacing-big);
        padding-bottom: 0;
    }

    @media (--rnb-mq-huge) {
        height: var(--box-height-huge);
        padding-top: var(--rnb-spacing-jumbo);
        padding-left: var(--rnb-spacing-super);
        padding-right: calc(2 * var(--rnb-spacing-jumbo));
    }

    /* Flipped (box on right, image on left) */
    &.isFlipped {
        @media (--rnb-mq-large) {
            top: var(--right-component-top-offset);
            padding-right: var(--rnb-spacing-big);
            padding-left: calc(var(--rnb-spacing-big) + var(--rnb-spacing-jumbo));
            right: 0;
        }

        @media (--rnb-mq-huge) {
            padding-left: calc(2 * var(--rnb-spacing-jumbo));
            padding-right: var(--rnb-spacing-super);
        }
    }
}

.shadedBoxHeader {
    font-weight: var(--rnb-font-weight-bold);
    margin-bottom: var(--rnb-spacing-medium);

    &.headerSizeJumbo {
        font-size: var(--header-font-size-jumbo-medium);

        @media (--rnb-mq-large) {
            font-size: var(--header-font-size-jumbo-large);
        }
    }

    &.headerSizeMassive {
        font-size: var(--rnb-font-size-big);

        @media (--rnb-mq-medium) {
            font-size: var(--rnb-font-size-huge);
        }

        @media (--rnb-mq-large) {
            font-size: var(--rnb-font-size-massive);
        }
    }

    &.shadedBoxHeaderSand {
        color: var(--box-header-color-sand);
    }

    &.shadedBoxHeaderChill {
        color: var(--rnb-colour-chill-shade-64);
    }

    &.shadedBoxHeaderSea {
        color: var(--rnb-colour-midnight);
    }

    &.shadedBoxHeaderGray {
        color: var(--rnb-colour-midnight-alpha-96);
    }
}

.shadedBoxImage {
    width: var(--image-width-small__3);
    z-index: var(--rnb-zindex-levitate);

    @media (--rnb-mq-medium) {
        margin: 0 var(--rnb-spacing-big);
        width: var(--image-width-medium__2);
    }

    @media (--rnb-mq-large) {
        margin: 0;
        position: absolute;
        width: var(--image-width-large__3);
        top: var(--right-component-top-offset);
        right: 0;

        &.isFlippedImage {
            top: 0;
            left: 0;
            right: 0;
        }
    }

    @media (--rnb-mq-huge) {
        width: var(--image-width-huge);
    }
}
