.slider {
    margin-top: var(--rnb-spacing-large);
}

/*********** Baseline, reset styles ***********/
.slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --thumb-x: calc(0.5 * 0.6rem + var(--ratio) * (100% - 0.6rem));
}

/* Removes default focus */
.slider:focus {
    outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
.slider::-webkit-slider-runnable-track {
    background: linear-gradient(var(--rnb-colour-chill), var(--rnb-colour-chill)) 0 / var(--thumb-x) 100% no-repeat,
        var(--rnb-colour-tertiary-light);
    border-radius: var(--rnb-border-radius-large);
    height: 0.25rem;
}

/* slider thumb */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.6rem; /* Centers thumb on the track */
    background-color: var(--rnb-colour-chill);
    border-radius: var(--rnb-border-radius-large);
    height: 1.5rem;
    width: 1.5rem;
}

.slider:focus::-webkit-slider-thumb {
    outline: 3px solid var(--rnb-colour-chill);
    outline-offset: 0.125rem;
}

/*********** Firefox styles ***********/
/* slider track */
.slider::-moz-range-track {
    background: linear-gradient(var(--rnb-colour-chill), var(--rnb-colour-chill)) 0 / var(--thumb-x) 100% no-repeat,
        var(--rnb-colour-tertiary-light);
    border-radius: var(--rnb-border-radius-large);
    height: 0.25rem;
}

/* slider thumb */
.slider::-moz-range-thumb {
    background-color: var(--rnb-colour-chill);
    border: none; /*Removes extra border that FF applies*/
    border-radius: var(--rnb-border-radius-large);
    height: var(--rnb-spacing-large);
    width: var(--rnb-spacing-large);
}

.slider:focus::-moz-range-thumb {
    outline: 3px solid var(--rnb-colour-chill);
    outline-offset: 0.125rem;
}

.sliderLabels {
    color: var(--rnb-colour-tertiary-light);
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}
