:root {
    --maxContainerSize__7: 1380px;
    --customSpacingRidiculous: 10rem;
    --backgroundColour: rgb(40, 103, 94);
}

.section {
    position: relative;
    width: 100%;
    background-color: var(--backgroundColour);
}

.saveSection {
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;
}

.saveContentWrapper {
    position: relative;
    width: 100%;
    max-width: var(--maxContainerSize__7);
    display: flex;
    padding: 0 var(--rnb-spacing-large);
    color: var(--rnb-colour-white);
    flex-direction: column;
    align-items: center;
    z-index: var(--rnb-zindex-levitate);
    margin-bottom: var(--rnb-spacing-super);
    margin-top: var(--rnb-spacing-super);

    @media (--rnb-mq-medium) {
        margin: var(--customSpacingRidiculous);
        align-items: unset;
        padding: 0 var(--rnb-spacing-super);
    }
}

.header {
    margin-bottom: var(--rnb-spacing-medium);
    text-align: center;

    @media (--rnb-mq-medium) {
        text-align: left;
    }
}

.cardWrapper {
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;

    @media (min-width: 1440px) {
        justify-content: space-between;
    }
}

.button {
    display: flex;
    justify-content: center;
}
