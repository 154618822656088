.component {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--rnb-spacing-big);
    color: var(--rnb-colour-primary-light);

    @media (--rnb-mq-medium) {
        flex-direction: row;
        gap: var(--rnb-spacing-large);
        justify-content: space-between;
    }
}

.inputs {
    @media (--rnb-mq-medium) {
        width: 50%;
        margin-right: 1rem;
    }
}

.outputs {
    display: flex;
    flex-direction: column;
    @media (--rnb-mq-medium) {
        width: 50%;
    }
}

.bold {
    font-weight: var(--rnb-font-weight-bold);
}

.icon {
    width: 27.45px;
}

.resetButton {
    margin-top: var(--rnb-spacing-large);
}

.disclaimer {
    background-color: var(--rnb-colour-moss-tint-8);
    padding: var(--rnb-spacing-large);
}

.results {
    background-color: var(--rnb-colour-moss-tint-16);
    padding: var(--rnb-spacing-large);
}

.activeControlText {
    color: var(--rnb-colour-white);
}
