.visuallyHidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

.segmentedControlOption {
    border-width: var(--rnb-border-width-thin);
    border-style: solid;
    flex: 1 0 0;
    position: relative;
    text-align: center;
    white-space: nowrap;

    /* safari bug fix */
    margin: 0;

    &.isFitted {
        flex: 1 1 auto;
    }

    &:focus-within {
        outline: 2px solid var(--rnb-colour-chill);
        outline: auto 5px -webkit-focus-ring-color;
    }

    &.isThemeLight {
        border-color: var(--rnb-colour-midnight-tint-64);
        background-color: var(--rnb-colour-white);
        color: var(--rnb-colour-midnight);

        &:not(.isDisabled):hover,
        &:not(.isDisabled):focus-within {
            border-color: var(--rnb-colour-midnight);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-midnight-tint-32);
            color: var(--rnb-colour-midnight-tint-32);
        }

        &.isActive {
            background-color: var(--rnb-colour-midnight);
            border-color: var(--rnb-colour-midnight);
            color: var(--rnb-colour-white);
        }
    }

    &.isThemeDark {
        border-color: var(--rnb-colour-white-alpha-64);
        background-color: var(--rnb-colour-midnight);
        color: var(--rnb-colour-white);

        &:not(.isDisabled):hover,
        &:not(.isDisabled):focus-within {
            border-color: var(--rnb-colour-white);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-white-alpha-16);
        }

        &.isActive {
            background-color: var(--rnb-colour-white);
            border-color: var(--rnb-colour-white);
            color: var(--rnb-colour-midnight);
        }
    }

    &:not(.isDisabled):hover,
    &:not(.isDisabled):focus-within {
        z-index: var(--rnb-zindex-default);
    }

    &:not(.isGrouped) {
        @media (--rnb-mq-medium) {
            border-radius: var(--rnb-border-radius-small);
        }
    }

    &:first-child {
        border-top-left-radius: var(--rnb-border-radius-small);
        border-bottom-left-radius: var(--rnb-border-radius-small);

        &.isDockedBottom {
            border-bottom-left-radius: 0;
        }
    }

    &:last-child {
        border-top-right-radius: var(--rnb-border-radius-small);
        border-bottom-right-radius: var(--rnb-border-radius-small);

        &.isDockedBottom {
            border-bottom-right-radius: 0;
        }
    }

    &:not(.isDisabled),
    &:not(.isDisabled) .label {
        cursor: pointer;
    }

    &.isActive {
        z-index: var(--rnb-zindex-levitate);
    }

    & + & {
        margin-left: -1px;

        &:not(.isGrouped) {
            @media (--rnb-mq-medium) {
                margin-left: var(--rnb-spacing-small);
            }
        }
    }
}

.label {
    display: block;

    &.isSizeTiny {
        padding: var(--rnb-spacing-micro);
    }

    &.isSizeSmall {
        padding: var(--rnb-spacing-tiny);
    }

    &.isSizeLarge {
        padding: var(--rnb-spacing-small);
    }
}
