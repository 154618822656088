.authorImage {
    width: 100%;
    margin-right: var(--rnb-spacing-medium);
    max-width: 36px;

    &.large {
        max-width: 64px;
    }
}

.image {
    border-radius: 50%;
}

.authorBio {
    display: flex;
    flex-direction: row;
    padding-top: var(--rnb-spacing-large);

    &.isArticleFooter {
        padding-left: var(--rnb-spacing-medium);
        border-radius: var(--rnb-border-radius-medium);
        background-color: var(--rnb-colour-chill-tint-8);
    }
}

.alignCenter {
    align-self: center;
}

.dotDivider::before {
    content: '∙';
    padding: 0 var(--rnb-spacing-micro);
}
