.textAndImageGrid {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    box-sizing: border-box;
    display: grid;

    grid-template-columns: 1fr;
    grid-column-gap: var(--rnb-spacing-medium);
    grid-row-gap: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--rnb-spacing-huge);
        grid-row-gap: var(--rnb-spacing-huge);
    }
}

h2 {
    font-size: var(--rnb-font-size-large) ;   
    font-weight: var(--rnb-font-weight-medium) ;

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-big) ;
        font-weight: var(--rnb-font-weight-medium) ;
    }
}

div[data-qa="text"]:not(a *), p {
    font-size: var(--rnb-font-size-small) ;
    line-height: var(--rnb-line-height-text);

    @media (--rnb-mq-large) {
        font-weight: var(--rnb-font-weight-regular) ;
        line-height: var(--rnb-line-height-text) ;
    }

}

.cta {
    margin-top: var(--rnb-spacing-large);
    text-align: left;
    padding: 0;

    a {
        padding: 0;
        text-align: left;
    }
}

.image {
    object-fit: contain;
    transition: opacity ease-in-out .5s;
    display: block ;

    img {
        height: auto;
    }

    &.containerized {
        flex: 1;
    }
}

.textAndImageChild {
    --website-container-white: var(--rnb-colour-white);
    --website-container-grey: var(--rnb-colour-midnight-tint-4);
    border-radius: var(--rnb-spacing-large);
    display: grid;
    grid-gap: var(--rnb-spacing-large);

    &.containerized {
        padding: var(--rnb-spacing-large);
    }

    &.leftImage, &.rightImage {
        grid-template-columns: minmax(auto, auto) minmax(auto, auto);
        .image, .text {
            align-self: flex-start;
        }
    }

    &.topImage, &.bottomImage {
        grid-template-rows: repeat(2, minmax(auto, 1fr));

        .image, .text {
            align-self: normal;
            justify-self: center;
        }
    }

    &.leftImage, &.topImage {
        .image {
            order: 1;
        }
        .text {
            order: 2;
        }
    }

    &.rightImage, &.bottomImage  {
        .image {
            order: 2;
        }
        .text {
            order: 1;
        }
    }
}