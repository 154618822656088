.leadership {
    text-align: center;

    @media (--rnb-mq-medium) {
        text-align: left;
    }
}

.image {
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 128px;
    max-width: 128px;
    max-height: 128px;
    background-color: var(--rnb-colour-midnight-tint-8);

    /* bug fix: get the border-radius to work in safari */
    mask-image: radial-gradient(white, black);

    @media (--rnb-mq-medium) {
        margin: 0;
    }
}
