.component {
    background-color: var(--hello-section);
    --justify-content: center;

    &.isFirstSection {
        --justify-content: center;
    }

    &.stackedMobileView {
        height: auto;
        margin-top: 100px;

        @media (--rnb-mq-large) {
            height: 90vh;
            height: 90dvh;
        }
    }

    min-height: 90vh;
    min-height: 90dvh;
    display: flex;
    flex-direction: column;
    justify-content: var(--justify-content);
    align-items: center;
    text-align: center;
    padding-bottom: var(--rnb-spacing-huge);
    gap: var(--rnb-spacing-large);

    @media (--rnb-mq-phablet) {
        padding-bottom: var(--rnb-spacing-super);
    }

    @media (--rnb-mq-large) {
        display: grid;
        grid-template: none / 1fr 0.8fr;
        align-items: center;
        text-align: left;
        padding-bottom: 0;
    }

    &.mobileLeftAligned {
        text-align: left;
    }

    &.flipHorizontal {
        @media (--rnb-mq-large) {
            grid-template: none / 0.6fr 1fr;
        }

        .contentWrapper {
            order: 1;
        }
    }

    &.noImage {
        display: flex;
    }
}

.heading {
    line-height: var(--rnb-line-height-heading);
    font-size: var(--rnb-font-size-large);

    .isFirstSection & {
        font-size: var(--rnb-font-size-massive);

        @media (--rnb-mq-medium) {
            font-size: calc(var(--rnb-font-size-massive) + var(--rnb-font-size-micro));
        }
    }

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-huge);
    }

    &.veryBold > h1 {
        font-size: var(--rnb-font-size-mas);
        font-size: calc(var(--rnb-font-size-massive) + var(--rnb-font-size-micro));

        @media (--rnb-mq-medium) {
            font-size: var(--rnb-font-size-jumbo);
        }
    }
}

.subHeading {
    line-height: var(--rnb-line-height-text);
    font-size: var(--rnb-font-size-small);

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-medium);
    }

    .noImage & {
        margin-top: -0.25rem;
    }
}

.contentWrapper {
    z-index: 1;

    &.pullContentUp {
        @media (--rnb-mq-large) {
            margin-top: -220px;
        }
    }
}

.lowerIndicatorIcon {
    color: var(--rnb-theme-colour-primary, inherit);
    position: absolute;
    bottom: var(--rnb-spacing-big);
    left: 50%;
    transform: translateX(-50%);

    .noImage & {
        bottom: 12.5%;
    }
}

.imageWrapper {
    --mobile-display: unset;

    display: var(--mobile-display);
    order: -1;
    position: relative;
    width: 100%;
    margin-top: var(--rnb-spacing-big);

    &.isFirstSection {
        --mobile-display: none;
    }

    &.pullContentUp {
        @media (--rnb-mq-large) {
            margin-top: -220px;
        }
    }

    &.mobileImageBelowForm {
        --mobile-display: unset;
        order: 1;
        position: relative;
        margin-top: none;
    }

    @media (--rnb-mq-phablet) {
        top: var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-large) {
        position: unset;
        padding-top: 0;
        order: unset;
        display: flex;
        justify-content: center;
    }
}

.image {
    height: auto;
    width: 100%;

    @media (--rnb-mq-large) {
        transform: scale(1);
        height: 60vh;
        width: 450px;

        .isFirstSection & {
            width: 350px;
        }
    }
}

.logoContainer {
    display: flex;
    color: var(--rnb-colour-white);
    flex-direction: column;
    align-items: center;
    font-size: var(--rnb-font-size-medium);

    @media (--rnb-mq-medium) {
        display: flex;
        margin-bottom: var(--rnb-spacing-big);
        font-size: var(--rnb-font-size-large);
    }
    @media (--rnb-mq-large) {
        align-items: flex-start;
    }
}

.logoContainer {
    &.mobileLeftAligned {
        align-items: flex-start;
    }
}

.logo {
    fill: white;
    width: 212px;
}

.prospectForm {
    @media (--rnb-mq-large) {
        max-width: 32.5rem;
    }

    &.isFirstSection {
        max-width: 32.5rem;
        margin-left: auto;
        margin-right: auto;

        @media (--rnb-mq-large) {
            margin-left: unset;
            margin-right: unset;
        }
    }

    &.stackedMobileView > form {
        --grid-template-columns: 1fr;

        @media (--rnb-mq-medium) {
            --grid-template-columns: 1fr auto;
        }
    }
}

.ctas {
    display: flex;
    flex-wrap: wrap;
    gap: var(--rnb-spacing-tiny);
}

.appLinksContainer {
    &:not(.appLinksContainerPersistent) {
        display: none;
    }

    @media (--rnb-mq-large) {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: var(--rnb-spacing-super);
        right: var(--rnb-spacing-super);
    }
}

.downloadAppCta {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
        margin-top: 3rem;

        .noImage & {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.imageBelowDisclaimer {
    width: 100%;
    height: 35px;
    display: flex;
    margin-top: 15px;
    justify-content: center;

    @media (--rnb-mq-large) {
        justify-content: flex-start;
    }
}

.disclaimerImage {
    height: 35px;
    width: 180px;
}

.flipHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}
