.logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.compact {
        justify-content: center;
    }
}

.logosScrollable {
    flex-wrap: nowrap;
    margin: 0 calc(var(--rnb-spacing-large) * -1);
    padding: 0 var(--rnb-spacing-large);
    width: calc(100% + var(--rnb-spacing-large) * 2);
    overflow-x: auto;
    justify-content: unset;
    gap: var(--rnb-spacing-medium);

    @media (--rnb-mq-phablet) {
        margin: 0 calc(var(--rnb-spacing-big) * -1);
        padding: 0 var(--rnb-spacing-big);
        width: calc(100% + var(--rnb-spacing-big) * 2);
    }

    .logo {
        width: auto;
        height: calc(var(--rnb-spacing-super) * 1.1);
        flex-shrink: 0;
        display: flex;

        @media (--rnb-mq-phablet) {
            flex-grow: 1;
        }

        @media (--rnb-mq-large) {
            height: auto;
        }

        .imageFitCover img {
            object-fit: cover;
        }

        .imageFitContain img {
            object-fit: contain;
        }
    }
}

.logo {
    width: 50%;

    @media (--rnb-mq-phablet) {
        width: 25%;
    }

    @media (--rnb-mq-large) {
        flex: 1;
    }

    &.compact {
        max-height: var(--rnb-spacing-super);
        max-width: 90px;
        display: flex;
        justify-content: center;

        img {
            object-fit: contain !important;
            max-height: var(--rnb-spacing-super);
        }

        @media (--rnb-mq-large) {
            max-width: initial;
            max-height: initial;

            img {
                object-fit: cover;
            }
        }


    }
}

.heading {
    @media (--rnb-mq-medium) {
        text-align: center;
    }

    &.centerAligned {
        text-align: center;
    }
}

.primaryHeadingColor {
    color: black;
}

.secondaryHeadingColor {
    color: var(--rnb-colour-midnight-tint-80);

    .isDarkTheme & {
        color: var(--rnb-colour-white);
    }
}

.compactHeading {
    font-size: var(--rnb-font-size-large);;
    font-weight: var(--rnb-font-weight-medium);;
    line-height: var(--rnb-line-height-heading);
    margin-bottom: 20px;
    color: var(--light-brand-secondary, #515E6D);

    @media (--rnb-mq-large) {
        line-height: var(--rnb-line-height-text);
    }
}
