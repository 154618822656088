.jobListingsContent {
    width: 100%;
}

.jobListingsContentItem {
    display: grid;
    grid-template: 'info arrow';
    justify-content: space-between;
    padding: var(--rnb-spacing-medium) var(--rnb-spacing-tiny);

    &:first-child {
        border-top: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-32);
    }

    &:last-child {
        border-bottom: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-32);
    }

    &:not(:last-child) {
        border-bottom: 0.5px solid var(--rnb-colour-midnight-tint-32);
    }

    @media (--rnb-mq-large) {
        display: flex;
    }
}

.jobListingsContentItemData {
    flex: 1 0;
    display: flex;
    align-items: center;
    grid-area: info;
    grid-row: span 1;

    &:last-child {
        flex: 0 0;
        grid-area: arrow;
        grid-row: -1;
    }
}

.jobListingsContentItemIcon {
    color: var(--rnb-colour-midnight);

    &:visited {
        color: var(--rnb-colour-midnight);
    }
}
