:root {
    --background-color__2: var(--rnb-colour-midnight-tint-4);
    --color__5: white;
    --maxContainerSize__14: 1280px;
    --maxContentSize: 675px;
    --maxContentSizeM: 550px;
    --shieldMobile: 125px;
    --shieldDesktop: 150px;
}

.plus {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.shield {
    display: grid;
    position: relative;
    width: var(--shieldMobile);

    @media (--rnb-mq-medium) {
        width: var(--shieldDesktop);
    }
}

.shieldText {
    position: absolute;
    align-self: center;
    justify-self: center;
}
