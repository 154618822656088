.component {
    position: relative;
}

.inputWrapper {
    font-family: var(--rnb-font-family-cs-clarity);
    font-weight: var(--rnb-font-weight-regular);
    font-size: var(--rnb-font-size-small);
    line-height: var(--rnb-line-height-text);
    margin: var(--rnb-spacing-tiny) var(--rnb-spacing-none);
    border-radius: var(--rnb-border-radius-medium);
    padding: var(--rnb-spacing-small);
    box-sizing: border-box;
    width: 100%;
    border-width: var(--rnb-border-width-thin);
    border-style: solid;

    &.isDockedTop {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: var(--rnb-border-width-thin-offset);
    }

    &.isThemeLight {
        background-color: var(--rnb-colour-midnight-tint-4);
        border-color: var(--rnb-colour-midnight-tint-48);
        color: var(--rnb-colour-midnight);

        &.isLightBg {
            background-color: var(--rnb-colour-white);
        }

        &.isValid {
            border-color: var(--rnb-colour-sea-shade-64);
        }

        &.isInvalid {
            border-color: var(--rnb-colour-sand);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-midnight-tint-16);
            color: var(--rnb-colour-midnight-tint-16);

            &::placeholder {
                color: var(--rnb-colour-midnight-tint-16);
            }
        }

        &::placeholder {
            color: var(--rnb-colour-midnight-tint-48);
        }

        &:not([disabled], .isValid, .isInvalid):focus {
            border-color: var(--rnb-colour-midnight);
        }
    }

    &.isThemeDark {
        background-color: var(--rnb-colour-white-alpha-8);
        border-color: var(--rnb-colour-white-alpha-48);
        color: var(--rnb-colour-white);

        &.isLightBg {
            background-color: var(--rnb-colour-black-alpha-64);
        }

        &.isValid {
            border-color: var(--rnb-colour-sea);
        }

        &.isInvalid {
            border-color: var(--rnb-colour-sand);
        }

        &.isDisabled {
            border-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-white-alpha-16);

            &::placeholder {
                color: var(--rnb-colour-white-alpha-16);
            }
        }

        &::placeholder {
            color: var(--rnb-colour-white-alpha-48);
        }

        &:not([disabled], .isValid, .isInvalid):focus {
            border-color: var(--rnb-colour-white);
        }
    }

    &[type='date'] {
        appearance: textfield;

        &::placeholder {
            visibility: hidden;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            display: none;
        }
    }
}

.wrapper {
    display: flex;
    align-items: center;
}

.shortPrepend {
    padding-left: calc(var(--rnb-spacing-small) + 1ch + var(--rnb-spacing-small));
}

.mediumPrepend {
    padding-left: calc(var(--rnb-spacing-small) + 2ch + var(--rnb-spacing-small));
}

.longPrepend {
    padding-left: calc(var(--rnb-spacing-small) + 3ch + var(--rnb-spacing-small));
}

.prependWrapper {
    pointer-events: none;
    position: absolute;
    padding-left: var(--rnb-spacing-small);

    &.isThemeLight {
        color: var(--rnb-colour-midnight);

        &.isDisabled {
            color: var(--rnb-colour-midnight-tint-16);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);

        &.isDisabled {
            color: var(--rnb-colour-white-alpha-16);
        }
    }
}

.errorMessage {
    position: absolute;
    left: var(--rnb-spacing-small);
}

.suffixWrapper {
    pointer-events: none;
    position: absolute;
    right: var(--rnb-spacing-small);

    &.isThemeLight {
        color: var(--rnb-colour-midnight);

        &.isDisabled {
            color: var(--rnb-colour-midnight-tint-16);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);

        &.isDisabled {
            color: var(--rnb-colour-white-alpha-16);
        }
    }
}

.clearIcon {
    position: absolute;
    right: var(--rnb-spacing-medium);
    color: var(--rnb-colour-midnight-tint-48);
    background: var(--rnb-colour-midnight-tint-4);
    border: none;
}