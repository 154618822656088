:root {
    --zIndexAboveEverything: 20;
    --cookieNoticeWidthHuge: 90vw;
    --cookieNoticeWidthMedium: 60vw;
    --cookieNoticeCentered: 5vw;
    --cookieAlignWithBlurb: 3%;
}

.cookieNotice {
    position: fixed;
    bottom: var(--rnb-spacing-big);
    z-index: var(--zIndexAboveEverything);
    width: 100%;

    @media (--rnb-mq-large) {
        width: auto;
        bottom: var(--rnb-spacing-big);
        max-width: var(--cookieNoticeWidthMedium);
        margin-left: var(--cookieAlignWithBlurb);
    }
}

.notificationContent {
    display: block;
    flex-wrap: wrap;

    > * {
        display: inline;

        @media (--rnb-mq-medium) {
            display: block;
        }
    }
}

.link {
    margin-left: var(--rnb-spacing-micro);

    @media (--rnb-mq-large) {
        margin-left: unset;
        margin-top: var(--rnb-spacing-micro);
    }
}
