:root {
    --hero-content-z-index: 10;
    --color__3: var(--rnb-colour-white);
    --background-color: rgb(247, 247, 248);
    --maxContainerSize__12: 1280px;
}

.section {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    clip-path: polygon(0% 0%, 0% 95%, 100% 100%, 100% 0%);

    @media (--rnb-mq-medium) {
        height: 100vh;
    }
}

.sectionContent {
    flex: 1;
    z-index: var(--rnb-zindex-levitate);
    background-color: var(--background-color);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (--rnb-mq-large) {
        padding: 0 var(--rnb-spacing-large);
    }
}

.title {
    text-align: center;
    margin: var(--rnb-spacing-medium);
}

.textContainer {
    margin: var(--rnb-spacing-huge) var(--rnb-spacing-large);
}

.textHeader {
    margin-bottom: var(--rnb-spacing-tiny);
}

.textBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.carouselTextContainer {
    padding: 0 var(--rnb-spacing-small);
}

.backgroundImage {
    position: absolute;
    width: 200vw;

    @media (--rnb-mq-medium) {
        height: 100%;
        clip-path: polygon(0% 0%, 0% 90%, 100% 100%, 100% 0%);
    }

    @media (--rnb-mq-large) {
        width: 100vw;
        transform: translateX(0);
    }
}

.tint {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--rnb-colour-black-alpha-32);
}

.headerSection {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    @media (--rnb-mq-large) {
        justify-content: flex-start;
        width: 100%;
        max-width: var(--maxContainerSize__12);
        padding: 0 var(--rnb-spacing-large);
    }
}

.header {
    text-align: center;
    padding-top: var(--rnb-spacing-huge);
    z-index: var(--rnb-zindex-levitate);
    color: var(--color__3);
}

.faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.carouselContainer {
    width: 100%;
    padding: var(--rnb-spacing-large);
    overflow: hidden;

    @media (--rnb-mq-large) {
        width: 100%;
        max-width: var(--maxContainerSize__12);
    }
}

.torchContainer {
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: 100vw;

    @media (--rnb-mq-large) {
        width: 100%;
        max-width: var(--maxContainerSize__12);
    }
}

.torch {
    transform: scaleX(-1);
    display: flex;
    justify-content: center;
}
