.card {
    background-color: var(--rnb-colour-white);
    box-shadow: var(--rnb-shadow-earth);
    width: 100%;
    height: 100%;
    min-height: inherit;
}

.isDisabled {
    pointer-events: none;
    cursor: default;
    box-shadow: none;
}

.isFlat {
    box-shadow: none;
}

button.card,
a.card {
    text-align: left;
    cursor: pointer;
    border: 0;
    padding: 0;
    transition: box-shadow var(--rnb-timing-fast) ease-in-out;

    &:hover,
    &:focus {
        box-shadow: var(--rnb-shadow-mountain);
    }

    &:active {
        box-shadow: var(--rnb-shadow-sea);
    }
}

a.card {
    display: block;
    text-decoration: none;
    color: var(--inherit-colour);
}

.cardContent {
    display: flex;
    flex-direction: row;
    min-height: inherit;
    height: 100%;
    color: var(--rnb-colour-midnight);
    border-radius: inherit;
}

.cardContent--vertical {
    flex-direction: column;

    .cardContent__primary {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .cardContent__secondary {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .cardContent__corner-image {
        max-height: 50%;
    }
}

.cardContent--horizontal {
    .cardContent__primary {
        border-top-left-radius: inherit;
        border-top-right-radius: 0;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: 0;
    }

    .cardContent__secondary {
        border-top-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: inherit;
    }
}

.cardContent__primary {
    width: 100%;
    position: relative;
    border-radius: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.cardContent__secondary {
    width: 100%;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: var(--rnb-colour-white);
}

.cardContent__corner-image {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
    max-height: 33%;
}

.cardContent__corner-image--with-offset {
    top: var(--rnb-spacing-medium);
    right: var(--rnb-spacing-medium);
}

.cardContent--dark {
    color: var(--rnb-colour-white);
}

.cardContent--flex-container {
    display: flex;
}

.cardContent--display-state-abandoned {
    background-color: var(--rnb-colour-midnight-tint-80);
}

.cardContent--display-state-positive {
    background-color: var(--rnb-colour-sea-tint-16);
}

.cardContent--display-state-negative {
    background-color: var(--rnb-colour-salvia-tint-16);
}

.cardContent--display-state-default {
    background-color: var(--rnb-colour-chill-tint-8);
}

.cardContent--display-state-image {
    background-color: var(--rnb-colour-dandelion-tint-16);
}

.cardContent--display-state-neutral {
    background-color: var(--rnb-colour-midnight-tint-16);
}

.cardContent--display-state-attention {
    background-color: var(--rnb-colour-sand-tint-16);
}

.cardContent--display-state-exclusive {
    background: var(--rnb-gradient-exclusive);
}

.cardContent--display-state-dpo_applied {
    background: var(--rnb-colour-sea-tint-16);
}

.cardContent--display-state-dpo_viewed {
    background: var(--rnb-colour-moss-tint-16);
}

.cardContent--padding-small {
    padding: var(--rnb-spacing-small);
}

.cardContent--padding-medium {
    padding: var(--rnb-spacing-medium);
}

.cardContent--padding-large {
    padding: var(--rnb-spacing-large);
}

.cardContent--padding-largest {
    padding: var(--rnb-spacing-big);
}

.cardContent--padding-none {
    padding: var(--rnb-spacing-none);
}
