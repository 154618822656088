:root {
    --subheader-max-width: 540px;
}

.header {
    color: var(--rnb-colour-midnight);
    line-height: var(--rnb-line-height-heading);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--rnb-font-size-big);

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-massive);
    }
}

.subheader {
    color: var(--rnb-colour-midnight-tint-64);
    max-width: var(--subheader-max-width);
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.smallScreenOnly {
    display: block;

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.mediumScreenOnly {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}
