:root {
    --appStoreLogoWidth: 125px;
}

.logo {
    margin-bottom: var(--rnb-spacing-micro);
    margin-right: var(--rnb-spacing-tiny);
    width: var(--appStoreLogoWidth);
}

.rating {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: var(--rnb-spacing-small);
}

.inline {
    display: inline-block;
}

.leftMargin {
    margin-left: var(--rnb-spacing-tiny);
}

.tagline {
    color: var(--rnb-theme-colour-secondary);
    margin-top: var(--rnb-spacing-small);
}
