.faqsSection {
    --padding-top: var(--rnb-spacing-large);
    --padding-bottom: var(--rnb-spacing-big);

    padding: var(--padding-top) var(--rnb-spacing-large) var(--padding-bottom);
    color: var(--rnb-colour-midnight);
    width: 100%;
    margin: 0 auto;
}

.darkTheme {
    .faq {
        box-shadow: none;
        background-color: var(--rnb-colour-background-light);
        
        > div {
            background-color: transparent;
        }
    }
}


.faq {
    border-radius: var(--rnb-border-radius-medium);
    box-shadow: 0 0.5px 1.75px rgba(0, 0, 0, 0.039), 0 1.85px 6.25px rgba(0, 0, 0, 0.19);
}

.cta {
    display: flex;
    justify-content: center;
}

.contentfulCta {
    display: flex;
    justify-content: flex-start;

    &.light{
        a > div {
            color: white;
        }
    }
}
