:root .progressBar {
    --bar-height: 2px;
}

.progressBar {
    position: sticky;
    height: var(--bar-height);
    top: 0;
    background-color: var(--rnb-colour-chill);
}
