.header {
    display: flex;
    justify-content: space-between;
}

.partnerIcon {
    width: 80px;
    max-width: 80px;
}

.price {
    display: flex;
    align-items: baseline;
}

.prices {
    display: flex;
    background-color: var(--rnb-colour-midnight-tint-4);
    padding: var(--rnb-spacing-small) var(--rnb-spacing-medium);
    justify-content: space-between;
    border-radius: var(--rnb-border-radius-medium);

    &.default {
        background-color: var(--rnb-colour-chill-tint-16);
    }

    &.neutral {
        background-color: var(--rnb-colour-midnight-tint-48);
    }
}
