.component {
    cursor: pointer;
    text-decoration: underline;

    &.isThemeLight {
        color: var(--rnb-colour-action-light);

        &:hover,
        &:focus {
            color: var(--rnb-colour-action-hover-light);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-action-dark);

        &:hover,
        &:focus {
            color: var(--rnb-colour-action-hover-dark);
        }
    }

    &.isThemeInherit {
        color: var(--rnb-theme-colour-action);

        &:hover,
        &:focus {
            color: var(--rnb-theme-colour-action-hover);
        }
    }

    &.isSimpleLink {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
