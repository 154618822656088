.blockquote {
    padding: var(--rnb-spacing-medium) var(--rnb-spacing-medium) var(--rnb-spacing-medium) var(--rnb-spacing-big);
    border-left: var(--rnb-border-width-default) solid;
    color: var(--rnb-theme-colour-primary, var(--rnb-colour-midnight));
}

.quote {
    font-style: italic;
}

.quote:not(:last-child) {
    margin-bottom: var(--rnb-spacing-tiny);
}
