@media (min-width: 0) {
    :root {
        --height-component: 460px;
    }
}

@media (min-width: 512px) {
    :root {
        --height-component: 500px;
    }
}

.component {
    height: var(--height-component);
    position: relative;
}

.image {
    height: var(--height-component);

    &::after {
        background-color: rgba(var(--rnb-colour-midnight-rgb), 0.64);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.content {
    color: var(--rnb-colour-white);
    padding: var(--rnb-spacing-big) 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    &::after {
        background-color: var(--rnb-colour-white);
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
    }

    @media (--rnb-mq-phablet) {
        padding-bottom: var(--rnb-spacing-super);
    }

    @media (--rnb-mq-huge) {
        padding-right: 50%;
    }
}

.title {
    margin-bottom: var(--rnb-spacing-tiny);
}

.description {
    margin-bottom: var(--rnb-spacing-large);
}

.rightArrow {
    margin-left: var(--rnb-spacing-micro);
    vertical-align: middle;
}

.details {
    display: flex;
}
