:root {
    --image-height-small__3: 340px;
    --image-height-small-tall: 575px;
}

.fullWidthImageWrapper {
    width: 100%;
    height: var(--image-height-small__3);
    position: relative;

    &.isTallMobile {
        height: var(--image-height-small-tall);

        @media (--rnb-mq-large) {
            height: unset;
        }
    }

    @media (--rnb-mq-large) {
        height: unset;
    }

    & :global(.gatsby-image-wrapper) {
        height: 100%;
    }
}
