.component {
    position: fixed;
    z-index: var(--rnb-zindex-top);
    transition: visibility var(--rnb-timing-fast) ease-in-out, transform var(--rnb-timing-fast) ease-in-out;
    box-shadow: var(--rnb-shadow-mountain);
    visibility: hidden;
    overflow-y: auto;

    &.isOpen {
        visibility: visible;
    }

    &.isRight {
        right: 0;
        top: 0;
        width: 100vw;
        transform: translateX(100%);

        &.isOpen {
            transform: translateX(0%);
        }

        @media (--rnb-mq-medium) {
            width: auto;
        }
    }

    &.isLeft {
        top: 0;
        left: 0;
        width: 100vw;
        transform: translateX(-100%);
        box-shadow: var(--rnb-shadow-hill-top);

        &.isOpen {
            transform: translateY(0%);
        }

        @media (--rnb-mq-medium) {
            width: auto;
        }
    }

    &.isBottom {
        bottom: 0;
        width: 100vw;
        transform: translateY(100%);
        box-shadow: var(--rnb-shadow-hill-top);

        &.isOpen {
            transform: translateY(0%);
        }

        @media (--rnb-mq-medium) {
            height: auto !important; /* forced to overwrite updated height with JS when on this device size */
            max-height: 100vh; /* fixes when the content is larger than the height of the screen */
        }
    }
}

.close {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: var(--rnb-spacing-medium);
    top: var(--rnb-spacing-medium);
    z-index: 1;

    &.isThemeLight {
        color: var(--rnb-colour-midnight);
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);
    }
}

.backdrop {
    z-index: var(--rnb-zindex-rise);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--rnb-colour-black-alpha-64);
    visibility: hidden;
    transform: translateX(100%);
    transition: visibility var(--rnb-timing-fast) ease-in-out, transform 0s;

    &.isShown {
        visibility: visible;
        transform: translateX(0%);
    }
}

.drawerContent {
    height: 100%;
    position: relative;
    z-index: 0;
}
