:root {
    --header-main-font-size-small: var(--rnb-font-size-big);
    --header-main-font-size-medium: 3rem;
    --header-text-width-medium: 658px;
    --header-text-margin-small: 0 var(--rnb-spacing-big);
    --header-text-margin-medium: 0;
}

.headerWrapper {
    color: var(--rnb-colour-midnight);
    display: flex;
    justify-content: center;
}

.headerText {
    text-align: center;
    margin: var(--header-text-margin-small);

    @media (--rnb-mq-medium) {
        margin: var(--header-text-margin-medium);
        width: var(--header-text-width-medium);
    }
}

.headerMain {
    line-height: var(--rnb-line-height-heading);
    font-size: var(--header-main-font-size-small);
    font-weight: var(--rnb-font-weight-bold);

    @media (--rnb-mq-medium) {
        font-size: var(--header-main-font-size-medium);
    }
}

.headerSubtext {
    color: var(--rnb-colour-midnight-tint-64);
    margin-top: var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-large);
    font-size: var(--rnb-font-size-medium);
    line-height: var(--rnb-line-height-text);

    &.leftAlign {
        text-align: left;

        @media (--rnb-mq-phablet) {
            text-align: unset;
        }
    }
}

.leftAlignChildren {
    text-align: left;

    @media (--rnb-mq-phablet) {
        text-align: unset;
    }
}
