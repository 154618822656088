.circle {
    transform-origin: center;
    transition: stroke-dashoffset ease-in-out;

    &.isThemeAlpha,
    &.isThemeTheta,
    &.isThemeShortTermDebt {
        stroke: var(--rnb-colour-sand-tint-64);
    }

    &.isThemeBeta {
        stroke: var(--rnb-colour-dandelion-tint-64);
    }

    &.isThemeGamma {
        stroke: var(--rnb-colour-moss-tint-64);
    }

    &.isThemeDelta {
        stroke: var(--rnb-colour-sea-tint-64);
    }

    &.isThemeEpsilon {
        stroke: var(--rnb-colour-chill-tint-32);
    }

    &.isThemeLongTermDebt {
        stroke: var(--rnb-colour-lavender-tint-64);
    }

    &.isThemePositive {
        stroke: var(--rnb-colour-sea-tint-64);
    }

    &.isThemeNegative {
        stroke: var(--rnb-colour-rose-tint-64);
    }

    &.isThemeWhite {
        stroke: var(--rnb-colour-white);
    }

    &.isThemeCustom {
        stroke: currentColor;
    }

    &.isThemeInactive {
        stroke: var(--rnb-colour-midnight-tint-8);
    }
}
