:root .faqSection {
    --tintedBackgroundColor: rgb(247, 247, 248);
}

.faqSection {
    display: flex;
    background-color: var(--tintedBackgroundColor);
    width: 100%;
    flex: 1;
}

.faqLists {
    padding: var(--rnb-spacing-large) var(--rnb-spacing-large) 0;
    width: 100%;
}

.faq {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08), 0 0 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    max-width: 630px;
    margin: 0 auto var(--rnb-spacing-medium);

    button {
        background: transparent;
        border-radius: 8px;
        border: none;
        padding: 20px var(--rnb-spacing-small);
        font-weight: var(--rnb-font-weight-bold);

        &[aria-expanded='true'] {
            border-radius: 8px 8px 0 0;
            background-color: var(--tintedBackgroundColor);
        }
    }
}

.accordionContent {
    padding: var(--rnb-spacing-small);

    div:first-child {
        border-top: none;
    }
}

.title {
    margin-top: var(--rnb-spacing-big);
    margin-bottom: var(--rnb-spacing-huge);

    @media (--rnb-mq-medium) {
        text-align: center;
    }
}

.ctaContainer {
    margin: var(--rnb-spacing-huge) 0 var(--rnb-spacing-super);

    @media (--rnb-mq-medium) {
        text-align: center;
    }
}
