.header {
    text-align: center;
    color: var(--rnb-colour-midnight);
}

.heroImage {
    margin: 0;

    @media (--rnb-mq-large) {
        margin: 0 var(--rnb-spacing-super);
    }
}

.content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    @media (--rnb-mq-large) {
        flex-direction: row;
    }
}
