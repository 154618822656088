.component {
    --accordion-bg-chill-base: var(--rnb-colour-chill);
    --accordion-bg-chill: var(--rnb-colour-chill-tint-4);
    --accordion-bg-midnight: var(--rnb-colour-midnight-shade-48);
    --accordion-bg-midnight-tint-4: var(--rnb-colour-midnight-tint-4);
    --accordion-bg-light: var(--rnb-colour-background-light);
    --accordion-bg-transparent: var(--rnb-colour-transparent);
    --accordion-bg-white: var(--rnb-colour-white);
    --accordion-bg-sand: var(--rnb-colour-sand-tint-8);
    --accordion-bg-rose: var(--rnb-colour-rose-tint-8);
    --accordion-bg-sea: var(--rnb-colour-sea-tint-8);
    --accordion-bg-reef: var(--rnb-colour-reef-tint-8);
    --accordion-bg-reef-tint-32: var(--rnb-colour-reef-tint-32);
    --accordion-bg-salvia-base: var(--rnb-colour-salvia);
    --accordion-bg-salvia: var(--rnb-colour-salvia-tint-8);
    --accordion-bg-salvia-tint-32: var(--rnb-colour-salvia-tint-32);
    --accordion-bg-sky-base: var(--rnb-colour-sky);
    --accordion-bg-sky: var(--rnb-colour-sky-tint-8);
    --accordion-bg-sky-tint-32: var(--rnb-colour-sky-tint-32);
    --accordion-bg-slate-tint-10: var(--slate-colour-slate-tint-10);

    border-radius: var(--rnb-border-radius-medium);

    &.isTypePrimary {
        background-color: var(--rnb-colour-white);
    }

    &.isTypeSecondary {
        background-color: var(--rnb-colour-midnight-tint-4);
    }
}

.accordionToggle {
    text-align: left;
    width: 100%;
    padding: var(--rnb-spacing-small) 0;
    outline: none;

    @media (--rnb-mq-small) {
        padding: var(--rnb-spacing-small) 0;
    }

    @media (--rnb-mq-phablet) {
        padding: var(--rnb-spacing-small) 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.isDisabled:hover {
    cursor: default;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--rnb-spacing-tiny);

    @media (--rnb-mq-small) {
        padding: 0 var(--rnb-spacing-small);
    }

    @media (--rnb-mq-phablet) {
        padding: 0 var(--rnb-spacing-medium);
    }
}

.leftSection {
    display: flex;
    justify-content: flex-start;
}

.details {
    display: flex;
    flex-direction: column;
}

.icon,
.largeIcon,
.title,
.description {
    margin-right: var(--rnb-spacing-tiny);
}

.title {
    color: var(--rnb-colour-midnight);
    &.bold {
        font-weight: var(--rnb-font-weight-bold)
    }

}

.description {
    color: var(--rnb-colour-midnight-tint-64);

    & a {
        font-weight: var(--rnb-font-weight-bold);
    }
}

.chevron,
.largeIcon,
.icon {
    display: flex;
    align-items: center;
}

.icon {
    width: 24px;

    & > svg {
        width: 24px;
        height: 24px;
    }
}

.largeIcon {
    width: 32px;

    & > svg {
        width: 32px;
        height: 32px;
    }
}

.chevron svg {
    transition: transform var(--rnb-timing-fast) ease-in-out;
}

.isExpanded svg {
    transform: rotate(-180deg);
}

.statusArrow {
    display: flex;
    align-items: center;
}

.tag {
    margin-right: var(--rnb-spacing-small);
    width: auto;
}
