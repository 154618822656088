/* stylelint-disable */

@custom-media --ds-mq-small (min-width: 375px);
@custom-media --ds-mq-phablet (min-width: 512px);
@custom-media --ds-mq-medium (min-width: 768px);
@custom-media --ds-mq-large (min-width: 1024px);
@custom-media --ds-mq-huge (min-width: 1408px);

:root {
    --ds-border-radius-circle: 50%;
    --ds-border-radius-small: 0.25rem;
    --ds-border-radius-medium: 0.5rem;
    --ds-border-radius-large: 1rem;
    --ds-border-radius-big: 1.25rem;
    --ds-border-width-thin: 1px;
    --ds-border-width-default: 2px;
    --ds-colour-caution: #ffb7b5;
    --ds-colour-caution-rgb: 255, 183, 181;
    --ds-colour-highlight-green: #baffee;
    --ds-colour-highlight-green-rgb: 186, 255, 238;
    --ds-colour-highlight-green-alpha-33: rgba(186, 255, 238, 0.33);
    --ds-colour-highlight-pink: #ffb5ef;
    --ds-colour-highlight-pink-rgb: 255, 181, 239;
    --ds-colour-neon-pink: #ffeeff;
    --ds-colour-neon-pink-rgb: 255, 238, 255;
    --ds-colour-neon-green: #cdfff3;
    --ds-colour-neon-green-rgb: 205, 255, 243;
    --ds-colour-neon-blue: #5df0fa;
    --ds-colour-neon-blue-rgb: 93, 240, 250;
    --ds-colour-panel-dark: #364665;
    --ds-colour-panel-dark-rgb: 54, 70, 101;
    --ds-colour-panel: #435880;
    --ds-colour-panel-rgb: 67, 88, 128;
    --ds-colour-on-panel: #596d92;
    --ds-colour-on-panel-rgb: rgb(89, 109, 146);
    --ds-colour-panel-elevated: #0b7190;
    --ds-colour-panel-elevated-rgb: 11, 113, 144;
    --ds-colour-primary: #006ed8;
    --ds-colour-primary-rgb: 0, 110, 216;
    --ds-colour-secondary: #cbddf2;
    --ds-colour-secondary-rgb: 203, 221, 242;
    --ds-colour-secondary-alpha-33: rgba(203, 221, 242, 0.33);
    --ds-colour-secondary-alpha-8: rgba(203, 221, 242, 0.08);
    --ds-colour-secondary-shade-34: #7184a6;
    --ds-colour-secondary-shade-34-rgb: 113, 132, 166;
    --ds-colour-white: #fff;
    --ds-colour-white-rgb: 255, 255, 255;
    --ds-colour-white-alpha-12: rgba(255, 255, 255, 0.12);
    --ds-colour-black: #000;
    --ds-colour-black-rgb: 0, 0, 0;
    --ds-colour-black-alpha-32: rgba(0, 0, 0, 0.32);
    --ds-colour-transparent: transparent;
    --ds-colour-button-primary: #006ed8;
    --ds-colour-button-primary-disabled: rgba(203, 221, 242, 0.33);
    --ds-colour-button-primary-pressed: #0986ff;
    --ds-colour-button-secondary-pressed: rgba(9, 134, 255, 0.33);
    --ds-colour-button-tertiary-pressed: rgba(9, 134, 255, 0.33);
    --ds-font-family-ds-averta: DSAverta, Helvetica Neue, Helvetica, Arial, sans-serif;
    --ds-font-size-micro: 0.625rem;
    --ds-font-size-tiny: 0.75rem;
    --ds-font-size-mini: 0.875rem;
    --ds-font-size-small: 1rem;
    --ds-font-size-adequate: 1.125rem;
    --ds-font-size-medium: 1.25rem;
    --ds-font-size-large: 1.5rem;
    --ds-font-size-big: 2.25rem;
    --ds-font-size-huge: 2.5rem;
    --ds-font-size-massive: 2.75rem;
    --ds-font-size-jumbo: 3.5rem;
    --ds-font-weight-extra-thin: 100;
    --ds-font-weight-thin: 200;
    --ds-font-weight-light: 300;
    --ds-font-weight-regular: 400;
    --ds-font-weight-medium: 500;
    --ds-font-weight-semi-bold: 600;
    --ds-font-weight-bold: 700;
    --ds-font-weight-extra-bold: 800;
    --ds-font-weight-black: 900;
    --ds-gradient-high-performance: linear-gradient(270.02deg, #76ffce 4.12%, #ffa6eb 100%);
    --ds-gradient-low-performance: linear-gradient(270.02deg, #e6aa6c 5.66%, #ff8b8b 100%);
    --ds-gradient-background-bottom: linear-gradient(161.6deg, #23358c 17.72%, #553e59 104.69%);
    --ds-gradient-background-top: radial-gradient(
        rgba(67, 213, 239, 0.5) 0%,
        rgba(47, 199, 208, 0) 60%,
        transparent 40%
    );
    --ds-gradient-background: linear-gradient(161.6deg, #20588b 17.72%, #463e76 104.69%);
    --ds-gradient-tooltip: linear-gradient(140deg, rgb(54, 138, 170) 10%, rgb(57, 80, 170) 100%);
    --ds-line-height-small: 1rem;
    --ds-line-height-adequate: 1.125rem;
    --ds-line-height-medium: 1.25rem;
    --ds-line-height-generous: 1.375rem;
    --ds-line-height-large: 1.5rem;
    --ds-line-height-great: 1.75rem;
    --ds-line-height-big: 2rem;
    --ds-line-height-huge: 2.5rem;
    --ds-line-height-massive: 2.75rem;
    --ds-line-height-jumbo: 3.25rem;
    --ds-line-height-gigantic: 4rem;
    --ds-line-height-reset: 1;
    --ds-line-height-text: 1.5;
    --ds-line-height-heading: 1.2;
    --ds-shadow-neon-green: 0px 0px 50px rgba(40, 255, 202, 0.2), 0px 0px 10px rgba(84, 255, 213, 0.5),
        inset 0px 0px 2px #00a980;
    --ds-shadow-neon-pink: 0px 0px 4px rgba(255, 111, 223, 0.5), 0px 0px 4px rgba(255, 103, 222, 0.04),
        0px 0px 20px rgba(255, 103, 222, 0.5), inset 0px 0px 1px #ee3edf;
    --ds-shadow-dark: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
    --ds-spacing-none: 0rem;
    --ds-spacing-nano: 0.125rem;
    --ds-spacing-micro: 0.25rem;
    --ds-spacing-tiny: 0.5rem;
    --ds-spacing-small: 0.75rem;
    --ds-spacing-medium: 1rem;
    --ds-spacing-generous: 1.25rem;
    --ds-spacing-large: 1.5rem;
    --ds-spacing-big: 2rem;
    --ds-spacing-huge: 3rem;
    --ds-spacing-super: 4rem;
    --ds-spacing-jumbo: 8rem;
    --ds-timing-fast: 250ms;
    --ds-timing-normal: 500ms;
    --ds-timing-slow: 1000ms;
    --ds-timing-easing-in: cubic-bezier(0.4, 0, 1, 1);
    --ds-zindex-dive: -200;
    --ds-zindex-default: 1;
    --ds-zindex-levitate: 2;
    --ds-zindex-rise: 200;
    --ds-zindex-sky: 400;
    --ds-zindex-top: 600;
}
