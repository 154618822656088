.image {
    display: block;
}

.contained {
    max-width: 100%;
}

.stretched {
    width: 100%;
}

.rounded {
    object-fit: cover;
    border-radius: 50%;
}
