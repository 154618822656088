.createAccountProspectFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.securityMessage {
    font-size: var(--rnb-font-size-tiny);
    font-weight: var(--rnb-font-weight-regular);
    line-height: var(--rnb-line-height-text);
    color: var(--rnb-colour-midnight-tint-80);
}

.securityMessageWithIcon {
    display: flex;
    margin-top: var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-huge);
}

.padlockIcon {
    height: var(--rnb-spacing-medium);
    margin-right: var(--rnb-spacing-small);
}

.formHeading {
    color: var(--rnb-colour-midnight);
    margin-bottom: var(--rnb-spacing-tiny);
}

.emailAddressFormLabel {
    color: var(--rnb-colour-midnight);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--rnb-font-size-small);
    line-height: var(--rnb-line-height-heading);
}

.tocMessage {
    margin-top: -90px;
    font-size: var(--rnb-font-size-tiny);
    font-weight: var(--rnb-font-weight-regular);
    line-height: var(--rnb-line-height-text);
    color: var(--rnb-colour-midnight-tint-80);
}
.createAccountProspectForm {
    button {
        width: 115px;
        margin-top: var(--rnb-spacing-super);
    }
}
