.heroContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    width: 100%;

    @media (--rnb-mq-large) {
        flex-direction: row;
        align-items: center;
    }
}

.heroImage {
    display: block;
    margin: 0 auto;
    height: auto;
    padding-top: var(--rnb-spacing-medium);
    width: 100%;
    max-width: 500px;

    @media (--rnb-mq-large) {
        margin: 0 var(--rnb-spacing-large) 0 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        padding-top: var(--rnb-spacing-big);
    }
}

.heroText {
    padding-top: var(--rnb-spacing-large);
    padding-bottom: 0;

    @media (--rnb-mq-large) {
        padding-top: var(--rnb-spacing-big);
        padding-bottom: var(--rnb-spacing-big);
    }
}
