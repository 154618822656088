.infoSection {
    display: flex;
    background: linear-gradient(
        -266.435deg,
        rgb(183, 207, 204) 0%,
        rgb(202, 235, 230) 41%,
        rgb(232, 255, 252) 53%,
        rgb(202, 236, 231) 61%,
        rgb(140, 179, 173) 100%
    );
}
