.container {
    position: relative;
    z-index: 1;
    grid-column-start: 1;
    grid-column-end: -1;
    color: var(--rnb-colour-midnight);
    background-color: var(--rnb-colour-white);
}

.preWrap {
    white-space: pre-wrap;
}

.viewportM {
    display: none;
}

@media (--rnb-mq-medium) {
    .viewportXS {
        display: none;
    }

    .viewportM {
        display: block;
    }
}
