:root {
    --nav-height__2: 90px;
    --nav-offset: 94px;
    --nav-offset-m: 68px;
}

.pageTitle,
.content h2 {
    color: var(--rnb-colour-midnight);
}

.pageDescription {
    color: var(--rnb-colour-black-alpha-64);
    font-size: var(--rnb-font-size-small);
}

.article {
    margin-top: var(--rnb-spacing-super);
    color: var(--rnb-colour-black-alpha-64);

    [data-layout|='article'] {
        grid-column-start: 2;
        grid-column-end: span 4;

        @media (--rnb-mq-medium) {
            grid-column-start: 3;
            grid-column-end: span 6;
        }
    }

    [data-layout='article-content'] {
        @media (--rnb-mq-large) {
            grid-column-start: 5;
            grid-column-end: span 6;
        }
    }

    [data-layout='article-bleed'] {
        @media (--rnb-mq-medium) {
            grid-column-start: 2;
            grid-column-end: span 8;
        }

        @media (--rnb-mq-large) {
            grid-column-start: 4;
            grid-column-end: span 8;
        }
    }

    [data-layout='article-left'] {
        @media (--rnb-mq-large) {
            grid-column-start: 2;
            grid-column-end: span 3;
        }
    }

    [data-layout='article-right'] {
        @media (--rnb-mq-large) {
            grid-column-start: 12;
            grid-column-end: span 2;
        }
    }
}

.readingProgressBar {
    position: fixed;
    width: 100%;
    z-index: 2;
}

.content {
    grid-row: 1;
}

.contentPadding {
    padding-left: var(--rnb-spacing-medium);
    padding-right: var(--rnb-spacing-medium);
}

.primaryCta {
    display: none;

    @media (--rnb-mq-large) {
        display: block;

        > * {
            position: sticky;
            top: calc(var(--nav-height__2) + var(--rnb-spacing-super));
        }
    }
}

.mobilePrimaryCta {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.heroImage {
    img {
        border-radius: var(--rnb-border-radius-medium);
        overflow: hidden;
    }
}

.categoryHeader {
    color: var(--rnb-colour-chill);
    margin-bottom: var(--rnb-spacing-medium);
    display: block;

    a {
        color: var(--rnb-colour-chill);
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }
}

.navOffset > * > * {
    padding-top: var(--nav-offset-m);
    margin-top: calc(-1 * var(--nav-offset-m));

    @media (--rnb-mq-large) {
        padding-top: var(--nav-offset);
        margin-top: calc(-1 * var(--nav-offset));
    }
}
