.wrapper {
    display: flex;
    justify-content: center;
    color: var(--rnb-colour-midnight);
}

.timesLogo {
    margin-right: var(--rnb-spacing-medium);
}

.contentRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.stars {
    display: flex;
    position: relative;

    & > svg:not(:last-of-type) {
        margin-right: var(--rnb-spacing-micro);
    }
}

.starMask {
    position: absolute;
    right: 0;
    background: var(--rnb-colour-white);
    height: 100%;
}

.text {
    font-size: var(--rnb-font-size-micro);
}
