.rating {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.stars {
    margin-bottom: var(--rnb-spacing-micro);
}

.userRating {
    font-size: var(--rnb-font-size-medium);
    font-weight: var(--rnb-font-weight-bold);
    margin-left: var(--rnb-spacing-micro);
    color: var(--rnb-theme-colour-primary);
}

.userRatingCount {
    font-size: var(--rnb-font-size-medium);
    margin-left: var(--rnb-spacing-micro);
    color: var(--rnb-theme-colour-secondary);
}

.visuallyHidden {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}
