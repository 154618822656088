.component {
    position: relative;
    width: 100%;
}

.cardContent {
    padding: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-huge);
    }
}

.darkTheme {
    color: var(--rnb-colour-white);
    background-color: var(--rnb-colour-midnight-shade-32);
}

.imageOverlay {
    background-color: rgba(var(--rnb-colour-black-rgb), var(--image-darkness-percentage));
    position: relative;
}

.alignCenter {
    text-align: center;
}

.backgroundWrapper {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: var(--rnb-zindex-default);
}

.backgroundImage {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute !important;
    object-fit: contain;
    object-position: center;
    z-index: var(--rnb-zindex-dive);
}

.background-chill-tint-16 {
    background-color: var(--rnb-colour-chill-tint-16);
}

.background-midnight-tint-16 {
    background-color: var(--rnb-colour-midnight-tint-16);
}

.background-midnight-shade-32 {
    background-color: var(--rnb-colour-midnight-shade-32);
}

.background-dandelion-tint-16 {
    background-color: var(--rnb-colour-dandelion-tint-16);
}

.background-rose-tint-16 {
    background-color: var(--rnb-colour-rose-tint-16);
}

.background-moss-tint-16 {
    background-color: var(--rnb-colour-moss-tint-16);
}

.background-white {
    background-color: var(--rnb-colour-white);
}
