:root .containerWrapper {
    --headerTitleAndAHalfMobile: 80px;
    --headerTitleAndAHalfDestop: 104px;
}

.containerWrapper {
    top: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
    pointer-events: none;
    padding-top: var(--headerTitleAndAHalfMobile);
    z-index: var(--rnb-zindex-sky);
    width: 100%;

    @media (--rnb-mq-medium) {
        padding-top: var(--headerTitleAndAHalfDestop);
    }

    & > * {
        position: sticky;
        top: var(--rnb-spacing-large);
        z-index: var(--rnb-zindex-sky);
    }
}

:global body {
    position: relative;
}

.enter {
    transition: opacity 100ms ease-out, transform 100ms ease-in;
    opacity: 1;
    transform: translateY(0) scale(1);
}

.exit {
    transition: opacity 100ms ease-out, transform 100ms ease-in;
    opacity: 0;
    transform: translateY(-15%) scale(0.95);
}
