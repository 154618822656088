:root {
    --header-font-size-small: var(--rnb-font-size-big);
    --header-font-size-large: 3rem;
}

.header {
    color: var(--rnb-colour-midnight);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--header-font-size-small);

    @media (--rnb-mq-large) {
        font-size: var(--header-font-size-large);
    }
}

.items {
    color: var(--rnb-colour-midnight-tint-64);
    display: grid;

    @media (--rnb-mq-phablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (--rnb-mq-medium) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.item {
    display: flex;
    align-items: center;
    margin-top: var(--rnb-spacing-big);
    margin-right: var(--rnb-spacing-medium);
}
