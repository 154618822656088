.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

.trustPilotContainer {
    margin-left: -1rem; /* we don't have control over the trust pilot content */
}

.appLinksContainer {
    display: flex;
    align-items: center;
}

@media (--rnb-mq-medium) {
    .createProspectForm {
        max-width: 32.5rem;
    }
}
