.container {
    text-align: left;
    font-weight: bold;
    font-size: 90%;
    line-height: calc(var(--rnb-spacing-medium) + var(--rnb-spacing-tiny));

    @media (--rnb-mq-large) {
        max-width: 90%;
    }
}

.title {
    color: var(--rnb-colour-midnight);
}

.menuItem {
    margin: var(--rnb-spacing-medium) auto 0;
}
