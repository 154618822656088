:root .section {
    --color: var(--rnb-colour-white);
    --maxContainerSize__2: 1380px;
    --maxContentWidth: 515px;
    --phoneShapeWidth: 225px;
    --phoneShapeHeight: 470px;
    --phoneShapeHeightLarger: 525px;
    --phoneShapeWidthLarger: 260px;
    --phoneShapeBorder: 25px;
}

.section {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.phone {
    position: absolute;
    top: 50vh;
    left: 50%;
    margin: auto;
    border-width: 1px;
    border-radius: 0;
    transform: translate(-50%, -50%);

    @media (--rnb-mq-large) {
        background-color: var(--rnb-colour-white-alpha-32);
        border-radius: var(--phoneShapeBorder);
        left: 75%;
        width: var(--phoneShapeWidthLarger);
        height: var(--maxContentWidth);
        flex-direction: column;
    }

    @media (min-width: 1440px) {
        border-radius: var(--phoneShapeBorder);
        left: 50%;
        width: var(--phoneShapeWidthLarger);
    }
}

.phoneWithBackground {
    position: absolute;
    width: var(--phoneShapeWidth);
    height: var(--phoneShapeHeight);
    margin: auto;
    border-radius: var(--phoneShapeBorder);
    transform: translate(-50%, -50%);
    top: 50vh;
    display: none;
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.4);

    @media (--rnb-mq-large) {
        align-items: center;
        justify-content: center;
        left: 75%;
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 1440px) {
        left: 50%;
        width: var(--phoneShapeWidthLarger);
        height: var(--phoneShapeHeightLarger);
        display: flex;
        flex-direction: column;
    }
}

.scoreBackground {
    background-image: url('./assets/score_panel_image_l.jpg');
    background-size: cover;
    width: var(--phoneShapeWidth);
    height: var(--phoneShapeHeight);
    border-radius: var(--phoneShapeBorder);

    @media (min-width: 1440px) {
        width: var(--phoneShapeWidthLarger);
        height: var(--phoneShapeHeightLarger);
    }
}

.sectionContent {
    max-width: var(--maxContainerSize__2);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--rnb-mq-large) {
        align-items: flex-start;
        padding: 0 var(--rnb-spacing-large);
    }
}

.scoreRing {
    --bottom: 0;
    --width: 200px;
    --height: 200px;
    --display: none;
    --left: auto;

    @media (--rnb-mq-large) {
        --display: flex;
        --bottom: 30%;

        &.scoreRingWithBackground {
            --left: 6%;
        }

        &.scoreRingWithNoBackground {
            --left: 12%;
        }
    }

    @media (min-width: 1440px) {
        &.scoreRingWithBackground {
            --left: 12%;
        }
    }

    width: var(--width);
    height: var(--height);
    bottom: var(--bottom);
    position: absolute;
    display: var(--display);
    left: var(--left);
}

.hero {
    flex-direction: column-reverse;
    min-height: 120vh;
    padding: var(--nav-height) 0;

    @media (--rnb-mq-medium) {
        min-height: 100vh;
    }
}

.heroContent {
    max-width: var(--maxContentWidth);
    color: var(--color);
    z-index: var(--rnb-zindex-levitate);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        align-items: flex-start;
    }
}

.title {
    text-align: center;
    margin: var(--rnb-spacing-medium) 0;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.signUp {
    margin-top: var(--rnb-spacing-big);

    .signUpForm {
        display: none;
    }

    .signUpButton {
        display: block;
    }

    @media (--rnb-mq-large) {
        .signUpForm {
            display: block;
        }

        .signUpButton {
            display: none;
        }
    }
}

.textContainer {
    margin: var(--rnb-spacing-huge) var(--rnb-spacing-large);
}

.heroFooter {
    max-width: var(--maxContainerSize__2);
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: var(--rnb-zindex-levitate);
    display: flex;
    justify-content: center;
    color: var(--color);

    @media (--rnb-mq-large) {
        justify-content: flex-start;
        width: 95%;
    }
}

.copyright {
    opacity: 0.8;
    display: none;
    margin-bottom: calc(var(--rnb-spacing-super) + var(--rnb-spacing-small));

    @media (--rnb-mq-large) {
        display: block;
    }
}

.logoContainer {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        text-align: left;
        justify-content: flex-start;
    }

    .logoText {
        font-size: var(--rnb-font-size-medium);

        @media (--rnb-mq-large) {
            font-size: var(--rnb-font-size-large);
        }
    }

    .subHeading {
        font-weight: var(--rnb-font-weight-medium);
        line-height: 1.2;
    }
}

.blurb {
    text-align: center;
    margin-top: var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.appInfo {
    display: block;
    text-align: center;
    z-index: var(--rnb-zindex-default);
    color: var(--color);
    max-width: var(--maxContentWidth);
    padding: 0 var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        text-align: left;
        display: none;
    }

    @media (min-width: 1440px) {
        position: absolute;
        display: block;
        left: 62%;
    }
}

.voucher {
    width: 100%;
    height: auto;
    max-width: 180px;

    @media (--rnb-mq-medium) {
        max-width: 220px;
    }
}

.indicatorWrapper {
    @media (--rnb-mq-large) {
        margin-left: 50%;
    }

    @media (min-width: 1440px) {
        margin-left: 23%;
    }
}

.tertiaryText {
    margin-top: 30px;
}

.bullets {
    display: flex;
    justify-content: center;

    ul li::before {
        display: none;
    }

    ul li {
        list-style-type: circle;
    }

    @media (--rnb-mq-large) {
        display: block;
    }
}
