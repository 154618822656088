:root .container {
    --maxContainerSize: 1088px;
}

.sectionWrapper {
    background-color: var(--rnb-colour-background-light);
}

.container {
    max-width: var(--maxContainerSize);
    line-height: var(--rnb-line-height-text);
    padding: var(--rnb-spacing-huge) var(--rnb-spacing-large);
    margin: 0 auto;

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-huge) var(--rnb-spacing-big);
    }
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;

    @media (--rnb-mq-medium) {
        flex-wrap: nowrap;
    }
}

.card {
    margin-top: var(--rnb-spacing-big);
    flex-grow: 1;
}

.marginRight {
    @media (--rnb-mq-medium) {
        margin-right: var(--rnb-spacing-big);
    }
}
