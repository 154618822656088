.thumbnail {
    position: relative;
}

.thumbnailText {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: var(--rnb-zindex-levitate);
    color: var(--rnb-colour-white);
    padding: 0 20%;
}

.thumbnailHeader {
    font-size: var(--rnb-font-size-big);
    font-weight: var(--rnb-font-weight-bold);
    line-height: var(--rnb-line-height-heading);
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

.video {
    width: 100%;
    cursor: pointer;
}
