.linkIcon {
    display: inline-block;

    svg {
        display: none;
    }

    a {
        display: flex;
    }

    a[target='_blank'] svg {
        display: inline-block;
    }
}
