.header {
    text-align: center;
    color: var(--rnb-colour-midnight);
}

.heroImage {
    margin: 0;

    @media (--rnb-mq-large) {
        margin: 0 var(--rnb-spacing-super);
    }
}

.verticalCentered {
    display: flex;
    align-items: center;
}

.horizontallyCentered {
    display: flex;
    justify-content: center;
}
