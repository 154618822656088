:root {
    --ringSize: 141px;
}

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
    grid-column-start: 1;
    grid-column-end: -1;
    text-align: center;
    align-items: center;
    background: var(--rnb-colour-midnight);
    overflow: hidden;
}

.wrapper {
    margin: 0 auto;
    z-index: 2;
}

.donut {
    color: var(--rnb-colour-white);

    .donutText {
        display: inline-block;
    }

    .first {
        transform: rotate(17deg) translate(-13px, -5px);
    }

    .second {
        transform: rotate(-9deg) translate(0, 0);

        .ringSeven {
            position: fixed;
            width: 120px;
            left: 19%;
            top: -23%;
        }
    }

    .zero {
        width: var(--ringSize);
        height: var(--ringSize);
        line-height: var(--ringSize);
        border-radius: var(--rnb-border-radius-circle);
        text-align: center;
        margin: 0 auto;
        color: var(--rnb-colour-white);
        border: var(--rnb-border-width-thin) solid var(--rnb-colour-white);
    }

    .third {
        transform: rotate(-6deg) translate(9px, -37px);
    }
}

.allRings {
    position: absolute;
}

.ringsWrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;
    margin-bottom: 94px;
    z-index: 1;

    .layerContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .ringOne {
            width: 100%;
            top: -22%;
            left: -45%;
        }

        .ringTwo {
            width: 30%;
            bottom: 4%;
            right: -14%;
        }

        .ringThree {
            width: 15%;
            left: 20%;
            bottom: -7%;
        }

        .ringFour {
            width: 5%;
            right: 27%;
            top: 46%;
        }

        .ringFive {
            width: 20%;
            left: -7%;
            top: 51%;
        }

        .ringSix {
            width: 10%;
            right: 10%;
            top: 25%;
        }
    }
}

.copy {
    color: var(--rnb-colour-white);
    padding-top: var(--rnb-spacing-big);
}

.button {
    padding-top: var(--rnb-spacing-large);
}
