/* stylelint-disable color-no-hex */
.tag {
    --background: #1a2430;
    --background2: #3e3837;
    --background3: #c46466;

    border-radius: var(--rnb-border-radius-small);
    white-space: nowrap;
    letter-spacing: 0.04em;
    overflow: hidden;
    display: inline-flex; /* needed to ensure tag takes minimum width */
    align-items: center;

    /* set some defaults for unknown displayState/theme combo */
    background-color: var(--rnb-colour-white);
    color: var(--rnb-colour-midnight);

    & .icon {
        display: inline-block;
        width: 1.35em;
        height: 1.35em;

        /* since there is an icon, reduce left padding */
        margin-left: -4px;
        margin-right: 4px;
    }

    & * + .icon {
        margin-left: 4px;
        margin-right: 0;
    }

    & .tagContent {
        padding: 0.125rem var(--rnb-spacing-tiny);
        display: flex;
        align-items: center;
    }

    &.regular {
        font-size: var(--rnb-font-size-tiny);
        font-weight: var(--rnb-font-weight-regular);
        line-height: 18px;
    }

    &.bold {
        font-size: var(--rnb-font-size-micro);
        font-weight: var(--rnb-font-weight-bold);
        line-height: 15px;
    }

    &.displayState_EXCLUSIVE,
    &.displayState_NYE,
    &.displayState_PRIMARY {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-midnight);
            color: var(--rnb-colour-white);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white);
            color: var(--rnb-colour-midnight);
        }
    }

    &.displayState_NYE {
        background: linear-gradient(
            90.81deg,
            var(--background) 24.82%,
            var(--background2) 58.27%,
            var(--background3) 106.11%
        );
    }

    &.displayState_NYE_AU {
        background: linear-gradient(90deg, var(--rnb-colour-sand-tint-64) 0%, var(--rnb-colour-dandelion-tint-32) 100%);
        color: var(--rnb-colour-midnight);
    }

    &.displayState_SECONDARY {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-midnight-tint-16);
            color: var(--rnb-colour-midnight);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-white);
        }
    }

    &.displayState_ATTENTION {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-sand-tint-16);
            color: var(--rnb-colour-sand-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-sand);
        }
    }

    &.displayState_POSITIVE {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-sea-tint-16);
            color: var(--rnb-colour-sea-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-sea);
        }
    }

    &.displayState_NEGATIVE {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-rose-tint-16);
            color: var(--rnb-colour-rose-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-rose);
        }
    }

    &.displayState_ACTION {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-chill-tint-16);
            color: var(--rnb-colour-chill-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-chill);
        }
    }

    &.displayState_INFORMATION {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-lavender-tint-16);
            color: var(--rnb-colour-lavender-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-lavender);
        }
    }

    &.displayState_OPEN_BANKING {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-sky-tint-32);
            color: var(--rnb-colour-sky-shade-48);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-white-alpha-16);
            color: var(--rnb-colour-sky);
        }
    }

    &.displayState_NEW {
        &.theme_LIGHT {
            background-color: var(--rnb-colour-midnight-tint-4);
            color: var(--rnb-colour-lavender-shade-32);
        }

        &.theme_DARK {
            background-color: var(--rnb-colour-lavender);
            color: var(--rnb-colour-lavender-shade-32);
        }
    }

    &.displayState_DARKEN {
        &.theme_DARK,
        &.theme_LIGHT {
            background-color: var(--rnb-colour-black-alpha-8);
        }
    }

    &.displayState_LIGHTEN {
        &.theme_DARK,
        &.theme_LIGHT {
            background-color: var(--rnb-colour-white-alpha-16);
        }
    }

    &.displayState_TRANSPARENT,
    &.displayState_OUTLINE {
        border: 1px currentColor solid;
    }

    &.displayState_TRANSPARENT {
        background-color: var(--rnb-colour-transparent);

        &.theme_LIGHT {
            color: var(--rnb-colour-midnight);
        }

        &.theme_DARK {
            color: var(--rnb-colour-white);
        }
    }

    &.displayState_TREND_UP {
        &.theme_DARK,
        &.theme_LIGHT {
            background-color: var(--rnb-colour-black-alpha-8);
        }
    }

    &.displayState_LIGHT_TRANSLUCENT {
        &.theme_DARK,
        &.theme_LIGHT {
            color: var(--rnb-colour-white);
            background-color: #ffffff29;
        }
    }
}
