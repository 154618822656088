.featureSection {
    display: flex;
    background-color: var(--rnb-colour-white);
    padding-top: 70px;
    padding-bottom: 70px;
    padding-right: var(--rnb-spacing-large);
    flex-direction: column;

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-jumbo) var(--rnb-spacing-large) var(--rnb-spacing-huge);
    }
}

.featureLists {
    @media (--rnb-mq-large) {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1330px;
        margin: 0 auto;
    }
}

.featureItem {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--rnb-spacing-super);
    padding-bottom: var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        flex: 50%;
        padding: 0 var(--rnb-spacing-large) var(--rnb-spacing-small) 0;

        &:nth-child(even) {
            padding: 0 0 var(--rnb-spacing-small) var(--rnb-spacing-large);
        }
    }
}

.featureImageHolder {
    width: 78px;

    @media (--rnb-mq-medium) {
        width: inherit;
        overflow: inherit;
    }
}

.featureItemLast {
    padding-bottom: 0;
    margin-bottom: var(--rnb-spacing-huge);
}

.featureImage {
    width: 92px;
    height: 92px;
    left: -18px;
    overflow: hidden;
    position: relative;

    @media (--rnb-mq-large) {
        overflow: inherit;
        left: auto;
    }

    img {
        width: 92px;
        height: 92px;
        position: absolute;

        @media (--rnb-mq-large) {
            left: 0;
            top: 0;
        }
    }
}

.featureContent {
    padding-left: 8px;

    @media (--rnb-mq-medium) {
        padding-left: 32px;
    }
}

.featureTitle {
    margin-bottom: 16px;
}

.featureParagraph {
    p {
        margin-bottom: var(--rnb-spacing-medium);
    }
}

.ctaContainer {
    margin: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        text-align: center;
    }
}
