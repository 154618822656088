:root {
    --logos-height: 40px;
}

.wrapper {
    padding: var(--rnb-spacing-super) var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        padding: var(--rnb-spacing-jumbo) var(--rnb-spacing-super) var(--rnb-spacing-super) var(--rnb-spacing-super);
    }

    &.isThemeSand {
        background-color: var(--rnb-colour-sand-tint-16);
    }

    &.isThemeChill {
        background-color: var(--rnb-colour-chill-tint-8);
    }
}

.headerText {
    color: var(--rnb-colour-midnight-shade-64);
    line-height: var(--rnb-line-height-heading);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--rnb-font-size-big);

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-massive);
    }
}

.contentText {
    color: var(--rnb-colour-midnight-tint-64);
}

.waysOfWorking {
    display: flex;
    flex-direction: column;

    @media (--rnb-mq-medium) {
        flex-direction: row;
    }
}

.logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: scroll hidden;
    height: var(--logos-height);
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    /* FireFox scrollbar hide */
    scrollbar-width: none;

    /* IE scrollbar hide */
    -ms-overflow-style: none;

    /* Chrome/safari */
    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        margin-right: var(--rnb-spacing-huge);
        height: fit-content;
    }
}
