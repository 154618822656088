:root .infoSection {
    --maxContentWidth__2: 524px;
    --maxContainerSize__4: 1380px;
}

.infoSection {
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;
}

.center {
    width: 100%;
    max-width: var(--maxContainerSize__4);
    display: flex;
    height: auto;
    flex-flow: wrap;
    justify-content: center;
    text-align: center;
    margin-bottom: var(--rnb-spacing-super);
    margin-top: var(--rnb-spacing-super);

    @media (--rnb-mq-medium) {
        margin: 16rem;
        height: auto;
        flex-flow: unset;
        justify-content: unset;
        text-align: left;
    }
}

.sectionContent {
    max-width: var(--maxContainerSize__4);
    z-index: var(--rnb-zindex-levitate);

    @media (--rnb-mq-medium) {
        align-items: flex-start;
        padding: 0 var(--rnb-spacing-large);
    }
}

.mainContent {
    max-width: var(--maxContentWidth__2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--rnb-spacing-large);

    @media (--rnb-mq-medium) {
        align-items: flex-start;
        max-width: 424px;
    }

    @media (--rnb-mq-large) {
        max-width: var(--maxContentWidth__2);
    }
}

.centerImage {
    width: 100%;
    height: auto;
    max-width: 280px;

    @media (--rnb-mq-medium) {
        position: relative;
    }

    @media (--rnb-mq-large) {
        max-width: 340px;
    }
}

.rhsInfo {
    max-width: var(--maxContentWidth__2);
    padding: 0 var(--rnb-spacing-small);

    @media (--rnb-mq-medium) {
        margin: 0;
        display: none;
    }

    @media (min-width: 1440px) {
        display: block;
    }
}

.mobileWrapper {
    display: flex;
    align-items: center;
}

.rhsTitle {
    margin-bottom: var(--rnb-spacing-small);
}

.rhsButton {
    margin-top: var(--rnb-spacing-large);
}
