.linkWrapper {
    display: block;
    text-decoration: none;
}

.card {
    backdrop-filter: blur(10px);
    background-color: var(--rnb-colour-black-alpha-32);
    box-shadow: var(--rnb-shadow-earth);
    width: 100%;
    height: 100%;
    min-height: inherit;
    border: var(--rnb-border-width-thin) solid var(--rnb-colour-white);
    border-radius: var(--rnb-border-radius-large);
    color: var(--rnb-colour-white);

    @media (--rnb-mq-medium) {
        border-radius: var(--rnb-border-radius-big);
    }

    &:hover {
        background-color: var(--rnb-colour-black-alpha-16);
        color: var(--rnb-colour-white-alpha-64);
        border-color: var(--rnb-colour-white-alpha-64);
        box-shadow: none;
    }
}

.contentContainer {
    display: flex;
    gap: var(--rnb-spacing-tiny);
    flex-direction: row;
    min-height: inherit;
    height: 100%;
    padding: var(--rnb-spacing-medium) var(--rnb-spacing-large);
    border-radius: inherit;

    @media (--rnb-mq-medium) {
        flex-direction: column;
    }
}

.icon {
    height: 40px;
    width: 40px;
    fill: var(--rnb-colour-white);
}

.text {
    display: flex;
    align-items: center;
}
