.termsChangeBanner {
    color: var(--rnb-colour-midnight);
    line-height: 150%;
    font-size: var(--rnb-font-size-tiny);

    background-color: var(--rnb-colour-white);
    padding: var(--rnb-spacing-medium) var(--rnb-spacing-large);
    display: flex;
    flex-direction: row;

    a {
        color: #0F81A3;
    }

}

.termsChangeBannerIcon {
    margin-right: var(--rnb-spacing-small);
    display: flex;
    align-items: center;
}

.termsChangeBannerMessage {
    align-items: center;
    display: flex;
    margin-right: auto;
}
