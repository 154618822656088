/* styles to hide the button or anchor and only show the icon */
.component {
    background-color: transparent; /* stylelint-disable-line */
    border: 0;
    margin: 0; /* fix for safari */
    padding: 0;
    display: flex;
    color: inherit; /* stylelint-disable-line */

    & svg {
        fill: currentColor;

        :global(.icon-state-hover),
        :global(.icon-state-normal) {
            transition: opacity 350ms ease-in-out;
        }

        :global(.icon-state-hover) {
            opacity: 0;
        }

        :global(.icon-state-normal) {
            opacity: 1;
        }
    }

    &:not([disabled]) {
        cursor: pointer;

        &.isActive,
        &:focus,
        &:hover {
            & svg {
                :global(.icon-state-normal) {
                    opacity: 0;
                }

                :global(.icon-state-hover) {
                    opacity: 1;
                }
            }
        }
    }
}
