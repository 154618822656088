.section {
    --website-section-chill-base: var(--rnb-colour-chill);
    --website-section-chill: var(--rnb-colour-chill-tint-4);
    --website-section-midnight: var(--rnb-colour-midnight-shade-48);
    --website-section-midnight-tint-4: var(--rnb-colour-midnight-tint-4);
    --website-section-light: var(--rnb-colour-background-light);
    --website-section-transparent: var(--rnb-colour-transparent);
    --website-section-white: var(--rnb-colour-white);
    --website-section-sand: var(--rnb-colour-sand-tint-8);
    --website-section-rose: var(--rnb-colour-rose-tint-8);
    --website-section-sea: var(--rnb-colour-sea-tint-8);
    --website-section-reef: var(--rnb-colour-reef-tint-8);
    --website-section-reef-tint-32: var(--rnb-colour-reef-tint-32);
    --website-section-salvia-base: var(--rnb-colour-salvia);
    --website-section-salvia: var(--rnb-colour-salvia-tint-8);
    --website-section-salvia-tint-32: var(--rnb-colour-salvia-tint-32);
    --website-section-sky-base: var(--rnb-colour-sky);
    --website-section-sky: var(--rnb-colour-sky-tint-8);
    --website-section-sky-tint-32: var(--rnb-colour-sky-tint-32);
    --website-section-slate-tint-5: var(--slate-colour-slate-tint-5);
    --website-section-metal-tint-20: var(--slate-colour-metal-tint-20);

    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    &.isImage {
        --color: rgba(var(--rnb-colour-black-rgb), calc(var(--background-darkness-percentage, 60) / 100));

        /* using gradient because inline style has a higher specificity than css */
        background-image: linear-gradient(0deg, var(--color), var(--color));
    }
}

.background {
    inset: 0;
    position: absolute !important;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
    /* 
        Due to the Gatsby image optimisations sometimes these which are mean't to be full size end up having a pixel or two
        "visual glitch" where a white/semi-transparent line appears at the bottom of the image. This just makes sure they always wrao
        the full height of the container.
    */
    bottom: -5px;
}

.backgroundMobile {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }
}

.backgroundDesktop {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
