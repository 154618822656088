:root {
    --color__4: var(--rnb-colour-white);
    --maxContainerSize__13: 1280px;
    --maxContentWidth__6: 580px;
}

.section {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.sectionContent {
    max-width: var(--maxContainerSize__13);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--rnb-mq-large) {
        align-items: flex-start;
        padding: 0 var(--rnb-spacing-large);
    }
}

.hero {
    flex-direction: column;
    height: 100vh;
    background-color: var(--rnb-colour-background-dark);
}

.heroContent {
    max-width: var(--maxContentWidth__6);
    color: var(--color__4);
    z-index: var(--rnb-zindex-levitate);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 var(--rnb-spacing-small);

    @media (--rnb-mq-large) {
        align-items: flex-start;
    }
}

.title {
    text-align: center;
    margin: var(--rnb-spacing-medium) 0;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.getProtect {
    margin-top: var(--rnb-spacing-big);

    .getProtectForm {
        display: none;
    }

    .getProtectButton {
        display: block;
    }

    @media (--rnb-mq-large) {
        .getProtectForm {
            display: block;
        }

        .getProtectButton {
            display: none;
        }
    }
}

.textContainer {
    margin: var(--rnb-spacing-huge) var(--rnb-spacing-large);
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: -300px;

    @media (--rnb-mq-medium) {
        object-position: 0;
    }
}

.heroFooter {
    max-width: var(--maxContainerSize__13);
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: var(--rnb-zindex-levitate);
    display: flex;
    justify-content: center;
    color: var(--color__4);

    @media (--rnb-mq-large) {
        justify-content: space-between;
        width: 95%;
    }
}

.shed {
    display: flex;
    align-items: center;
}

.copyright {
    opacity: 0.64;
    display: none;

    @media (--rnb-mq-large) {
        display: block;
        margin-top: var(--rnb-spacing-medium);
    }
}

.torchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;

    @media (--rnb-mq-large) {
        width: 100%;
    }
}

.torch {
    margin-left: var(--rnb-spacing-jumbo);
    overflow: visible;
}

.tagline {
    position: absolute;
    top: var(--rnb-spacing-medium);
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .logoText {
        font-size: var(--rnb-font-size-large);
        margin-left: var(--rnb-spacing-tiny);
    }

    .clearScore {
        font-weight: var(--rnb-font-weight-bold);
    }
}

.blurb {
    text-align: center;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}
