.twoPanelSection {
    color: var(--rnb-colour-white);
    position: relative;
    overflow: hidden;
}

.backgroundDarken {
    /* contentful input is a percentage */
    background-color: rgba(var(--rnb-colour-black-rgb), calc(var(--background-darkness-percentage, 50) / 100));
}

.darkGradient {
    background-image: linear-gradient(
        0deg,
        rgba(var(--rnb-colour-black-rgb), 0.32),
        rgba(var(--rnb-colour-black-rgb), 0.64)
    );
}

.topPanel {
    --padding-bottom: var(--rnb-spacing-large);

    @media (--rnb-mq-large) {
        --padding-bottom: calc(var(--rnb-spacing-huge) * 2);
    }

    padding: var(--padding-top) 0 var(--padding-bottom);
    grid-column: 1 / -1;
}

.headerSpacing {
    --padding-top: var(--rnb-spacing-jumbo);

    @media (--rnb-mq-large) {
        --padding-top: calc(var(--rnb-spacing-big) * 5);
    }
}

.noHeaderSpacing {
    --header-overlay-height: 60px;
    --padding-top: calc(var(--rnb-spacing-jumbo) - var(--header-overlay-height));

    @media (--rnb-mq-large) {
        --padding-top: calc((var(--rnb-spacing-big) * 5) - var(--header-overlay-height));
    }
}

.bottomPanel {
    grid-column: 1 / -1;
    position: relative;
}

.backgroundBlur {
    --backdrop-blur: 1.5rem;

    background-color: var(--rnb-colour-black);

    @supports (backdrop-filter: blur(var(--backdrop-blur))) {
        background-color: var(--rnb-colour-black-alpha-16);
        backdrop-filter: blur(var(--backdrop-blur));
    }
}

.background {
    background-color: var(--rnb-colour-chill);
    inset: 0;
    position: absolute !important;
    overflow: hidden;
    z-index: var(--rnb-zindex-dive);
}

.backgroundMobile {
    display: block;

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.backgroundTablet {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }

    @media (--rnb-mq-large) {
        display: none;
    }
}

.backgroundDesktop {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
