/* stylint-disable */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html,
body {
    height: 100%;
}

body {
    line-height: 1;
    font-family: CSClarity; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: CSClarity; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
}

ol,
ul {
    list-style: none;
}

blockquote {
    quotes: none;
}

blockquote::before,
blockquote::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

i,
em {
    font-style: italic;
}

* {
    box-sizing: border-box;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin.ttf') format('ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-thin-italic.ttf') format('ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light.ttf') format('ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-light-italic.ttf') format('ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-book-italic.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-regular-italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-medium-italic.ttf') format('ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-bold-italic.ttf') format('ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: fallback;
    font-family: CSClarity;
    src: url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black-italic.woff2') format('woff2'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black-italic.woff') format('woff'),
        url('https://cdn2-fs.clearscore.com/global-website/fonts/cs-clarity-black-italic.ttf') format('ttf');
    font-weight: 900;
    font-style: italic;
}

/* 
    Waiting on a response from the type foundry to get a woff2 - will switch out when we have
    Ticket -> https://clearscore.atlassian.net/browse/CS-145147
*/

@font-face {
    font-family: 'CS Clarity Display';
    src: url('https://media.clearscore.com/global/fonts/cs-clarity-display/cs-clarity-display-heavy.woff')
        format('woff');
    font-weight: 950;
    font-style: normal;
}

/*
    Averta - DriveScore Font START
*/

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Extrathin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-ExtrathinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Thin.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Thintalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: DSAverta;
    src: url('https://media.clearscore.com/global/fonts/ds-averta/Averta-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

/*
    Averta - DriveScore Font END
*/

svg {
    fill: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--rnb-font-family-cs-clarity-display) !important;
}
