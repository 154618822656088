:root {
    --inputWidth__2: 240px;
}

.emailForm {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input {
    margin-right: var(--rnb-spacing-tiny);
}

@media (--rnb-mq-large) {
    .input {
        width: var(--inputWidth__2);
    }
}
