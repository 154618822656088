:root {
    --size-author-image: 32px;
}

.wrapper {
    align-items: center;
    display: flex;
}

.spacer {
    margin: 0 var(--rnb-spacing-micro);
}

.icon {
    vertical-align: top;
}

.authorImage {
    border-radius: var(--rnb-border-radius-circle);
    height: var(--size-author-image);
    margin-right: var(--rnb-spacing-tiny);
    overflow: hidden;
    width: var(--size-author-image);
}
