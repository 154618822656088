:root {
    --divider-height: 1px;
}

.cardArticle {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &.hasFullArticleInfo {
        flex-direction: column;
    }

    &.isHorizontal {
        flex-direction: row;
    }
}

.url,
.imageContainer {
    color: var(--rnb-colour-midnight);
    text-decoration: none;

    &:hover {
        + .infoWrapper .title,
        .title,
        .category {
            text-decoration: underline;
        }

        + .infoWrapper .rightArrow,
        .rightArrow {
            transform: translate(5px, 0);
        }
    }

    + .infoWrapper .rightArrow,
    .rightArrow {
        transition: all 0.2s ease-in-out;
    }
}

.divider {
    display: none;
    border: none;
    background: var(--rnb-colour-black-alpha-16);
    height: var(--divider-height);
    width: 45%;
    margin: var(--rnb-spacing-medium) 0 var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        width: 100%;
        margin: var(--rnb-spacing-big) 0 var(--rnb-spacing-big);
    }

    &.hasDividerMobile {
        display: block;

        @media (--rnb-mq-phablet) {
            display: none;
        }
    }

    /* must be last */
    &.hasDivider {
        display: block;
    }
}

.imageContainer {
    margin-right: var(--rnb-spacing-large);
    flex-shrink: 0;
    width: 45%;

    .hasFullArticleInfo & {
        width: 100%;
        margin-top: 0;
    }

    @media (--rnb-mq-medium) {
        margin-top: 0;
    }
}

.image {
    border-radius: var(--rnb-border-radius-small);
    margin-bottom: var(--rnb-spacing-medium);
    width: 100%;

    @media (--rnb-mq-medium) {
        margin-bottom: var(--rnb-spacing-big);
    }
}

.category {
    color: var(--rnb-colour-chill);
    text-decoration: none;
    margin: 0 0 var(--rnb-spacing-tiny);
}

.title {
    color: var(--rnb-colour-midnight);
    margin-bottom: var(--rnb-spacing-tiny);

    &.titleHero:nth-child(-n + 1) {
        margin-bottom: var(--rnb-spacing-tiny);
    }
}

.description {
    color: var(--rnb-colour-midnight);
    word-break: break-word;

    &.descriptionMobile {
        display: none;

        @media (--rnb-mq-medium) {
            display: block;
        }
    }
}

.lastWordWithArrow {
    display: inline-block;
}

.rightArrow {
    color: var(--rnb-colour-chill);
    vertical-align: middle;

    &.rightArrowDescription {
        display: inline-block;

        @media (--rnb-mq-medium) {
            display: none;
        }
    }

    &.rightArrowHideMobile {
        display: none;
    }
}

.caption {
    color: var(--rnb-colour-midnight);
    margin: var(--rnb-spacing-tiny) 0 0;

    &.captionHero:nth-child(-n + 1) {
        margin: var(--rnb-spacing-medium) 0 0;
    }
}
