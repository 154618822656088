.container {
    text-align: center;

    @media (--rnb-mq-large) {
        text-align: left;
    }
}

.spacingMedium {
    margin-bottom: var(--rnb-spacing-medium);
}

.title {
    color: var(--rnb-colour-midnight);
}
