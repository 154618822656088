.emailForm {
    --grid-template-columns: auto;

    display: grid;
    align-items: center;
    column-gap: var(--rnb-spacing-tiny);
    row-gap: var(--rnb-spacing-large);
    grid-template-columns: var(--grid-template-columns);

    &.isVertical {
        --grid-template-columns: auto;
    }

    &.isHorizontal {
        --grid-template-columns: 1fr auto;
    }

    @media (--rnb-mq-medium) {
        --grid-template-columns: 1fr auto;
        row-gap: var(--rnb-spacing-large);
    }
}
