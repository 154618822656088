.show-medium {
    display: none;

    @media (--rnb-mq-medium) {
        display: block;
    }
}

.show-large {
    display: none;

    @media (--rnb-mq-large) {
        display: block;
    }
}
