:root {
    --nav-height: 60px;
    --nav-height-m: 76px;
    --notification-terms-height: 40px;
    --notification-terms-height-m: 40px;
}

.nav {
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    z-index: 25;
    height: var(--nav-height);

    @media (--rnb-mq-phablet) {
        height: var(--nav-height-m);
    }

    &.pullUpContent {
        margin-bottom: calc(-1 * var(--nav-height));

        @media (--rnb-mq-phablet) {
            margin-bottom: calc(-1 * var(--nav-height-m));
        }
    }

    &.termsNotification {
        height: calc(var(--notification-terms-height) + var(--nav-height));

        @media (--rnb-mq-phablet) {
            height: calc(var(--notification-terms-m-height) + var(--nav-height-m))
        }
    }
}

.navScrollRef {
    position: absolute;
    left: 0;
    top: 0;
    height: 90vh;
    pointer-events: none;
    visibility: hidden;
}
