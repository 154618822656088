:root .button {
    --min-sticky-height: 3rem;
    --loader-min-width-tiny: 20px;
    --loader-min-width-small: 25px;
    --loader-min-width-large: 30px;
}

.button {
    background-color: var(--rnb-colour-transparent);
    border: none;
    border-radius: var(--rnb-border-radius-medium);
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    margin: 0; /* fix safari bug */

    &.isTypePrimary {
        &.isThemeLight {
            background-color: var(--rnb-colour-chill);
            color: var(--rnb-colour-white);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                background-color: var(--rnb-colour-chill-tint-64);
            }
        }

        &.isThemeDark {
            background-color: var(--rnb-colour-white);
            color: var(--rnb-colour-midnight);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                color: var(--rnb-colour-chill);
            }
        }

        &.isThemeCs24 {
            border-radius: var(--rnb-border-radius-large);
            background: var(--rnb-colour-cs24-button-gradient-primary);
            --rnb-theme-colour-primary: var(--rnb-theme-colour-primary-inverted);

            > div {
                color: var(--rnb-colour-white);
            }

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }
        }

        &.isThemeInherit {
            /* Primary colours are used for text, but to put emphasis on
             * the button it it has dark background, we need to invert the
             * colour of the text.
             */
            --rnb-theme-colour-primary: var(--rnb-theme-colour-primary-inverted);

            background-color: var(--rnb-theme-colour-action);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-text-hover);

                background-color: var(--rnb-theme-colour-action-hover);
            }

            &.isDisabled {
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-text-disabled);

                background-color: var(--rnb-theme-colour-disabled);
            }
        }

        &.isDisabled {
            background-color: var(--rnb-colour-midnight-tint-16);
            color: var(--rnb-colour-midnight-tint-32);
        }
    }

    &.isTypeSecondary {
        &.isThemeLight {
            border: var(--rnb-border-width-thin) solid var(--rnb-colour-chill);
            background-color: var(--rnb-colour-white);
            color: var(--rnb-colour-chill);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                border-color: var(--rnb-colour-chill-tint-64);
                color: var(--rnb-colour-chill-tint-64);
            }

            &.isDisabled {
                border-color: var(--rnb-colour-midnight-tint-16);
                color: var(--rnb-colour-midnight-tint-32);
            }
        }

        &.isThemeDark {
            border: var(--rnb-border-width-thin) solid var(--rnb-colour-white);
            background-color: var(--rnb-colour-black-alpha-16);
            color: var(--rnb-colour-white);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                background-color: var(--rnb-colour-black-alpha-32);
            }

            &.isDisabled {
                border-color: var(--rnb-colour-midnight-tint-64);
                color: var(--rnb-colour-midnight-tint-64);
            }
        }

        &.isThemeCs24 {
            border-radius: var(--rnb-border-radius-large);
            background: var(--rnb-colour-cs24-button-gradient-secondary);

            > div {
                color: var(--rnb-color-cs24-button-primary-text);
            }

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }
        }

        &.isThemeInherit {
            /* Primary colours are used for text, but to put emphasis on
             * the button it it has dark background, we need to invert the
             * colour of the text.
             */
            --rnb-theme-colour-primary: var(--rnb-theme-colour-action);

            border: var(--rnb-border-width-thin) solid var(--rnb-theme-colour-action);
            background-color: var(--rnb-theme-colour-button-secondary-background);

            &:not(.isFlat, .isDisabled) {
                box-shadow: var(--rnb-shadow-earth);
            }

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                /* dark and light text hover colours are different, this is a workaround */
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-secondary-text-hover);

                background-color: var(--rnb-theme-colour-button-secondary-backgrd-hover);
                border-color: var(--rnb-theme-colour-button-secondary-text-hover);
            }

            &.isDisabled {
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-secondary-text-disabled);

                border-color: var(--rnb-theme-colour-disabled);
                background-color: var(--rnb-colour-transparent);
            }
        }
    }

    &.isTypeTertiary {
        &.isThemeLight {
            color: var(--rnb-colour-chill);

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                background-color: var(--rnb-colour-midnight-tint-8);
            }

            &.isDisabled {
                color: var(--rnb-colour-midnight-tint-32);
            }
        }

        &.isThemeDark {
            color: var(--rnb-colour-chill-tint-64);

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                background-color: var(--rnb-colour-black-alpha-8);
            }

            &.isDisabled {
                color: var(--rnb-colour-midnight-tint-64);
            }
        }

        &.isThemeInherit {
            /* Primary colours are used for text, but to put emphasis on
             * the button it it has dark background, we need to invert the
             * colour of the text.
             */
            --rnb-theme-colour-primary: var(--rnb-theme-colour-action);

            background-color: var(var(--rnb-theme-colour-primary-inverted));

            &:not([disabled]):hover,
            &:not([disabled]):focus {
                /* dark and light text hover colours are different, this is a workaround */
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-tertiary-text-hover);

                background-color: var(--rnb-theme-colour-button-tertiary-background-hover);
            }

            &.isDisabled {
                --rnb-theme-colour-primary: var(--rnb-theme-colour-button-tertiary-text-disabled);

                /* TODO: disable the border or just set color to transparent? */
                border: none;

                /* TODO: check if this works or would it be better to use some special variables */
                background-color: var(--rnb-theme-colour-button-tertiary-background-disabled);
            }
        }
    }

    &.isTypeSticky {
        position: fixed;
        width: 100vw;
        min-height: var(--min-sticky-height);
        min-width: 100vw;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        box-shadow: var(--rnb-shadow-earth-top);
        background-color: var(--rnb-colour-white);
        padding: var(--rnb-spacing-inset-squash-small);
        color: var(--rnb-colour-chill);
        border-radius: 0;

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            color: var(--rnb-colour-chill-shade-64);
        }

        &.isDisabled {
            color: var(--rnb-colour-midnight-tint-32);
        }
    }

    &.isSizeTiny {
        padding: var(--rnb-spacing-inset-squash-micro);
    }

    &.isSizeSmall {
        padding: var(--rnb-spacing-inset-squash-tiny);
    }

    &.isSizeLarge {
        padding: var(--rnb-spacing-inset-squash-small);
    }

    &.isWide {
        width: 100%;
    }

    &:not(.isNarrow) {
        min-width: 170px;
    }

    &:not(.isWide):not(.isTypeSticky).isResponsive {
        width: 100%;

        @media (--rnb-mq-medium) {
            min-width: 170px;
            width: auto;

            &.isNarrow {
                min-width: 0;
            }
        }
    }

    &:not([disabled]) {
        cursor: pointer;
    }
}

.loaderWrapper {
    &.isSizeTiny {
        min-width: var(--loader-min-width-tiny);
    }

    &.isSizeSmall {
        min-width: var(--loader-min-width-small);
    }

    &.isSizeLarge {
        min-width: var(--loader-min-width-large);
    }

    & > .loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        white-space: nowrap;
    }
}
