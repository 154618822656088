:root {
    --box-padding-right: var(--rnb-spacing-medium);
    --card-wrapper-height: 550px;
    --image-min-height-phablet: 475px;
    --card-content-height: 80%;
    --card-content-height-withquote: 55%;
}

.wrapper {
    position: relative;
    height: var(--card-wrapper-height);
    width: 100%;
    padding-right: var(--box-padding-right);
    overflow: hidden;

    @media (--rnb-mq-phablet) {
        margin-right: 0;
        height: 100%;
    }
}

.image {
    height: 100%;

    @media (--rnb-mq-phablet) {
        min-height: var(--image-min-height-phablet);
    }

    /* Add gradient to ensure white text is readable */
    &::after {
        display: block;
        position: absolute;
        background-image: linear-gradient(-180deg, transparent 0%, rgba(var(--rnb-colour-black-rgb), 0.7) 100%);
        top: 0;
        height: 100%;
        width: 100%;
        content: '';
    }
}

.contentClosed {
    position: absolute;
    bottom: 0;
    color: var(--rnb-colour-white);
    padding: 0 var(--rnb-spacing-big) var(--rnb-spacing-super) var(--rnb-spacing-large);
}

.contentOpen {
    position: absolute;
    top: 0;
    z-index: var(--rnb-zindex-levitate);
    width: calc(100% - var(--box-padding-right));
    height: 100%;
    padding: 0 var(--rnb-spacing-large);
    background-color: var(--rnb-colour-chill-tint-8);
    color: var(--rnb-colour-midnight);
}

/* Content drawer animations - START */
.contentOpenEnter {
    transform: translateY(100%);
}

.contentOpenEnterActive {
    transform: translateY(0%);
    transition: transform var(--rnb-timing-fast) ease-in-out, opacity var(--rnb-timing-fast);
}

.contentOpenExit {
    transform: translateY(0%);
}

.contentOpenExitActive {
    transform: translateY(100%);
    transition: transform var(--rnb-timing-fast) ease-in-out, opacity var(--rnb-timing-fast);
}

/* Content drawer animations - END */
.contentBody {
    color: var(--rnb-colour-midnight-tint-64);
    height: var(--card-content-height);
    display: flex;
    align-items: center;

    @media (--rnb-mq-huge) {
        padding: 0 var(--rnb-spacing-super);
    }

    &.hasQuote {
        height: var(--card-content-height-withquote);
    }
}
