:root {
    --nav-width-large: 22vw;
}

.wrapper {
    display: flex;
    overflow: scroll hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    border-bottom: var(--rnb-border-width-default) solid var(--rnb-colour-black-alpha-16);

    /* FireFox scrollbar hide */
    scrollbar-width: none;

    /* IE scrollbar hide */
    -ms-overflow-style: none;

    /* Chrome/safari */
    &::-webkit-scrollbar {
        display: none;
    }

    @media (--rnb-mq-large) {
        flex-direction: column;
        width: var(--nav-width-large);
        border-bottom: none;
    }
}

.link {
    cursor: pointer;
    color: var(--rnb-colour-midnight);
    font-weight: var(--rnb-font-weight-bold);
    opacity: 0.5;

    &.isActiveLink {
        opacity: 1;
    }

    &:hover {
        opacity: 0.7;
    }
}
