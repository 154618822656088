.wrapper {
    flex-grow: 1;
    color: var(--rnb-colour-midnight);
}

.header {
    font-size: var(--rnb-font-size-huge);
    font-weight: var(--rnb-font-weight-bold);
    line-height: var(--rnb-line-height-heading);
}

.body {
    color: var(--rnb-colour-midnight-tint-64);
}
