.hero {
    background-color: var(--rnb-colour-chill-tint-4);
}

.tail {
    background-color: var(--rnb-colour-black-alpha-4);
}

.hero,
.tail {
    overflow: hidden;
}
