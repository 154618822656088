.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: var(--rnb-spacing-medium);

    border-width: var(--rnb-border-width-thin);
    border-style: solid;

    box-shadow: var(--rnb-shadow-earth);
    border-radius: var(--rnb-border-radius-medium);

    &.light {
        background-color: var(--rnb-colour-midnight-tint-4);
        border-color: var(--rnb-colour-midnight-tint-48);

        &.isLightBg {
            background-color: var(--rnb-colour-white);
        }
    }

    &.dark {
        background-color: var(--rnb-colour-white-alpha-8);
        border-color: var(--rnb-colour-white-alpha-48);

        &.isLightBg {
            background-color: var(--rnb-colour-black-alpha-64);
        }
    }
}

.image {
    width: 75px;
    height: 75px;
    margin-right: var(--rnb-spacing-medium);
}

.text > h3 {
    margin-bottom: var(--rnb-spacing-tiny);
}
