:root {
    --min-image-height: 575px;
    --left-text-left: 10%;
    --left-text-bottom: 10%;
    --right-items-width-small: 210px;
    --right-items-top-small: 20%;
    --right-items-width-large: 300px;
    --right-items-top-large: 15%;
    --right-items-right: 10%;
    --cta-bottom: 4%;
    --cta-open-left: 10%;
    --content-open-width: 35%;
    --content-open-min-width: 420px;
}

.wrapper {
    position: relative;
    color: var(--rnb-colour-white);

    & :global(.gatsby-image-wrapper) {
        min-height: var(--min-image-height);
    }
}

.textLeft {
    position: absolute;
    left: var(--left-text-left);
    bottom: var(--left-text-bottom);
}

.textRight {
    position: absolute;
    font-size: var(--rnb-font-size-big);
    font-weight: var(--rnb-font-weight-bold);
    line-height: var(--rnb-line-height-heading);
    right: var(--right-items-right);
    top: var(--right-items-top-small);
    width: var(--right-items-width-small);

    @media (--rnb-mq-large) {
        top: var(--right-items-top-large);
        width: var(--right-items-width-large);
    }
}

.ctaClosed {
    position: absolute;
    bottom: var(--cta-bottom);
    right: var(--right-items-right);
    width: var(--right-items-width-small);

    @media (--rnb-mq-large) {
        width: var(--right-items-width-large);
    }
}

.ctaOpen {
    position: absolute;
    bottom: var(--cta-bottom);
    left: var(--cta-open-left);
    color: var(--rnb-colour-midnight);
}

.contentOpen {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: var(--content-open-width);
    min-width: var(--content-open-min-width);
    color: var(--rnb-colour-midnight-tint-64);
    background-color: var(--rnb-colour-white);
}

.openQuote {
    color: var(--rnb-colour-midnight);
}
