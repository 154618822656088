.table {
    display: table;
    overflow: auto;
    width: 100%;
    color: var(--rnb-colour-midnight);
    text-align: left;

    @media (--rnb-mq-medium) {
        display: flex;
        flex-direction: column;
    }

    & thead th {
        border-bottom: var(--rnb-border-width-thin) solid;
    }
}

.row {
    display: table-row;

    @media (--rnb-mq-medium) {
        display: flex;
        flex-direction: row;
    }

    &:not(:last-child) > td {
        border-bottom: 0.5px solid var(--rnb-colour-midnight-tint-32);
    }
}

.cell {
    display: table-cell;
    padding: var(--rnb-spacing-medium) var(--rnb-spacing-tiny) var(--rnb-spacing-medium) var(--rnb-spacing-tiny);

    @media (--rnb-mq-medium) {
        flex: 1 0 0;
    }
}
