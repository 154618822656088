.spacingTiny {
    --margin-top: var(--rnb-spacing-tiny);
    --margin-bottom: var(--rnb-spacing-small);
}

.spacingLarge {
    --margin-top: var(--rnb-spacing-large);
    --margin-bottom: var(--rnb-spacing-big);
}

.heading {
    display: flex;
    align-items: center;
    margin-bottom: var(--margin-bottom, var(--rnb-spacing-micro));

    &:empty {
        display: none;
    }
}

.icon {
    margin-right: var(--rnb-spacing-micro);
}

.list {
    position: relative;
}

.li {
    line-height: var(--rnb-line-height-heading);
    align-items: center;

    &:not(:first-child) {
        margin-top: var(--rnb-spacing-micro);
    }
}

.dt {
    font-weight: var(--rnb-font-weight-bold);
    line-height: var(--rnb-line-height-heading);

    &:not(:first-child) {
        margin-top: var(--rnb-spacing-micro);
    }
}

.dd {
    line-height: var(--rnb-line-height-text);
}

.li,
.dd {
    &:not(:first-child) {
        margin-top: var(--margin-top);
    }
}

.li,
.dt {
    &::before {
        position: absolute;
        left: 0;
    }
}

.list.hasBullet {
    & .li,
    & .dt,
    & .dd {
        margin-left: var(--rnb-spacing-big);
    }

    &.isNumbered {
        counter-reset: adl;

        & .li,
        & .dt {
            counter-increment: adl;

            &::before {
                margin-left: var(--rnb-spacing-tiny);
                content: counter(adl) '.';
            }
        }
    }

    &:not(.isNumbered) {
        & .li,
        & .dt {
            &::before {
                content: '';
                background-color: var(--rnb-theme-colour-primary, var(--rnb-colour-midnight));
                border-radius: var(--rnb-border-radius-circle);
                height: 4px;
                width: 4px;
                margin-top: 0.5em; /* center align the bullet */
                margin-left: var(--rnb-spacing-small);
            }
        }
    }
}

.list:not(.hasBullet) {
    & .li,
    & .dt {
        display: flex;
    }
}

.bulletIcon {
    margin-right: var(--rnb-spacing-small);
    vertical-align: middle;
}
