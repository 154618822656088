/* stylelint-disable */

@custom-media --rnb-mq-small (min-width: 375px);
@custom-media --rnb-mq-phablet (min-width: 512px);
@custom-media --rnb-mq-medium (min-width: 768px);
@custom-media --rnb-mq-lt-medium (max-width: 767px);
@custom-media --rnb-mq-large (min-width: 1024px);
@custom-media --rnb-mq-huge (min-width: 1408px);

:root {
    --rnb-border-radius-circle: 50%;
    --rnb-border-radius-small: 0.25rem;
    --rnb-border-radius-medium: 0.5rem;
    --rnb-border-radius-large: 1rem;
    --rnb-border-radius-big: 1.25rem;
    --rnb-border-width-thin: 1px;
    --rnb-border-width-thin-offset: -1px;
    --rnb-border-width-default: 2px;
    --rnb-border-width-thick: 4px;
    --rnb-colour-transparent: transparent;
    --rnb-colour-black-tint-4: #f5f5f5;
    --rnb-colour-black-tint-4-rgb: 245, 245, 245;
    --rnb-colour-black-alpha-4: rgba(0, 0, 0, 0.04);
    --rnb-colour-black-alpha-8: rgba(0, 0, 0, 0.08);
    --rnb-colour-black-alpha-16: rgba(0, 0, 0, 0.16);
    --rnb-colour-black-alpha-32: rgba(0, 0, 0, 0.32);
    --rnb-colour-black-alpha-48: rgba(0, 0, 0, 0.48);
    --rnb-colour-black-alpha-64: rgba(0, 0, 0, 0.64);
    --rnb-colour-black: #000;
    --rnb-colour-black-rgb: 0, 0, 0;
    --rnb-colour-white-alpha-4: rgba(255, 255, 255, 0.04);
    --rnb-colour-white-alpha-8: rgba(255, 255, 255, 0.08);
    --rnb-colour-white-alpha-16: rgba(255, 255, 255, 0.16);
    --rnb-colour-white-alpha-32: rgba(255, 255, 255, 0.32);
    --rnb-colour-white-alpha-48: rgba(255, 255, 255, 0.48);
    --rnb-colour-white-alpha-64: rgba(255, 255, 255, 0.64);
    --rnb-colour-white-alpha-80: rgba(255, 255, 255, 0.8);
    --rnb-colour-white: #fff;
    --rnb-colour-white-rgb: 255, 255, 255;
    --rnb-colour-midnight-tint-4: #f7f7f8;
    --rnb-colour-midnight-tint-4-rgb: 247, 247, 248;
    --rnb-colour-midnight-tint-8: #eeeff1;
    --rnb-colour-midnight-tint-8-rgb: 238, 239, 241;
    --rnb-colour-midnight-tint-16: #dcdfe2;
    --rnb-colour-midnight-tint-16-rgb: 220, 223, 226;
    --rnb-colour-midnight-tint-32: #b9bec4;
    --rnb-colour-midnight-tint-32-rgb: 185, 190, 196;
    --rnb-colour-midnight-tint-48: #979fa8;
    --rnb-colour-midnight-tint-48-rgb: 151, 159, 168;
    --rnb-colour-midnight-tint-64: #747f8a;
    --rnb-colour-midnight-tint-64-rgb: 116, 127, 138;
    --rnb-colour-midnight-tint-80: #515e6d;
    --rnb-colour-midnight-tint-80-rgb: 81, 94, 109;
    --rnb-colour-midnight-alpha-96: rgba(38, 54, 72, 0.96);
    --rnb-colour-midnight: #263648;
    --rnb-colour-midnight-rgb: 38, 54, 72;
    --rnb-colour-midnight-shade-64: #18232e;
    --rnb-colour-midnight-shade-64-rgb: 24, 35, 46;
    --rnb-colour-midnight-shade-48: #121a23;
    --rnb-colour-midnight-shade-48-rgb: 18, 26, 35;
    --rnb-colour-midnight-shade-32: #0c1117;
    --rnb-colour-midnight-shade-32-rgb: 12, 17, 23;
    --rnb-colour-chill-tint-4: #f6fafc;
    --rnb-colour-chill-tint-4-rgb: 246, 250, 252;
    --rnb-colour-chill-tint-8: #ecf5f8;
    --rnb-colour-chill-tint-8-rgb: 236, 245, 248;
    --rnb-colour-chill-tint-16: #d8ebf0;
    --rnb-colour-chill-tint-16-rgb: 216, 235, 240;
    --rnb-colour-chill-tint-32: #b2d6e1;
    --rnb-colour-chill-tint-32-rgb: 178, 214, 225;
    --rnb-colour-chill-tint-64: #66aec5;
    --rnb-colour-chill-tint-64-rgb: 102, 174, 197;
    --rnb-colour-chill: #0f81a3;
    --rnb-colour-chill-rgb: 15, 129, 163;
    --rnb-colour-chill-shade-64: #0a5269;
    --rnb-colour-chill-shade-64-rgb: 10, 82, 105;
    --rnb-colour-chill-shade-48: #073e4e;
    --rnb-colour-chill-shade-48-rgb: 7, 62, 78;
    --rnb-colour-chill-shade-32: #052934;
    --rnb-colour-chill-shade-32-rgb: 5, 41, 52;
    --rnb-colour-rose-tint-8: #faefef;
    --rnb-colour-rose-tint-8-rgb: 250, 239, 239;
    --rnb-colour-rose-tint-16: #f4dede;
    --rnb-colour-rose-tint-16-rgb: 244, 222, 222;
    --rnb-colour-rose-tint-32: #e9bcbc;
    --rnb-colour-rose-tint-32-rgb: 233, 188, 188;
    --rnb-colour-rose-tint-64: #d57a7a;
    --rnb-colour-rose-tint-64-rgb: 213, 122, 122;
    --rnb-colour-rose: #bd2f2f;
    --rnb-colour-rose-rgb: 189, 47, 47;
    --rnb-colour-rose-shade-64: #791e1e;
    --rnb-colour-rose-shade-64-rgb: 121, 30, 30;
    --rnb-colour-rose-shade-48: #5b1717;
    --rnb-colour-rose-shade-48-rgb: 91, 23, 23;
    --rnb-colour-rose-shade-32: #3c0f0f;
    --rnb-colour-rose-shade-32-rgb: 60, 15, 15;
    --rnb-colour-lavender-tint-8: #fcf5fe;
    --rnb-colour-lavender-tint-8-rgb: 252, 245, 254;
    --rnb-colour-lavender-tint-16: #f8eafd;
    --rnb-colour-lavender-tint-16-rgb: 248, 234, 253;
    --rnb-colour-lavender-tint-32: #f0d5fb;
    --rnb-colour-lavender-tint-32-rgb: 240, 213, 251;
    --rnb-colour-lavender-tint-64: #e2acf7;
    --rnb-colour-lavender-tint-64-rgb: 226, 172, 247;
    --rnb-colour-lavender: #d27df3;
    --rnb-colour-lavender-rgb: 210, 125, 243;
    --rnb-colour-lavender-shade-64: #86509b;
    --rnb-colour-lavender-shade-64-rgb: 134, 80, 155;
    --rnb-colour-lavender-shade-48: #653c75;
    --rnb-colour-lavender-shade-48-rgb: 101, 60, 117;
    --rnb-colour-lavender-shade-32: #43284e;
    --rnb-colour-lavender-shade-32-rgb: 67, 40, 78;
    --rnb-colour-sand-tint-8: #fff8f1;
    --rnb-colour-sand-tint-8-rgb: 255, 248, 241;
    --rnb-colour-sand-tint-16: #fdefe2;
    --rnb-colour-sand-tint-16-rgb: 253, 239, 226;
    --rnb-colour-sand-tint-32: #fce0c6;
    --rnb-colour-sand-tint-32-rgb: 252, 224, 198;
    --rnb-colour-sand-tint-64: #f9c28d;
    --rnb-colour-sand-tint-64-rgb: 249, 194, 141;
    --rnb-colour-sand: #f69f4d;
    --rnb-colour-sand-rgb: 246, 159, 77;
    --rnb-colour-sand-shade-64: #9d6631;
    --rnb-colour-sand-shade-64-rgb: 157, 102, 49;
    --rnb-colour-sand-shade-48: #764c25;
    --rnb-colour-sand-shade-48-rgb: 118, 76, 37;
    --rnb-colour-sand-shade-32: #4f3319;
    --rnb-colour-sand-shade-32-rgb: 79, 51, 25;
    --rnb-colour-dandelion-tint-8: #fefbee;
    --rnb-colour-dandelion-tint-8-rgb: 254, 251, 238;
    --rnb-colour-dandelion-tint-16: #fbf6dc;
    --rnb-colour-dandelion-tint-16-rgb: 251, 246, 220;
    --rnb-colour-dandelion-tint-32: #f8ecb9;
    --rnb-colour-dandelion-tint-32-rgb: 248, 236, 185;
    --rnb-colour-dandelion-tint-64: #f1db75;
    --rnb-colour-dandelion-tint-64-rgb: 41, 219, 117;
    --rnb-colour-dandelion: #e9c627;
    --rnb-colour-dandelion-rgb: 233, 198, 39;
    --rnb-colour-dandelion-shade-64: #957f19;
    --rnb-colour-dandelion-shade-64-rgb: 149, 127, 25;
    --rnb-colour-dandelion-shade-48: #705f13;
    --rnb-colour-dandelion-shade-48-rgb: 112, 95, 19;
    --rnb-colour-dandelion-shade-32: #4b3f0c;
    --rnb-colour-dandelion-shade-32-rgb: 75, 63, 12;
    --rnb-colour-moss-tint-8: #f8fbee;
    --rnb-colour-moss-tint-8-rgb: 248, 251, 238;
    --rnb-colour-moss-tint-16: #f2f8de;
    --rnb-colour-moss-tint-16-rgb: 242, 248, 222;
    --rnb-colour-moss-tint-32: #e4f0bc;
    --rnb-colour-moss-tint-32-rgb: 228, 240, 188;
    --rnb-colour-moss-tint-64: #cbe27b;
    --rnb-colour-moss-tint-64-rgb: 203, 226, 123;
    --rnb-colour-moss: #add230;
    --rnb-colour-moss-rgb: 173, 210, 48;
    --rnb-colour-moss-shade-64: #6f861f;
    --rnb-colour-moss-shade-64-rgb: 111, 134, 3;
    --rnb-colour-moss-shade-48: #536517;
    --rnb-colour-moss-shade-48-rgb: 83, 101, 23;
    --rnb-colour-moss-shade-32: #37430f;
    --rnb-colour-moss-shade-32-rgb: 55, 67, 15;
    --rnb-colour-sea-tint-8: #effdf5;
    --rnb-colour-sea-tint-8-rgb: #239, 253, 245;
    --rnb-colour-sea-tint-16: #ddf9ea;
    --rnb-colour-sea-tint-16-rgb: 221, 249, 234;
    --rnb-colour-sea-tint-32: #bbf3d4;
    --rnb-colour-sea-tint-32-rgb: 187, 243, 212;
    --rnb-colour-sea-tint-64: #78e8ab;
    --rnb-colour-sea-tint-64-rgb: 120, 232, 171;
    --rnb-colour-sea: #2cdb7b;
    --rnb-colour-sea-rgb: 44, 219, 123;
    --rnb-colour-sea-shade-64: #1c8c4f;
    --rnb-colour-sea-shade-64-rgb: 28, 140, 79;
    --rnb-colour-sea-shade-48: #15693b;
    --rnb-colour-sea-shade-48-rgb: 21, 105, 59;
    --rnb-colour-sea-shade-32: #0e4627;
    --rnb-colour-sea-shade-32-rgb: 14, 70, 39;
    --rnb-colour-reef-tint-8: #effcfb;
    --rnb-colour-reef-tint-8-rgb: 239, 252, 25;
    --rnb-colour-reef-tint-16: #ddf8f7;
    --rnb-colour-reef-tint-16-rgb: 221, 248, 247;
    --rnb-colour-reef-tint-32: #bbf0ee;
    --rnb-colour-reef-tint-32-rgb: 187, 240, 238;
    --rnb-colour-reef-tint-64: #78e1dc;
    --rnb-colour-reef-tint-64-rgb: 120, 225, 220;
    --rnb-colour-reef: #2bcfc8;
    --rnb-colour-reef-rgb: 43, 207, 200;
    --rnb-colour-reef-shade-64: #1c8580;
    --rnb-colour-reef-shade-64-rgb: 28, 133, 128;
    --rnb-colour-reef-shade-48: #156460;
    --rnb-colour-reef-shade-48-rgb: 21, 100, 96;
    --rnb-colour-reef-shade-32: #0e4341;
    --rnb-colour-reef-shade-32-rgb: 14, 67, 65;
    --rnb-colour-salvia: #9e86e7;
    --rnb-colour-salvia-rgb: 158, 134, 231;
    --rnb-colour-salvia-shade-32: #332b4a;
    --rnb-colour-salvia-shade-32-rgb: 51, 43, 74;
    --rnb-colour-salvia-shade-48: #4c406f;
    --rnb-colour-salvia-shade-48-rgb: 76, 64, 111;
    --rnb-colour-salvia-shade-64: #655694;
    --rnb-colour-salvia-shade-64-rgb: 101, 86, 148;
    --rnb-colour-salvia-tint-8: #f7f5fd;
    --rnb-colour-salvia-tint-8-rgb: 247, 245, 253;
    --rnb-colour-salvia-tint-16: #efecfb;
    --rnb-colour-salvia-tint-16-rgb: 239, 236, 251;
    --rnb-colour-salvia-tint-32: #e0d8f7;
    --rnb-colour-salvia-tint-32-rgb: 224, 216, 247;
    --rnb-colour-salvia-tint-64: #c1b2f0;
    --rnb-colour-salvia-tint-64-rgb: 193, 178, 240;
    --rnb-colour-sky: #49c1e3;
    --rnb-colour-sky-rgb: 73, 193, 227;
    --rnb-colour-sky-shade-32: #173e49;
    --rnb-colour-sky-shade-32-rgb: 23, 62, 73;
    --rnb-colour-sky-shade-48: #235d6d;
    --rnb-colour-sky-shade-48-rgb: 35, 93, 109;
    --rnb-colour-sky-shade-64: #2f7c91;
    --rnb-colour-sky-shade-64-rgb: 47, 124, 145;
    --rnb-colour-sky-tint-8: #f0fafd;
    --rnb-colour-sky-tint-8-rgb: 240, 250, 253;
    --rnb-colour-sky-tint-16: #e2f5fb;
    --rnb-colour-sky-tint-16-rgb: 225, 245, 251;
    --rnb-colour-sky-tint-32: #c5ebf6;
    --rnb-colour-sky-tint-32-rgb: 197, 235, 246;
    --rnb-colour-sky-tint-64: #8ad8ed;
    --rnb-colour-sky-tint-64-rgb: 138, 216, 237;
    --rnb-colour-grad-shade-a: #1a2430;
    --rnb-colour-grad-shade-a-rgb: 26, 36, 48, 1;
    --rnb-colour-grad-shade-tint: #3e3837;
    --rnb-colour-grad-shade-tint-rgb: 62, 56, 55, 1;
    --rnb-colour-grad-shade-b: #c46466;
    --rnb-colour-grad-shade-b-rgb: 196, 100, 102, 1;
    --rnb-colour-band-theta: #f9c28d;
    --rnb-colour-band-alpha: #f9c28d;
    --rnb-colour-band-beta: #f1db75;
    --rnb-colour-band-gamma: #cbe27b;
    --rnb-colour-band-delta: #78e8ab;
    --rnb-colour-band-epsilon: #b2d6e1;
    --rnb-colour-primary-light: #263648;
    --rnb-colour-primary-inverted-light: #fff;
    --rnb-colour-primary-light-rgb: 38, 54, 72;
    --rnb-colour-secondary-light: #747f8a;
    --rnb-colour-secondary-light-rgb: 116, 127, 138;
    --rnb-colour-tertiary-light: #979fa8;
    --rnb-colour-tertiary-light-rgb: 151, 159, 168;
    --rnb-colour-positive-light: #1c8c4f;
    --rnb-colour-positive-light-rgb: 28, 140, 79;
    --rnb-colour-negative-light: #bd2f2f;
    --rnb-colour-negative-light-rgb: 189, 47, 47;
    --rnb-colour-attention-light: #f69f4d;
    --rnb-colour-attention-light-rgb: 246, 159, 77;
    --rnb-colour-disabled-light: #dcdfe2;
    --rnb-colour-disabled-light-rgb: 220, 223, 226;
    --rnb-colour-action-light: #0f81a3;
    --rnb-colour-action-light-rgb: 15, 129, 163;
    --rnb-colour-action-hover-light: #0a5269;
    --rnb-colour-overlay-light: rgba(38, 54, 72, 0.96);
    --rnb-colour-background-light: #f7f7f8;
    --rnb-colour-card-light: #fff;
    --rnb-colour-cell-light: #fff;
    --rnb-colour-section-light: #fff;
    --rnb-colour-header-light: #fff;
    --rnb-colour-footer-light: #18232e;
    --rnb-colour-primary-dark: #fff;
    --rnb-colour-primary-inverted-dark: #263648;
    --rnb-colour-secondary-dark: rgba(255, 255, 255, 0.64);
    --rnb-colour-tertiary-dark: rgba(255, 255, 255, 0.48);
    --rnb-colour-positive-dark: #2cdb7b;
    --rnb-colour-negative-dark: #d57a7a;
    --rnb-colour-attention-dark: #f69f4d;
    --rnb-colour-disabled-dark: rgba(255, 255, 255, 0.16);
    --rnb-colour-action-dark: #fff;
    --rnb-colour-action-hover-dark: rgba(255, 255, 255, 0.64);
    --rnb-colour-overlay-dark: rgba(0, 0, 0, 0.64);
    --rnb-colour-background-dark: #0c1117;
    --rnb-colour-card-dark: #18232e;
    --rnb-colour-cell-dark: rgba(255, 255, 255, 0.04);
    --rnb-colour-section-dark: rgba(255, 255, 255, 0.04);
    --rnb-colour-header-dark: rgba(255, 255, 255, 0.04);
    --rnb-colour-footer-dark: rgba(255, 255, 255, 0.04);
    --rnb-colour-cs24-button-gradient-primary: linear-gradient(122deg, #275053 0%, #0F2533 100%);
    --rnb-colour-cs24-button-gradient-secondary: linear-gradient(122deg, #CAE4E6 0%, #BAD8EB 100%);
    --rnb-color-cs24-button-primary-text: #0C1E29;
    --rnb-font-family-cs-clarity: CSClarity, Helvetica Neue, Helvetica, Arial, sans-serif;
    --rnb-font-family-cs-clarity-display: CS Clarity Display, CSClarity, Helvetica Neue, Helvetica, Arial, sans-serif;
    --rnb-font-size-micro: 0.625rem;
    --rnb-font-size-tiny: 0.75rem;
    --rnb-font-size-mini: 0.875rem;
    --rnb-font-size-small: 1rem;
    --rnb-font-size-medium: 1.25rem;
    --rnb-font-size-large: 1.5rem;
    --rnb-font-size-big: 2rem;
    --rnb-font-size-huge: 2.5rem;
    --rnb-font-size-massive: 2.75rem;
    --rnb-font-size-jumbo: 4rem;
    --rnb-font-size-gargantuan: 6rem;
    --rnb-font-size-ridiculous: 8rem;
    --rnb-font-weight-thin: 100;
    --rnb-font-weight-light: 200;
    --rnb-font-weight-book: 300;
    --rnb-font-weight-regular: 400;
    --rnb-font-weight-medium: 500;
    --rnb-font-weight-bold: 700;
    --rnb-font-weight-black: 900;
    --rnb-gradient-exclusive: linear-gradient(-180deg, #263648, #18232e);
    --rnb-line-height-reset: 1;
    --rnb-line-height-text: 1.5;
    --rnb-line-height-heading: 1.2;
    --rnb-shadow-sea: 0 0 0 transparent;
    --rnb-shadow-earth: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.16);
    --rnb-shadow-hill: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.16);
    --rnb-shadow-mountain: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.16);
    --rnb-shadow-hill-light: 0 0 0.75rem rgba(0, 0, 0, 0.04), 0 0.4rem 0.75rem rgba(0, 0, 0, 0.08);
    --rnb-shadow-earth-top: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.16);
    --rnb-shadow-hill-top: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.16);
    --rnb-shadow-mountain-top: 0 -0.5rem 0.75rem rgba(0, 0, 0, 0.16);
    --rnb-spacing-none: 0rem;
    --rnb-spacing-micro: 0.25rem;
    --rnb-spacing-tiny: 0.5rem;
    --rnb-spacing-small: 0.75rem;
    --rnb-spacing-medium: 1rem;
    --rnb-spacing-large: 1.5rem;
    --rnb-spacing-big: 2rem;
    --rnb-spacing-huge: 3rem;
    --rnb-spacing-super: 4rem;
    --rnb-spacing-jumbo: 8rem;
    --rnb-spacing-inset-squash-micro: 0.25rem 0.5rem;
    --rnb-spacing-inset-squash-tiny: 0.5rem 0.75rem;
    --rnb-spacing-inset-squash-small: 0.75rem 1rem;
    --rnb-spacing-inset-squash-medium: 1rem 1.5rem;
    --rnb-spacing-inset-squeeze-micro: 0.5rem 0.25rem;
    --rnb-spacing-inset-squeeze-tiny: 0.75rem 0.5rem;
    --rnb-spacing-inset-squeeze-medium: 1.5rem 1rem;
    --rnb-timing-fast: 250ms;
    --rnb-timing-normal: 500ms;
    --rnb-timing-slow: 1000ms;
    --rnb-timing-easing-in: cubic-bezier(0.4, 0, 1, 1);
    --rnb-zindex-dive: -200;
    --rnb-zindex-default: 1;
    --rnb-zindex-levitate: 2;
    --rnb-zindex-rise: 200;
    --rnb-zindex-sky: 400;
    --rnb-zindex-top: 600;
    --slate-colour-slate-tint-40: #96CACD; 
    --slate-colour-slate-tint-20: #CAE4E6;
    --slate-colour-slate-tint-10: #E5F2F3;
    --slate-colour-slate-tint-5: #EAF5F5;
    --slate-colour-metal-tint-80: #235778;
    --slate-colour-metal-tint-20: #BAD8EB;
    --slate-colour-coral-tint-10: #FDF5F3;
    --slate-colour-coral-tint-40: #F6D7D0;
}
