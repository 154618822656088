.none > * + * {
    margin-top: var(--rnb-spacing-none);
}

.micro > * + * {
    margin-top: var(--rnb-spacing-micro);
}

.tiny > * + * {
    margin-top: var(--rnb-spacing-tiny);
}

.small > * + * {
    margin-top: var(--rnb-spacing-small);
}

.medium > * + * {
    margin-top: var(--rnb-spacing-medium);
}

.large > * + * {
    margin-top: var(--rnb-spacing-large);
}

.big > * + * {
    margin-top: var(--rnb-spacing-big);
}

.huge > * + * {
    margin-top: var(--rnb-spacing-huge);
}

.super > * + * {
    margin-top: var(--rnb-spacing-super);
}

.jumbo > * + * {
    margin-top: var(--rnb-spacing-jumbo);
}

/************************
 * MEDIA QUERY -  SMALL *
 ************************/

@media (--rnb-mq-small) {
    .none-at-small > * + * {
        margin-top: var(--rnb-spacing-none);
    }

    .micro-at-small > * + * {
        margin-top: var(--rnb-spacing-micro);
    }

    .tiny-at-small > * + * {
        margin-top: var(--rnb-spacing-tiny);
    }

    .small-at-small > * + * {
        margin-top: var(--rnb-spacing-small);
    }

    .medium-at-small > * + * {
        margin-top: var(--rnb-spacing-medium);
    }

    .large-at-small > * + * {
        margin-top: var(--rnb-spacing-large);
    }

    .big-at-small > * + * {
        margin-top: var(--rnb-spacing-big);
    }

    .huge-at-small > * + * {
        margin-top: var(--rnb-spacing-huge);
    }

    .super-at-small > * + * {
        margin-top: var(--rnb-spacing-super);
    }

    .jumbo-at-small > * + * {
        margin-top: var(--rnb-spacing-jumbo);
    }
}

/************************
 * MEDIA QUERY -  PHABLET *
 ************************/

@media (--rnb-mq-phablet) {
    .none-at-phablet > * + * {
        margin-top: var(--rnb-spacing-none);
    }

    .micro-at-phablet > * + * {
        margin-top: var(--rnb-spacing-micro);
    }

    .tiny-at-phablet > * + * {
        margin-top: var(--rnb-spacing-tiny);
    }

    .small-at-phablet > * + * {
        margin-top: var(--rnb-spacing-small);
    }

    .medium-at-phablet > * + * {
        margin-top: var(--rnb-spacing-medium);
    }

    .large-at-phablet > * + * {
        margin-top: var(--rnb-spacing-large);
    }

    .big-at-phablet > * + * {
        margin-top: var(--rnb-spacing-big);
    }

    .huge-at-phablet > * + * {
        margin-top: var(--rnb-spacing-huge);
    }

    .super-at-phablet > * + * {
        margin-top: var(--rnb-spacing-super);
    }

    .jumbo-at-phablet > * + * {
        margin-top: var(--rnb-spacing-jumbo);
    }
}

/************************
 * MEDIA QUERY -  MEDIUM *
 ************************/

@media (--rnb-mq-medium) {
    .none-at-medium > * + * {
        margin-top: var(--rnb-spacing-none);
    }

    .micro-at-medium > * + * {
        margin-top: var(--rnb-spacing-micro);
    }

    .tiny-at-medium > * + * {
        margin-top: var(--rnb-spacing-tiny);
    }

    .small-at-medium > * + * {
        margin-top: var(--rnb-spacing-small);
    }

    .medium-at-medium > * + * {
        margin-top: var(--rnb-spacing-medium);
    }

    .large-at-medium > * + * {
        margin-top: var(--rnb-spacing-large);
    }

    .big-at-medium > * + * {
        margin-top: var(--rnb-spacing-big);
    }

    .huge-at-medium > * + * {
        margin-top: var(--rnb-spacing-huge);
    }

    .super-at-medium > * + * {
        margin-top: var(--rnb-spacing-super);
    }

    .jumbo-at-medium > * + * {
        margin-top: var(--rnb-spacing-jumbo);
    }
}

/************************
 * MEDIA QUERY -  LARGE *
 ************************/

@media (--rnb-mq-large) {
    .none-at-large > * + * {
        margin-top: var(--rnb-spacing-none);
    }

    .micro-at-large > * + * {
        margin-top: var(--rnb-spacing-micro);
    }

    .tiny-at-large > * + * {
        margin-top: var(--rnb-spacing-tiny);
    }

    .small-at-large > * + * {
        margin-top: var(--rnb-spacing-small);
    }

    .medium-at-large > * + * {
        margin-top: var(--rnb-spacing-medium);
    }

    .large-at-large > * + * {
        margin-top: var(--rnb-spacing-large);
    }

    .big-at-large > * + * {
        margin-top: var(--rnb-spacing-big);
    }

    .huge-at-large > * + * {
        margin-top: var(--rnb-spacing-huge);
    }

    .super-at-large > * + * {
        margin-top: var(--rnb-spacing-super);
    }

    .jumbo-at-large > * + * {
        margin-top: var(--rnb-spacing-jumbo);
    }
}

/************************
 * MEDIA QUERY -  HUGE *
 ************************/

@media (--rnb-mq-huge) {
    .none-at-huge > * + * {
        margin-top: var(--rnb-spacing-none);
    }

    .micro-at-huge > * + * {
        margin-top: var(--rnb-spacing-micro);
    }

    .tiny-at-huge > * + * {
        margin-top: var(--rnb-spacing-tiny);
    }

    .small-at-huge > * + * {
        margin-top: var(--rnb-spacing-small);
    }

    .medium-at-huge > * + * {
        margin-top: var(--rnb-spacing-medium);
    }

    .large-at-huge > * + * {
        margin-top: var(--rnb-spacing-large);
    }

    .big-at-huge > * + * {
        margin-top: var(--rnb-spacing-big);
    }

    .huge-at-huge > * + * {
        margin-top: var(--rnb-spacing-huge);
    }

    .super-at-huge > * + * {
        margin-top: var(--rnb-spacing-super);
    }

    .jumbo-at-huge > * + * {
        margin-top: var(--rnb-spacing-jumbo);
    }
}
