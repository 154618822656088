@keyframes HideMenuLinks {
    0% {
        visibility: visible;
    }

    100% {
        visibility: hidden;
    }
}

.navigationContent {
    overflow: hidden;
    z-index: 1;

    @media (--rnb-mq-lt-medium) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--rnb-spacing-large);
        background-color: var(--rnb-colour-black);
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        animation: HideMenuLinks 0.3s linear forwards;

        @supports (backdrop-filter: blur()) {
            background-color: var(--rnb-colour-black-alpha-64);
        }

        &.open {
            transform: translateX(0%);
            animation: none;
        }
    }
}

.navigationContentActions {
    display: flex;
    justify-content: space-between;

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.navigationContentActionsItem {
    color: var(--rnb-colour-white);
    cursor: pointer;
}

.navigationContentCTAs {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        display: none;
    }
}

.navigationContentItems {
    display: flex;
    gap: var(--rnb-spacing-medium);
    overflow: hidden;

    @media (--rnb-mq-lt-medium) {
        margin: var(--rnb-spacing-large) 0;
        flex-direction: column;
        overflow-y: auto;
    }
}
