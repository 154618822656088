:root {
    --header-font-size-small: var(--rnb-font-size-big);
    --header-font-size-large: 3rem;
}

.wrapper {
    color: var(--rnb-colour-midnight);
}

.header {
    color: var(--rnb-colour-midnight);
    font-weight: var(--rnb-font-weight-bold);
    font-size: var(--header-font-size-small);

    @media (--rnb-mq-large) {
        font-size: var(--header-font-size-large);
    }
}

.sectionsLarge {
    display: none;
    align-items: stretch;

    @media (--rnb-mq-large) {
        display: flex;
    }

    & > * {
        margin-right: var(--rnb-spacing-super);
    }
}

.sectionsSmall {
    display: block;

    @media (--rnb-mq-large) {
        display: none;
    }

    & button {
        background: inherit;
        border: none;
        border-bottom: var(--rnb-border-width-thin) solid var(--rnb-colour-midnight-tint-16);
        font-size: var(--rnb-font-size-medium);
    }
}

.sectionContentBody {
    color: var(--rnb-colour-midnight-tint-64);
    display: flex;
    flex-direction: column;
}

.bullet {
    display: flex;
    align-items: flex-start;
    margin-top: var(--rnb-spacing-medium);
}

.bulletIcon {
    width: var(--rnb-font-size-medium);
    height: var(--rnb-font-size-medium);
}
