.investors {
    text-align: center;

    @media (--rnb-mq-medium) {
        text-align: left;
    }
}

.imageContainer {
    display: flex;
    align-items: center;

    > :first-child {
        display: inline-block;
        width: calc(60% - var(--rnb-spacing-huge));
    }

    > :nth-child(n + 2) {
        display: inline-block;
        width: 40%;
    }
}
