.alignCenter {
    @media (--rnb-mq-medium) {
        text-align: center;
    }
}

.alignCenter,
.steps {
    color: var(--rnb-colour-midnight);
}

.steps {
    --display: grid;
    --grid-gap: 1.5rem;
    --grid-template-columns: none;
    --flex-direction: column;
    --text-align: left;

    @media (--rnb-mq-medium) {
        --display: grid;
        --grid-template-columns: repeat(3, 1fr);
        --grid-gap: 2.5rem;
        --text-align: center;
    }

    display: var(--display);
    grid-template-columns: var(--grid-template-columns);
    gap: var(--grid-gap);
    text-align: var(--text-align);
    align-items: start;
}

.cta {
    display: flex;
    justify-content: center;
}
