.spacing {
    margin-bottom: var(--rnb-spacing-medium);

    &:last-child {
        margin-bottom: 0;
    }

    &.h2 {
        margin-top: var(--rnb-spacing-super);
    }

    &.h3 {
        margin-top: var(--rnb-spacing-big);
    }
}
