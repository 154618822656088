.section {
    background-color: var(--blue);
    padding: var(--rnb-spacing-super) 0 var(--rnb-spacing-tiny);
    text-align: center;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 164px;
    margin-bottom: var(--rnb-spacing-medium);
}

.pitch {
    margin-bottom: var(--rnb-spacing-huge);
    margin-top: var(--rnb-spacing-big);
    max-width: 630px;
    padding: 0 var(--rnb-spacing-large);
    font-size: var(--rnb-font-size-mini);
    line-height: var(--rnb-line-height-text);
    font-weight: var(--rnb-font-weight-regular);
    color: var(--rnb-colour-white);
}

.storeLogos {
    display: flex;
    align-items: center;
}

.appStoreLogo {
    width: 120px;
}

.googlePlayLogo {
    width: 135px;
    margin-right: var(--rnb-spacing-medium);
}
