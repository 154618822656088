.component {
    display: flex;
    align-items: center;
    opacity: 0;

    &.isThemeLight {
        color: var(--rnb-colour-midnight);
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);
    }

    &.isVisible {
        opacity: 1;
    }
}

.icon {
    margin-right: var(--rnb-spacing-tiny);
    display: inline-flex;

    &.isTypeSuccess {
        color: var(--rnb-colour-sea-shade-64);
    }

    &.isTypeWarning {
        color: var(--rnb-colour-sand);
    }

    &.isTypeError {
        color: var(--rnb-colour-midnight-tint-64);
    }

    &.isTypePending {
        color: var(--rnb-colour-sand);
    }
}
