:root {
    --size-keyline: 1px;
    --size-dot__2: 3px;
    --grid-gap: var(--rnb-spacing-large);
    --container-gutter: var(--rnb-spacing-big);
    --grid-gutters: calc(var(--container-gutter) - var(--grid-gap));
}

.component {
    background-color: var(--rnb-colour-white);
    overflow: hidden;
    margin-bottom: calc(var(--size-keyline) * -1);
    position: sticky;
    top: 0;
    transform: translateY(calc(var(--size-keyline) * -1));
    z-index: 2;
}

.wrapper {
    border-top: var(--size-keyline) solid var(--rnb-colour-black-alpha-16);
    margin: 0 var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        margin: 0 var(--rnb-spacing-big);
    }
}

.items {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template: 1fr / auto;
    overflow-x: auto;
    padding: var(--rnb-spacing-big) 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }

    @supports (grid-auto-flow: initial) {
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        grid-template: 1fr / var(--grid-gutters);
        margin: 0 calc(var(--container-gutter) * -1) 0;

        &::before,
        &::after {
            content: '';
            width: var(--grid-gutters);
        }
    }
}

.item {
    color: var(--rnb-colour-midnight);
    position: relative;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    &.activeUrl {
        text-decoration: underline;
    }
}
