.describeCard {
    width: 100%;
    max-width: 324px;

    @media (--rnb-mq-large) {
        max-width: 464px;
    }
}

.image {
    height: 200px;
    border-radius: 25px;
    background: transparent;
    z-index: var(--rnb-zindex-default);
}

.imageSection {
    margin-bottom: var(--rnb-spacing-huge);
}
