.background {
    background-color: var(--rnb-colour-moss-tint-16);
    border-radius: var(--rnb-border-radius-medium);
    padding: var(--rnb-spacing-tiny);
}

.background,
.background p {
    /* override due to mdx inserting a p tag where it isn't wanted */
    line-height: var(--rnb-line-height-heading) !important; /* remove when switching to gastby mdx */
}
