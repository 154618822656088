.dots {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: var(--rnb-spacing-large);
}

.items {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template: 1fr / auto;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }

    @supports (grid-auto-flow: initial) {
        grid-auto-columns: calc(90% - var(--container-gutter) - var(--grid-gap) / 2);
        grid-auto-flow: column;
        grid-template: 1fr / var(--grid-gutters);
        margin: 0 calc(var(--container-gutter) * -1) 0;

        @media (--rnb-mq-medium) {
            grid-auto-columns: calc(48% - var(--container-gutter) - var(--grid-gap) / 2);
        }

        &::before,
        &::after {
            content: '';
            width: var(--grid-gutters);
        }
    }

    & .item {
        position: relative;
        scroll-snap-align: center;
    }
}

.carousel {
    width: 100%;
    overflow: hidden;
    margin: var(--rnb-spacing-big) var(--rnb-spacing-none);
}

.carouselItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;

    @media (--rnb-mq-medium) {
        padding: var(--rnb-spacing-none) var(--rnb-spacing-medium);
        padding-top: var(--rnb-spacing-medium);
    }
}

.testimonials {
    justify-content: center;
    align-items: center;

    @media (--rnb-mq-medium) {
        display: flex;
    }

    & .items {
        width: 100%;
        margin-bottom: var(--rnb-spacing-large);

        @supports (grid-auto-flow: initial) {
            overflow-y: hidden;
            grid-auto-columns: 100%;
            grid-template-columns: 100%;

            @media (--rnb-mq-medium) {
                grid-auto-columns: 100%;
            }
        }

        &::before,
        &::after {
            display: none;
        }
    }

    & .innerCarousel {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 var(--rnb-spacing-large);
    }

    & .arrow {
        display: none;

        @media (--rnb-mq-medium) {
            display: block;
        }
    }
}
