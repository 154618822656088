
section[data-id="donut-landing-hero-sky-base"] {
    --donut-gradient-color: var(--rnb-colour-sky);
}
section[data-id="donut-landing-hero-sky"] {
    --donut-gradient-color: var(--rnb-colour-sky-tint-8);
}
section[data-id="donut-landing-hero-sky-tint-32"] {
    --donut-gradient-color: var(--rnb-colour-sky-tint-32);
}
section[data-id="donut-landing-hero-chill-base"] {
    --donut-gradient-color: var(--rnb-colour-chill);
}
section[data-id="donut-landing-hero-chill"] {
    --donut-gradient-color: var(--rnb-colour-chill-tint-4);
}
section[data-id="donut-landing-hero-chill-tint-32"] {
    --donut-gradient-color: var(--rnb-colour-chill-tint-32);
}
section[data-id="donut-landing-hero-white"] {
    --donut-gradient-color: var(--rnb-colour-white);
}
section[data-id="donut-landing-hero-transparent"] {
    --donut-gradient-color: transparent;
}

section[id="donut-landing-hero"] {
    background-image: linear-gradient(to bottom, transparent calc(100% - 200px), var(--donut-gradient-color)) !important;
}

:root {
    --rnb-bottom-arc-height: 3rem;
}
.donutSectionContainer {
    --arc-bg-chill-base: var(--rnb-colour-chill);
    --arc-bg-chill: var(--rnb-colour-chill-tint-4);
    --arc-bg-midnight: var(--rnb-colour-midnight-shade-48);
    --arc-bg-midnight-tint-4: var(--rnb-colour-midnight-tint-4);
    --arc-bg-light: var(--rnb-colour-background-light);
    --arc-bg-transparent: var(--rnb-colour-transparent);
    --arc-bg-white: var(--rnb-colour-white);
    --arc-bg-sand: var(--rnb-colour-sand-tint-8);
    --arc-bg-rose: var(--rnb-colour-rose-tint-8);
    --arc-bg-sea: var(--rnb-colour-sea-tint-8);
    --arc-bg-reef: var(--rnb-colour-reef-tint-8);
    --arc-bg-reef-tint-32: var(--rnb-colour-reef-tint-32);
    --arc-bg-salvia-base: var(--rnb-colour-salvia);
    --arc-bg-salvia: var(--rnb-colour-salvia-tint-8);
    --arc-bg-salvia-tint-32: var(--rnb-colour-salvia-tint-32);
    --arc-bg-sky-base: var(--rnb-colour-sky);
    --arc-bg-sky: var(--rnb-colour-sky-tint-8);
    --arc-bg-sky-tint-32: var(--rnb-colour-sky-tint-32);
    --arc-bg-slate-tint-5: var(--slate-colour-slate-tint-5);
    --arc-bg-white: var(--rnb-colour-white);

    margin-top: 60px;

    @media (--rnb-mq-phablet) {
        margin-top: 76px;
    }
}

.donutHero {
    background-color: var(--hello-section);
    --justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: var(--justify-content);
    text-align: center;
    gap: var(--rnb-spacing-large);

    &.hasBottomArc {
        margin-top: var(--rnb-spacing-large);
        margin-bottom: var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-large) {
        display: grid;
        display: grid;
        grid-template-columns: 5.5fr 4.5fr;
        min-height: 52vh;
        min-height: 52dvh;
        margin-top: 0;
        margin-bottom: 0;
    }

    &.compactHeight {
        min-height: 30vh;
        min-height: 30dvh;
        margin-bottom: 40px;
    }

}

.heading h1 {
    font-size: var(--rnb-font-size-big);
    font-weight: var(--rnb-font-weight-regular);
    line-height: var(--rnb-line-height-heading);
    display: none;
    @media (--rnb-mq-large) {
        display: block;
        font-size: var(--rnb-font-size-huge);
        font-weight: var(--rnb-font-weight-medium) !important;
    }
}

.headingsContainer {
    order: 2;
    @media (--rnb-mq-medium) {
        order: 1;
    }
}

.prospectFormContainer {
    order: 1;
    @media (--rnb-mq-medium) {
        order: 2;
        margin-bottom: var(--rnb-spacing-large);
    }
    @media (--rnb-mq-large) {
        margin-bottom: 0;
    }
}

.mobileOnlyHeading {
    display: var(--mobile-display);
    order: -2;
    

    p {
        font-size: var(--rnb-font-size-large);
        font-weight: var(--rnb-font-weight-regular);
        line-height: var(--rnb-line-height-heading);
        text-align: left;

        @media (--rnb-mq-medium) {
            font-size: var(--rnb-font-size-big);
        }
    }

    @media (--rnb-mq-large) {
        display: none;
    }

}

.subHeading {
    margin-top: var(--rnb-spacing-medium);
    margin-bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-medium) {
        margin-top: var(--rnb-spacing-medium);
    }
}

.subHeading p {
    font-size: var(--rnb-font-size-medium);
    font-weight: var(--rnb-font-weight-medium) !important;
    line-height: 1.2;
    text-align: left;

    @media (--rnb-mq-medium) {
        font-size: var(--rnb-font-size-large);
        font-weight: var(--rnb-font-weight-regular) !important;
    }
}

.mobileOnlyHeading {
    > p {
        font-family: var(--rnb-font-family-cs-clarity-display);
    }
}

.contentWrapper {
    text-align: center;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;

    @media (--rnb-mq-large) {
        text-align: left;

        &.hasArc {
            transform: translateY(calc(var(--rnb-bottom-arc-height) * -1));
        }    
    }
}

.prospectForm {
    margin: 0;

    @media (--rnb-mq-large) {
        margin-top: var(--rnb-spacing-large);
        margin-bottom: var(--rnb-spacing-medium);
    }

    form {
        grid-template-columns: 1fr;
        grid-row-gap: var(--rnb-spacing-tiny);

        @media (--rnb-mq-large) {
            grid-template-columns: 1fr auto;
        }
    }

    input::placeholder {
        color: var(--rnb-colour-white) !important; 
    }
    
    input:not(:focus) {
        border: solid 1px var(--rnb-colour-white);
    }
}

.imageWrapper {
    --mobile-display: unset;
    display: var(--mobile-display);
    order: -1;
    position: relative;
    width: 100%;
    align-self: center;  

    @media (--rnb-mq-large) {
        &.hasArc {
            transform: translateY(calc(var(--rnb-bottom-arc-height) * -1));
        }    
    }


    @media (--rnb-mq-large) {
        position: unset;
        padding-top: 0;
        order: unset;
        display: flex;
        justify-content: center;
    }

    img {
        object-fit: contain !important;

        @media (--rnb-mq-large) {
            object-fit: cover !important;
        }
    }

    &.centered {
        display: flex;
        justify-content: center;
    }
}

.donut {
    margin-top: var(--rnb-spacing-small);
    border-radius: 50%;

    @media (--rnb-mq-medium) {
        position: unset;
        margin-top: 0;
        margin-left: var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-large) {
        margin-left: var(--rnb-spacing-super);
    }

    @media (--rnb-mq-huge) {
        margin-left: 0;
    }
}


.image {
    height: 190px;
    width: 190px;

    @media (--rnb-mq-large) {
        height: 300px;
        width:300px;
    }
}

.highlights {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: var(--rnb-spacing-medium) 0 0;

    @media (--rnb-mq-large) {
        margin: var(--rnb-spacing-medium) 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr); 
        gap: 10px;
        margin: 0;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: var(--rnb-spacing-medium);
        color: var(--rnb-colour-black);

        &:last-child{
            margin-bottom: 0;
        }

        @media (--rnb-mq-large) {
            margin: 0;
        }
    }

    p {
        font-size: var(--rnb-font-size-small);
        font-weight: var(--rnb-font-weight-bold) !important;
        line-height: var(--rnb-line-height-heading);

        @media (--rnb-mq-large) {
            font-size: 1rem;
            font-style: normal;
            font-weight: var(--rnb-font-weight-medium) !important;
            line-height: var(--rnb-line-height-heading);
        }
    }

    &.hasArc {
        li {
            color: var(--rnb-colour-black);
        }
    }
}

.highlightIcon {
    max-width: 52px;
    height: 44px;
    margin-right: var(--rnb-spacing-tiny);
}

.bottomArcContainer {
    padding: 24px 0;
    position: relative;
}

.bottomArc {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    pointer-events: none;
    overflow: visible;
    background: transparent;
    z-index: 1;
    transform: translateY(calc(-100% + 0.2rem));

    @media (--rnb-mq-large) {
        height: 7rem;
    }

}

.arc {
    fill: white;
}

/* Floating donut for mobile behind flag with css class */
.donutHero {
    &.floatingMobileDonut {

        grid-gap: 0;

        .headingsContainer {
            order: 1;
        }

        .subHeading p {
            font-size: var(--rnb-font-size-small);
        }

        .prospectFormContainer {
            order: 2;
        }

        .prospectForm button {
            width: 50%;
            @media (--rnb-mq-large) {
                width: 100%;
            }
        }

        .mobileOnlyHeading {
            order:1;
        }

        .contentWrapper {
            order: 2;
        }

        .imageWrapper {
            order: 3;
            display: grid;
            justify-content: flex-end;

            > .image {
                margin-top: calc(-1 * var(--rnb-spacing-medium));
            }
        }
    }
}
