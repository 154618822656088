:root {
    --image-wrapper-height-small: 680px;
    --hero-image-text-width-small: 305px;
    --hero-image-text-width-large: 650px;
    --font-size-hero: 5rem;
    --lower-image-text-padding-tiny: 0 var(--rnb-spacing-medium);
    --lower-image-text-padding-small: 0 var(--rnb-spacing-big);
    --lower-image-text-padding-large: 0 25%;
    --lower-image-text-colour: var(--rnb-colour-white);
}

.heroWrapper {
    width: 100%;

    /* Special case to overwrite gatsby image styling to show right side of image on small screens */
    & :global(.gatsby-image-wrapper) img {
        object-position: right center !important;
    }
}

.heroImageText {
    position: absolute;
    z-index: var(--rnb-zindex-default);
    width: var(--hero-image-text-width-small);
    font-size: var(--rnb-font-size-massive);
    font-weight: var(--rnb-font-weight-bold);
    color: var(--rnb-colour-dandelion-tint-16);
    bottom: var(--rnb-spacing-big);
    left: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        font-size: var(--font-size-hero);
        width: var(--hero-image-text-width-large);
        bottom: var(--rnb-spacing-jumbo);
        left: var(--rnb-spacing-super);
    }
}

.heroImageDownArrow {
    position: absolute;
    bottom: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        bottom: var(--rnb-spacing-big);
    }
}

.verticalCentered {
    display: flex;
    align-items: center;
}

.horizontallyCentered {
    display: flex;
    justify-content: center;
}

.clickable {
    cursor: pointer;
}

.lowerImageText {
    position: absolute;
    z-index: var(--rnb-zindex-default);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--lower-image-text-padding-tiny);
    text-align: center;
    color: var(--lower-image-text-colour);
    font-size: var(--rnb-font-size-massive);
    font-weight: var(--rnb-font-weight-bold);

    @media (--rnb-mq-small) {
        padding: var(--lower-image-text-padding-small);
    }

    @media (--rnb-mq-medium) {
        display: block;
        height: unset;
        top: var(--rnb-spacing-huge);
    }

    @media (--rnb-mq-large) {
        padding: var(--lower-image-text-padding-large);
        font-size: var(--font-size-hero);
    }

    @media (--rnb-mq-huge) {
        top: var(--rnb-spacing-jumbo);
    }

    & > a {
        font-size: var(--rnb-font-size-small);

        &:visited {
            color: var(--lower-image-text-colour);
        }

        &:link {
            color: var(--lower-image-text-colour);
        }
    }
}

.lowerImageBody {
    font-size: var(--rnb-font-size-small);
    font-weight: var(--rnb-font-weight-regular);
    line-height: var(--rnb-line-height-text);

    @media (--rnb-mq-large) {
        font-size: var(--rnb-font-size-medium);
    }
}
