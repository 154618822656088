.component {
    color: var(--rnb-theme-colour-primary, inherit);
    font-family: var(--rnb-font-family-cs-clarity);

    &.mini {
        font-size: var(--rnb-font-size-mini);
        line-height: var(--rnb-line-height-heading);
    }

    &.small {
        font-size: var(--rnb-font-size-small);
        line-height: var(--rnb-line-height-text);
    }

    &.medium {
        font-size: var(--rnb-font-size-medium);
        line-height: var(--rnb-line-height-heading);
    }

    &.large {
        font-size: var(--rnb-font-size-large);
        line-height: var(--rnb-line-height-heading);
    }

    &.big {
        font-size: 1.75rem;
        line-height: var(--rnb-line-height-heading);
    }

    &.huge {
        font-size: var(--rnb-font-size-big);
        line-height: var(--rnb-line-height-heading);
    }

    &.massive {
        font-size: var(--rnb-font-size-huge);
        line-height: var(--rnb-line-height-heading);
    }

    &.regular {
        font-weight: var(--rnb-font-weight-regular);
    }

    &.bold {
        font-weight: var(--rnb-font-weight-bold);
    }
}

h1.component {
    margin-bottom: var(--rnb-spacing-large);
}

h2.component {
    margin-bottom: var(--rnb-spacing-medium);
}

h3.component {
    margin-bottom: var(--rnb-spacing-medium);
}

h4.component,
h5.component {
    margin-bottom: var(--rnb-spacing-tiny);
}

p.component {
    margin-bottom: var(--rnb-spacing-tiny);
}

/* If the body text is preceded by another body text, expand the margin-top to clearly seperate paragraphs */
p + p.component {
    margin-top: var(--rnb-spacing-medium);
}

ul.component {
    list-style: outside;
}

ol.component {
    list-style-type: decimal;
    list-style-position: outside;
}

.list {
    margin-bottom: var(--rnb-spacing-medium);
    margin-left: var(--rnb-spacing-large);
}

li.component {
    margin-left: var(--rnb-spacing-micro);
}

p + .heading.component,
.list + .heading.component {
    margin-top: var(--rnb-spacing-large);
}

.component:last-child {
    margin-bottom: 0;
}
