.component {
    cursor: pointer;
    text-decoration: underline;

    &.isThemeLight {
        color: var(--rnb-colour-chill);

        &:hover,
        &:focus {
            color: var(--rnb-colour-chill-tint-64);
        }
    }

    &.isThemeDark {
        color: var(--rnb-colour-white);

        &:hover,
        &:focus {
            color: var(--rnb-colour-white-alpha-64);
        }
    }

    &.isThemeInherit {
        color: var(--rnb-theme-colour-action);

        &:hover,
        &:focus {
            color: var(--rnb-theme-colour-action-hover);
        }
    }

    &.isSimpleLink {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
