.theme {
    --rnb-theme-colour-primary: var(--rnb-colour-primary-dark);
    --rnb-theme-colour-primary-inverted: var(--rnb-colour-primary-inverted-dark);
    --rnb-theme-colour-secondary: var(--rnb-colour-secondary-dark);
    --rnb-theme-colour-action: var(--rnb-colour-action-dark);
    --rnb-theme-colour-action-hover: var(--rnb-colour-action-hover-dark);
    --rnb-theme-colour-disabled: var(--rnb-colour-disabled-dark);

    /*
     * Button Specials
     * Buttons are commonly used, but have few edge cases which
     * require different set of variables between the
     * light and dark themes. Revisit when we are doing
     * design system 2.0
     */

    /* primary */
    --rnb-theme-colour-button-text-hover: var(--rnb-colour-chill-shade-64);
    --rnb-theme-colour-button-text-disabled: var(--rnb-colour-white-alpha-32);

    /* secondary */
    --rnb-theme-colour-button-secondary-background: var(--rnb-colour-black-alpha-16);
    --rnb-theme-colour-button-secondary-background-hover: var(--rnb-colour-black-alpha-32);
    --rnb-theme-colour-button-secondary-text-hover: var(--rnb-theme-colour-primary);
    --rnb-theme-colour-button-secondary-text-disabled: var(--rnb-colour-midnight-tint-32);
    --rnb-theme-colour-button-secondary-background-disabled: var(--rnb-colour-transparent);

    /* tertiary */
    --rnb-theme-colour-button-tertiary-text-hover: var(--rnb-theme-colour-primary);
    --rnb-theme-colour-button-tertiary-background-hover: var(--rnb-colour-black-alpha-8);
    --rnb-theme-colour-button-tertiary-text-disabled: var(--rnb-colour-white-alpha-32);
    --rnb-theme-colour-button-tertiary-background-disabled: var(--rnb-colour-transparent);
}
