:root .dot {
    --size-dot: 3px;
}

/* NB - May pull dots styling out of core */
.dot {
    background-color: var(--rnb-colour-midnight-tint-32);
    border-radius: var(--rnb-border-radius-circle);
    height: var(--size-dot);
    transition: all ease var(--rnb-timing-fast);
    width: var(--size-dot);

    &.isActive {
        background-color: var(--rnb-colour-midnight);
        transform: scale(2);
    }

    & + .dot {
        margin-left: var(--rnb-spacing-tiny);
    }
}

.itemObserver {
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
}
