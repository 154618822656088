.container {
    transform: rotate(-90deg);
}

.svg {
    fill: none;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    stroke-linecap: round;
    width: 100%;
    display: block;

    &.isResetting {
        transform: rotate(360deg);
    }
}

.circle {
    transition: stroke-dashoffset 1s ease-in-out;
}
