:root {
    --image-size__4: 64px;
}

.component {
    color: var(--rnb-colour-midnight);
    display: grid;
    grid-gap: var(--rnb-spacing-medium);
    grid-template-columns: var(--image-size__4) 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: 'image authorDetails' 'image authorDetails' 'description description';
}

.image {
    border-radius: var(--rnb-border-radius-circle);
    box-shadow: 0 var(--rnb-spacing-medium) var(--rnb-spacing-big) 0 var(--rnb-colour-black-alpha-16);
    grid-area: image;
    overflow: hidden;
}

.authorDetails {
    grid-area: authorDetails;
}

.description {
    grid-area: description;
}
