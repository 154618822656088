.navigation {
    --navigation-theme: dark;
    --navigation-color: var(--rnb-colour-black-alpha-32);

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--rnb-colour-black);
    color: var(--rnb-colour-white);
    padding: var(--rnb-spacing-small) var(--rnb-spacing-large);
    gap: var(--rnb-spacing-large);
    color-scheme: var(--navigation-theme);
    z-index: var(--rnb-zindex-top);

    &:not(.relative) {
        position: fixed;
        top: 0;
        left: 0;
    }

    &.scrolled {
        --navigation-color: var(--rnb-colour-black-alpha-64);
    }

    @media (--rnb-mq-phablet) {
        padding: calc(var(--rnb-spacing-large) - var(--rnb-spacing-micro)) var(--rnb-spacing-large);
    }

    @supports (backdrop-filter: blur()) {
        background-color: var(--rnb-colour-transparent);

        &::before {
            content: '';
            background-color: var(--navigation-color);
            backdrop-filter: blur(var(--rnb-font-size-small));
            transition: background-color var(--rnb-timing-fast) ease;
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        &::after {
            content: '';
            background-color: var(--rnb-colour-black-alpha-16);
            backdrop-filter: blur(var(--rnb-font-size-small));
            z-index: 0;
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100%;
            transition: opacity 0.3s ease-in-out;
            pointer-events: none;
            opacity: 0;
        }

        &.open {
            &::after {
                opacity: 1;
            }
        }

        @media (--rnb-mq-medium) {
            &::after {
                content: none;
            }
        }
    }

    &.midnight {
        @supports (backdrop-filter: blur()) {
            background-color: var(--rnb-colour-transparent);
    
            &::before {
                background-color: var(--rnb-colour-midnight);
            }
        }
    }
}

.navigationHamburger {
    @media (--rnb-mq-medium) {
        display: none;
    }
}

.navigationLogo {
    flex-shrink: 0;
    color: var(--rnb-colour-white);

    &.small {
        height: var(--rnb-font-size-large);
        display: none;

        @media (--rnb-mq-medium) {
            display: block;
        }

        @media (--rnb-mq-large) {
            display: none;
        }
    }

    &.large {
        height: var(--rnb-font-size-mini);
        display: block;

        @media (--rnb-mq-phablet) {
            height: var(--rnb-font-size-small);
        }

        @media (--rnb-mq-medium) {
            display: none;
            height: calc(var(--rnb-font-size-big) - var(--rnb-font-size-mini));
        }

        @media (--rnb-mq-large) {
            display: block;
        }
    }
}

.navigationCTAs {
    display: flex;
    margin-left: auto;
    gap: var(--rnb-spacing-tiny);
    white-space: nowrap;

    & > a {
        color: var(--rnb-colour-white) !important;
    }
}

.navigationScrollMarker {
    position: absolute;
    left: 0;
    top: 1px;
    pointer-events: none;
    visibility: hidden;
}


