.section {
    --maxContentWidth: 600px;
    --maxContainerSize: 1380px;
    --mainBackgroundColour: white;

    .section {
        display: flex;
        align-items: center;
        flex-flow: column;
        width: 100%;
    }
}

.title {
    display: flex;
    align-items: center;
    margin-left: -20px;
}

.infoWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: var(--rnb-spacing-medium);

    @media (--rnb-mq-large) {
        align-items: flex-start;
        padding: 0 var(--rnb-spacing-big);
        margin-top: var(--rnb-spacing-big);
    }

    @media (--rnb-mq-phablet) {
        text-align: left;
    }

    @media (--rnb-mq-large) {
        padding: 0 0 0 var(--rnb-spacing-super);
        align-items: flex-start;
        max-width: 424px;
    }

    @media (--rnb-mq-large) {
        max-width: var(--maxContentWidth);
    }
}

.infoImageWrapper {
    width: 100%;
    max-width: var(--maxContainerSize);
    display: flex;
    height: auto;
    flex-flow: wrap;
    justify-content: center;
    margin-top: var(--rnb-spacing-big);
    padding: 0 var(--rnb-spacing-big);

    @media (--rnb-mq-large) {
        margin: var(--rnb-spacing-super);
        flex-flow: unset;
        justify-content: space-between;
    }
}

.image {
    width: 100%;
    height: auto;
    max-width: 560px;
    display: none;

    @media (--rnb-mq-phablet) {
        display: unset;
        position: relative;
    }

    @media (--rnb-mq-large) {
        margin-right: var(--rnb-spacing-jumbo);
    }
}

.blurb {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;

    @media (--rnb-mq-phablet) {
        max-width: 90%;
    }

    @media (--rnb-mq-medium) {
        flex-direction: row;
        text-align: left;
    }

    @media (--rnb-mq-large) {
        margin-left: 0;
    }

    > * {
        margin-right: var(--rnb-spacing-small);
    }
}
