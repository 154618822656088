.hr {
    height: 1px;
    border: 0;
    background-color: var(--rnb-colour-black-alpha-32);
    width: 100%;
}

.hideM {
    @media (--rnb-mq-medium) {
        display: none;
    }
}

.hideL {
    @media (--rnb-mq-large) {
        display: none;
    }
}
