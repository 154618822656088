.background {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: rgba(var(--rnb-colour-black-rgb), 0.9);
    z-index: var(--rnb-zindex-rise);
    width: 100%;
    height: 100%;
}

.videoWrapper {
    max-height: 720px;
    max-width: 1280px;
}

.video {
    width: 100%;
}

.close {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: var(--rnb-colour-white);
    padding-bottom: var(--rnb-spacing-small);
}
