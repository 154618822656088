/* Disabling the stylelint for shorthands as it seems to struggle with nested css vars */
.spacer {
    --rnbs-all-top: 0;
    --rnbs-all-bottom: 0;
    --rnbs-all-left: 0;
    --rnbs-all-right: 0;

    /* small */
    --rnbs-small-top: var(--rnbs-all-top);
    --rnbs-small-bottom: var(--rnbs-all-bottom);
    --rnbs-small-left: var(--rnbs-all-left);
    --rnbs-small-right: var(--rnbs-all-right);

    /* phablet */
    --rnbs-phablet-top: var(--rnbs-small-top);
    --rnbs-phablet-bottom: var(--rnbs-small-bottom);
    --rnbs-phablet-left: var(--rnbs-small-left);
    --rnbs-phablet-right: var(--rnbs-small-right);

    /* medium */
    --rnbs-medium-top: var(--rnbs-phablet-top);
    --rnbs-medium-bottom: var(--rnbs-phablet-bottom);
    --rnbs-medium-left: var(--rnbs-phablet-left);
    --rnbs-medium-right: var(--rnbs-phablet-right);

    /* large */
    --rnbs-large-top: var(--rnbs-medium-top);
    --rnbs-large-bottom: var(--rnbs-medium-bottom);
    --rnbs-large-left: var(--rnbs-medium-left);
    --rnbs-large-right: var(--rnbs-medium-right);

    /* huge */
    --rnbs-huge-top: var(--rnbs-large-top);
    --rnbs-huge-bottom: var(--rnbs-large-bottom);
    --rnbs-huge-left: var(--rnbs-large-left);
    --rnbs-huge-right: var(--rnbs-large-right);

    /* all margins */
    margin-top: var(--rnbs-all-top);
    margin-right: var(--rnbs-all-right);
    margin-bottom: var(--rnbs-all-bottom);
    margin-left: var(--rnbs-all-left);
}

@media (--rnb-mq-small) {
    .spacer-at-small {
        margin-top: var(--rnbs-small-top);
        margin-right: var(--rnbs-small-right);
        margin-bottom: var(--rnbs-small-bottom);
        margin-left: var(--rnbs-small-left);
    }
}

@media (--rnb-mq-phablet) {
    .spacer-at-phablet {
        margin-top: var(--rnbs-phablet-top);
        margin-right: var(--rnbs-phablet-right);
        margin-bottom: var(--rnbs-phablet-bottom);
        margin-left: var(--rnbs-phablet-left);
    }
}

@media (--rnb-mq-medium) {
    .spacer-at-medium {
        margin-top: var(--rnbs-medium-top);
        margin-right: var(--rnbs-medium-right);
        margin-bottom: var(--rnbs-medium-bottom);
        margin-left: var(--rnbs-medium-left);
    }
}

@media (--rnb-mq-large) {
    .spacer-at-large {
        margin-top: var(--rnbs-large-top);
        margin-right: var(--rnbs-large-right);
        margin-bottom: var(--rnbs-large-bottom);
        margin-left: var(--rnbs-large-left);
    }
}

@media (--rnb-mq-huge) {
    .spacer-at-huge {
        margin-top: var(--rnbs-huge-top);
        margin-right: var(--rnbs-huge-right);
        margin-bottom: var(--rnbs-huge-bottom);
        margin-left: var(--rnbs-huge-left);
    }
}
