:root {
    --stepWidth: 200px;
    --iconWidth: 90px;
}

.step {
    margin-bottom: var(--rnb-spacing-big);
    max-width: var(--stepWidth);
    padding: 0 var(--rnb-spacing-tiny);
}

.howToIcon {
    width: var(--iconWidth);
    margin: auto;
}

.imageWrapper {
    display: flex;
    justify-content: center;

    svg {
        background-color: var(--rnb-colour-white);
        border-radius: 30px;
        border-style: solid;
        border-width: 2px;
        height: 60px;
        width: 60px;
    }
}
